import axios from 'axios';

export const uploadMultiFilesServices = (files) => {
  const { REACT_APP_FILE_BASE_URL } = process.env;
  const url = `${REACT_APP_FILE_BASE_URL}upload/realisasi_petty_cash_multiple`;

  if (files?.length > 0) {
    const formData = new FormData();

    files?.forEach((file, index) =>
      formData.append(`file_${index}`, file.data)
    );

    return axios.post(url, formData, {
      headers: {
        'Api-Key': 'TUdQQmFuZ3NhdFBhbnRla0FzdQ==',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return new Promise((resolve) => resolve({ data: { data: [] } }));
};
