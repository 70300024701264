import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  Input,
  InfoItemVertical,
  TextArea,
  DatePicker,
  InputCurrency,
} from 'components2';
import { dateConvert, rupiahConvert } from 'utilities2';
import { generateNoPettyCash, FILE_TYPE } from '../__PenerimaPettyCashUtils__';
import { CustomUploadFile } from '.';

export const PenerimaanPettyCashContent = ({ action }) => {
  const { values, errors, touched, setValues, handleChange } =
    useFormikContext();

  const handleChangeFile = (e) => {
    const file = e.target.files[0] ?? null;
    const isImage = FILE_TYPE.images.includes(file.type);

    if (!file || !isImage) {
      return alert('File harus berupa gambar (.jpg | .jpeg | .png)');
    }

    const name = file.name;
    const url = URL.createObjectURL(file);
    setValues({ ...values, bukti: { data: file, name, url } });
  };

  useEffect(async () => {
    if (action === 'CREATE') {
      const TODAY = new Date();
      const noPettyCash = await generateNoPettyCash(TODAY);

      setValues({
        ...values,
        no_penerimaan_petty_cash: noPettyCash,
        tgl_penerimaan_petty_cash: TODAY,
      });
    }
  }, [action]);

  if (action === 'DETAIL') {
    return (
      <Fragment>
        <Row>
          <Col md="3">
            <InfoItemVertical
              label="Tgl. Pengajuan Petty Cash"
              text={
                values.tgl_pengajuan_petty_cash
                  ? dateConvert().getSlashDMY(
                      new Date(values?.tgl_pengajuan_petty_cash)
                    )
                  : '-'
              }
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="No. Pengajuan Petty Cash"
              text={values.no_pengajuan_petty_cash}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="Nominal Pengajuan Petty Cash"
              text={rupiahConvert().getWithComa(
                String(parseInt(values.nominal_pengajuan))
              )}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical label="Proyek" text={values.nama_proyek} />
          </Col>
        </Row>

        <div style={{ fontSize: 14 }} className="font-weight-bold mt-2 mb-1">
          Masa Berlaku Petty Cash
        </div>
        <Row>
          <Col md="3">
            <InfoItemVertical
              label="Tgl. Awal Petty Cash"
              text={
                values.tgl_awal_petty_cash
                  ? dateConvert().getSlashDMY(
                      new Date(values?.tgl_awal_petty_cash)
                    )
                  : '-'
              }
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="Tgl. Akhir Petty Cash"
              text={
                values.tgl_akhir_petty_cash
                  ? dateConvert().getSlashDMY(
                      new Date(values?.tgl_akhir_petty_cash)
                    )
                  : '-'
              }
            />
          </Col>
          <Col md="3">
            <InfoItemVertical label="Divisi" text={values.kode_modul} />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md="3">
            <InfoItemVertical
              label="Tgl. Penerimaan Petty Cash"
              text={dateConvert().getSlashDMY(
                new Date(values?.tgl_penerimaan_petty_cash)
              )}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="No. Penerimaan Petty Cash"
              text={values?.no_penerimaan_petty_cash}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="Nominal Penerimaan Petty Cash"
              text={rupiahConvert().getWithComa(
                String(parseInt(values?.nominal_penerimaan_petty_cash))
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Keterangan" text={values?.keterangan} />
          </Col>
        </Row>
        <Row>
          <Col>
            <small style={{ fontSize: 12, display: 'block' }}>
              Bukti Penerimaan Petty Cash
            </small>
            <img
              style={{ minWidth: 150, maxWidth: 200, height: 'auto' }}
              src={values.bukti.url}
              alt="bukti penerimaan petty cash"
            />
          </Col>
        </Row>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Pengajuan Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_pengajuan_petty_cash
                ? new Date(values.tgl_pengajuan_petty_cash)
                : ''
            }
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="No. Pengajuan Petty Cash"
            className="d-flex flex-column"
            value={values.no_pengajuan_petty_cash}
          />
        </Col>
        <Col md="3">
          <InputCurrency
            disabled
            label="Nominal Pengajuan Petty Cash"
            className="d-flex flex-column text-right"
            value={parseFloat(values.nominal_pengajuan ?? 0)}
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="Proyek"
            className="d-flex flex-column"
            value={values.nama_proyek}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Mutasi Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_mutasi_petty_cash
                ? new Date(values.tgl_mutasi_petty_cash)
                : ''
            }
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="No. Bukti Mutasi"
            className="d-flex flex-column"
            value={values.no_bukti_mutasi}
          />
        </Col>
        <Col md="3">
          {console.log(values.nominal_mutasi)}
          <InputCurrency
            disabled
            label="Nominal Mutasi Petty Cash"
            className="d-flex flex-column text-right"
            value={parseFloat(values.nominal_mutasi ?? 0)}
          />
        </Col>
        <Col md="3">
          {console.log(values.nominal_mutasi)}
          <Input
            disabled
            label="Divisi"
            className="d-flex flex-column"
            value={values.kode_modul}
          />
        </Col>
      </Row>

      <div style={{ fontSize: 14 }} className="font-weight-bold mt-2">
        Masa Berlaku Petty Cash
      </div>
      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Awal Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_awal_petty_cash
                ? new Date(values.tgl_awal_petty_cash)
                : ''
            }
          />
        </Col>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Akhir Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_akhir_petty_cash
                ? new Date(values.tgl_akhir_petty_cash)
                : ''
            }
          />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col md="3">
          <DatePicker
            label="Tgl. Penerimaan Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_penerimaan_petty_cash
                ? new Date(values.tgl_penerimaan_petty_cash)
                : ''
            }
            onChange={async (date) => {
              if (action === 'CREATE') {
                const noPettyCash = await generateNoPettyCash(date);

                setValues({
                  ...values,
                  no_penerimaan_petty_cash: noPettyCash,
                  tgl_penerimaan_petty_cash: date,
                });
                return;
              }
              setValues({ ...values, tgl_penerimaan_petty_cash: date });
            }}
            error={
              errors.tgl_penerimaan_petty_cash &&
              touched.tgl_penerimaan_petty_cash &&
              true
            }
            errorText={errors.tgl_penerimaan_petty_cash}
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="No. Penerimaan Petty Cash"
            className="d-flex flex-column"
            value={values.no_penerimaan_petty_cash}
          />
        </Col>

        <Col md="3">
          <InputCurrency
            disabled
            label="Nominal Penerimaan Petty Cash"
            className="d-flex flex-column text-right"
            value={parseFloat(values.nominal_penerimaan_petty_cash ?? 0)}
          />
        </Col>
      </Row>

      <TextArea
        label="Keterangan"
        placeholder="Masukkan Keterangan"
        name="keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan) && errors.keterangan}
      />

      <CustomUploadFile
        label="Bukti Penerimaan Petty Cash"
        name="bukti"
        fileName={values?.bukti?.name ?? ''}
        onChange={handleChangeFile}
        error={Boolean(errors.bukti && touched.bukti)}
        errorText={errors.bukti}
      />

      <img
        className="mt-3"
        style={{ minWidth: 150, maxWidth: 200, height: 'auto' }}
        src={values?.bukti?.url ?? ''}
        alt=""
      />
    </Fragment>
  );
};
