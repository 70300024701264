import React from 'react';
import { Button } from 'react-bootstrap';

const FilterButton = ({ active, className, onClick }) => {
  return (
    <div>
      <Button
        variant="primary"
        onClick={onClick}
        className={`ml-2 mb-2 px-3 text-nowrap ${className}`}
      >
        Filter: {active ? 'ON' : 'OFF'}
      </Button>
    </div>
  );
};

export default FilterButton;
