import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Row, Card } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { CRUDLayout } from 'components';
import { DataStatus, ButtonBack, ButtonCreate } from 'components2';
import { useModalConfirm } from 'hooks2';
import {
  formInitialValues,
  formValidationSchema,
  formSubmitValueMapper,
} from './__PenerimaPettyCashUtils__';
import PenerimaanPettyCashApi from './__PenerimaanPettyCashApi__';
import {
  PenerimaanPettyCashContent,
  PenerimaanPettyCashConfirmationContent,
} from './__PenerimaanPettyCashComps__';
import { uploadImageServices } from './__PenerimaPettyCashUtils__/uploadImageServices';

export const PenerimaanPettyCashTambah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const {
    state: { no_bukti_mutasi },
  } = useLocation();
  const modalConfirm = useModalConfirm();

  const { data, isLoading, isError } = useQuery(
    ['penerima_petty_cash', 'tambah', no_bukti_mutasi],
    () => PenerimaanPettyCashApi.getSingleMutasi({ no_bukti_mutasi }),
    { enabled: !!no_bukti_mutasi }
  );

  const onSubmitForm = async (values, { resetForm }) => {
    await modalConfirm.trigger({
      size: 'md',
      type: 'create',
      component: (
        <Formik initialValues={values}>
          <PenerimaanPettyCashConfirmationContent />
        </Formik>
      ),

      onSubmit: async () => {
        modalConfirm.loading();

        await uploadImageServices(values?.bukti?.data)
          .then(({ data }) => {
            const imgURL = data.data;
            const finalValues = formSubmitValueMapper({
              ...values,
              foto_penerimaan_petty_cash: imgURL,
            });

            PenerimaanPettyCashApi.create(finalValues)
              .then(() =>
                modalConfirm.infoSuccess({
                  title: 'Data Penerimaan Petty Cash Berhasil Ditambah',
                  customTextInfoDetail: 'Data telah tersimpan ke database',
                  size: 'md',
                  onHide: () => {
                    resetForm();
                    modalConfirm.close();
                    history.replace(
                      '/human-resource/transaksi/penerimaan-petty-cash'
                    );
                  },
                })
              )
              .catch((err) =>
                modalConfirm.infoError({
                  title: 'Data Penerimaan Petty Cash Gagal Ditambah',
                  customTextInfoTitle: 'Penyebab Kegagalan:',
                  customTextInfoDetail: err?.response?.data?.message,
                })
              );
          })
          .catch((err) =>
            modalConfirm.infoError({
              title: 'Data Penerimaan Petty Cash Gagal Ditambah',
              customTextInfoTitle: 'Penyebab Kegagalan:',
              customTextInfoDetail:
                err?.response?.data?.message ??
                'Gagal mengupload Bukti Penerimaan',
            })
          );
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle('Penerimaan Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik
          initialValues={formInitialValues(data.data, 'CREATE')}
          validationSchema={formValidationSchema}
          onSubmit={onSubmitForm}
        >
          {({ handleSubmit }) => (
            <Card className="p-4 mt-4">
              <PenerimaanPettyCashContent action="CREATE" />
              <Row className="mt-4 px-3 d-flex justify-content-end align-items-center">
                <ButtonCreate size="md" onClick={() => handleSubmit()} />
              </Row>
            </Card>
          )}
        </Formik>
      )}
    </CRUDLayout>
  );
};
