import { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { DataStatus, Table, THead, Tr, Td, ThFixed, TBody } from 'components';

import { kalkulasiLuas, kalkulasiVolume } from '../../Helpers';
import { RowItemHardwood, calcSubtotalHW } from './RowItemHardwood';

export const TableHardwood = ({
  showTotalLuasVolume = false,
  showStatusPermintaan = false,
  readOnly = false,
  title = '',
  data = [],
}) => {
  const reduceTotalLuas = parseFloat(
    data.reduce(
      (luas, values) =>
        luas +
        kalkulasiLuas({
          id_tipe_sisi: values.id_tipe_sisi,
          qty: values.qty_final,
          t: values.t_final,
          w: values.w_final,
          l: values.l_final,
        }),
      0
    )
  ).toFixed(6);

  const reduceTotalVolume = parseFloat(
    data.reduce(
      (volume, values) =>
        volume +
        kalkulasiVolume({
          qty_raw: values.qty_raw,
          t_raw: values.t_raw,
          w_raw: values.w_raw,
          l_raw: values.l_raw,
        }),
      0
    )
  ).toFixed(6);

  const reduceSubTotal = parseFloat(
    data.reduce(
      (subTotal, values) => Math.round(subTotal + calcSubtotalHW(values)),
      0
    )
  );

  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        {title}
      </div>
      <div className="table-responsive">
        <Table>
          <THead className="bg-light">
            <Tr>
              <ThFixed rowSpan={2} className="text-nowrap">
                No.
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Deskripsi
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Spesifikasi Kayu
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Part Kayu
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Tipe Finishing
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Tipe Sisi
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Qty. Final
              </ThFixed>
              <ThFixed colSpan={3} className="text-nowrap">
                Ukuran Final (cm)
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Qty. Raw
              </ThFixed>
              <ThFixed colSpan={3}>Ukuran Raw (cm)</ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Luas (m2)
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Volume (m3)
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Unit Price
              </ThFixed>
              <ThFixed rowSpan={2}>Konst.</ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Sub Total Price (Rp)
              </ThFixed>
              {showStatusPermintaan ? (
                <ThFixed rowSpan={2}>Status Permintaan</ThFixed>
              ) : null}
              {readOnly ? null : <ThFixed rowSpan={2}>Aksi</ThFixed>}
            </Tr>

            <Tr>
              <ThFixed>T</ThFixed>
              <ThFixed>W</ThFixed>
              <ThFixed>L</ThFixed>

              <ThFixed>T</ThFixed>
              <ThFixed>W</ThFixed>
              <ThFixed>L</ThFixed>
            </Tr>
          </THead>

          <TBody>
            {data?.length ? (
              data?.map((val, index) => (
                <RowItemHardwood
                  key={index}
                  index={index}
                  data={val}
                  showStatusPermintaan={showStatusPermintaan}
                />
              ))
            ) : (
              <Tr>
                <Td colSpan={20}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}

            {data?.length && showTotalLuasVolume ? (
              <Tr>
                <Td textRight colSpan={14}>
                  Total Luas dan Volume
                </Td>
                <Td textRight>{reduceTotalLuas}</Td>
                <Td textRight>{reduceTotalVolume}</Td>
                <Td textRight colSpan={2}>
                  Total Price
                </Td>
                <Td textRight>
                  <NumberFormat
                    value={reduceSubTotal}
                    displayType="text"
                    prefix="Rp"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                  />
                </Td>
                {showStatusPermintaan || !readOnly ? <Td /> : null}
              </Tr>
            ) : null}
          </TBody>
        </Table>
      </div>
    </Fragment>
  );
};
