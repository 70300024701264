import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableFS } from './TableFS';

export const ContainerFSDetail = () => {
  const { dataFS } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableFS
        jobOrder
        readOnly
        showTotalPrice
        title="List Factory Supply Dari Job Order"
        data={dataFS.jobOrder}
      />
      <TableFS
        readOnly
        showTotalPrice
        title="List Permintaan Factory Supply"
        data={dataFS.permintaan}
      />
    </Row>
  );
};
