export const kalkulasiVolume = ({ qty_raw, t_raw, w_raw, l_raw }) => {
  const countTotalUnit = parseFloat(
    (parseFloat(qty_raw).toFixed(6) *
      parseFloat(t_raw).toFixed(6) *
      parseFloat(w_raw).toFixed(6) *
      parseFloat(l_raw).toFixed(6)) /
      1000000
  ).toFixed(6);

  const totalUnit =
    parseFloat(countTotalUnit) < parseFloat(0.000001)
      ? parseFloat(0.000001)
      : parseFloat(countTotalUnit);

  return totalUnit;
};
