import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerBODetail } from '../BiayaOverhead';

export const TabOverhead = () => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="bo">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="bo">
            <NavTab eventKey="bo" title="BiayaOverhead" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane eventKey="bo" component={<ContainerBODetail />} />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
