import { Row, Col } from 'react-bootstrap';
import { InfoItemVertical } from 'components2';
import { DateConvert } from 'utilities';

const STATUS_APPROVAL = {
  PEN: 'PENDING',
  VER: 'VERIFIED',
  REV: 'REVISED',
  REJ: 'REJECTED',
  APP: 'APPROVED',
};

export const InfoSection = ({ data }) => {
  return (
    <>
      <Row>
        <Col md="3">
          <InfoItemVertical
            label="Tgl. Convert Barang"
            text={DateConvert(new Date(data.tgl_convert_barang)).detail}
          />
        </Col>
        <Col md="3">
          <InfoItemVertical
            label="No. Convert Barang"
            text={data.no_convert_barang}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="Catatan Convert Barang"
            text={data.catatan_convert_barang ?? '-'}
          />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <InfoItemVertical
            label="Status Approval"
            text={STATUS_APPROVAL[data.status_approval ?? 'PEN']}
          />
        </Col>
      </Row>
    </>
  );
};
