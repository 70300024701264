import { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import {
  DataStatus,
  Table,
  THead,
  Tr,
  Td,
  Th,
  ThFixed,
  TBody,
} from 'components';

import { RowItemBPF, calcSubtotalBPF } from './RowItemBPF';

export const TableBPF = ({
  showTotalPrice = false,
  readOnly = false,
  title = '',
  data = [],
}) => {
  const reduceSubTotal = parseFloat(
    data.reduce((subTotal, values) => {
      return Math.round(subTotal + calcSubtotalBPF(values));
    }, 0)
  );

  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        {title}
      </div>
      <div className="table-responsive">
        <Table>
          <THead className="bg-light">
            <Tr>
              <ThFixed className="text-nowrap">No.</ThFixed>
              <ThFixed className="text-nowrap">Kode Item</ThFixed>
              <Th width={250}>Nama Item</Th>
              <ThFixed className="text-nowrap">Qty.</ThFixed>
              <ThFixed className="text-nowrap">Satuan</ThFixed>
              <ThFixed className="text-nowrap">Unit Price (Rp)</ThFixed>
              <ThFixed className="text-nowrap">Konst.</ThFixed>
              <ThFixed className="text-nowrap">Sub Total Price (Rp)</ThFixed>
              {readOnly ? null : <ThFixed>Aksi</ThFixed>}
            </Tr>
          </THead>

          <TBody>
            {data?.length ? (
              data?.map((val, index) => (
                <RowItemBPF
                  key={index}
                  index={index}
                  data={val}
                  readOnly={readOnly}
                />
              ))
            ) : (
              <Tr>
                <Td textCenter colSpan={9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}

            {data?.length && showTotalPrice ? (
              <Tr>
                <Td textRight colSpan={7}>
                  Total Price
                </Td>
                <Td textRight>
                  <NumberFormat
                    value={reduceSubTotal}
                    displayType="text"
                    prefix="Rp"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                  />
                </Td>
              </Tr>
            ) : null}
          </TBody>
        </Table>
      </div>
    </Fragment>
  );
};
