import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  FilterButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from 'components';
import {
  DateConvert,
  PageNumber as TableNumber,
  RupiahConvert,
} from 'utilities';
import { useSessionStorage } from 'hooks';
import { ApprovalSeleksiVendorApi } from 'api';
import { tglInputFormat } from '../Utils';
import { ModalFilter } from '../Components';
import { refetchInterval } from 'config/refetchInterval';

const ApprovalSeleksiVendor = () => {
  const history = useHistory();
  const location = useLocation();
  const [modalFilter, setModalFilter] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const PAGINATION_KEY = `${location.pathname}_pagination_approval`;
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',

    active: false,
    created_at_start: undefined,
    created_at_end: undefined,
    tgl_seleksi_vendor_start: undefined,
    tgl_seleksi_vendor_end: undefined,
    tgl_purchase_request_start: undefined,
    tgl_purchase_request_end: undefined,
    id_item_buaso: undefined,
    id_vendor: undefined,
    status_approval: undefined,
  });

  //handle typing saat melakukan pencarian
  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  //handle saat melakukan perpindahan Page
  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  //handle saat mengubah banyak data per Page
  const onDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const { data, isLoading } = useQuery(
    ['approval', 'seleksiVendor', 'list', pagination],
    () => ApprovalSeleksiVendorApi.get(pagination),
    {
      refetchInterval,
      onError: () =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        }),
    }
  );

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig({
        show: locationState.alert.show,
        text: locationState.alert.text,
        variant: locationState.alert.variant,
      });
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  useEffect(() => {
    checkAlert();
  }, []);

  const PageContent = () => {
    return (
      <>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Informasi Pembuat Pengajuan</Th>
              <ThFixed>Tgl. Input Data</ThFixed>
              <Th>Informasi Seleksi Vendor</Th>
              <Th>Informasi Purchase Request</Th>
              <Th width={400}>Item Barang</Th>
              <Th>Vendor</Th>
              <Th>Harga Satuan Kesepakatan</Th>
              <Th>Total Harga</Th>
              <Th>Aksi</Th>
            </Tr>
          </THead>
          <TBody>
            {data.data.data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </TdFixed>

                <Td>
                  <div>
                    {val.nama_karyawan_pengaju
                      ? val.nama_karyawan_pengaju
                      : '-'}
                  </div>
                  <div>
                    {val.nama_jabatan_pengaju ? val.nama_jabatan_pengaju : '-'}
                  </div>
                </Td>

                <Td>
                  {tglInputFormat({
                    created_at: val.created_at,
                    separator: ' - ',
                  })}
                </Td>

                <Td>
                  <div>
                    {val.tgl_seleksi_vendor
                      ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY
                      : '-'}
                  </div>
                  <div>
                    {val.no_seleksi_vendor ? val.no_seleksi_vendor : '-'}
                  </div>
                </Td>

                <Td>
                  <div>
                    {val.tgl_purchase_request
                      ? DateConvert(new Date(val.tgl_purchase_request))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>
                    {val.no_purchase_request ? val.no_purchase_request : '-'}
                  </div>
                </Td>

                <Td>
                  {val.nama_item ?? '-'}{' '}
                  {val.qty_order ? parseFloat(val.qty_order) : 0}{' '}
                  {val.satuan_beli ?? ''}
                </Td>

                <Td>{val.nama_vendor ?? '-'}</Td>

                <Td className="text-right">
                  {val.harga_kesepakatan
                    ? RupiahConvert(String(parseInt(val.harga_kesepakatan)))
                        .detail
                    : 'Rp.0'}
                </Td>

                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(
                        Math.round(
                          parseFloat(val.harga_kesepakatan ?? 0) *
                            parseFloat(val.qty_order ?? 0)
                        )
                      )
                    ).detail
                  }
                </Td>

                <Td>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      size="sm"
                      className="col"
                      onClick={() =>
                        history.push(
                          '/human-resource/approval/seleksi-vendor/detail',
                          {
                            no_seleksi_vendor: val.no_seleksi_vendor,
                          }
                        )
                      }
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination?.page * pagination?.per_page - pagination?.per_page + 1
          }
          dataPage={
            data?.data?.data_count < pagination?.per_page
              ? data?.data?.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={data?.data?.data_count}
          currentPage={pagination?.page}
          totalPage={data?.data?.total_page}
          onPaginationChange={onPaginationChange}
          onDataLengthChange={onDataLengthChange}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </Col>
            <Col md={4}>
              <FilterButton
                active={pagination.active}
                onClick={() => setModalFilter(true)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={dismissAlert}
      />

      {/* content */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data?.data?.data?.length > 0 ? (
        <PageContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilter
        key={JSON.stringify(pagination)}
        show={modalFilter}
        setShow={setModalFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default ApprovalSeleksiVendor;
