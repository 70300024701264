import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableBO } from './TableBO';

export const ContainerBODetail = () => {
  const { dataBO } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableBO
        jobOrder
        readOnly
        showTotalPrice
        title="List Biaya Overhead Dari Job Order"
        data={dataBO.jobOrder}
      />
      <TableBO
        readOnly
        showTotalPrice
        title="List Permintaan Biaya Overhead"
        data={dataBO.permintaan}
      />
    </Row>
  );
};
