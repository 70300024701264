import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Approval,
} from 'components';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { DateConvert, ApprovalStatus } from 'utilities';
import { Formik } from 'formik';
import { PurchaseRequestApi } from 'api';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { ModalGambar } from './Section';
import { tglInputFormat } from './Utils';

const DetailPurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { no_purchase_request, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListPR, setDataListPR] = useState([]);
  const [dataPR, setDataPR] = useState({});
  const [dataApproval, setDataApproval] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [dataGambar, setDataGambar] = useState([]);
  const [modalGambarConfig, setModalGambarConfig] = useState({
    show: false,
    data: {},
    index: 0,
  });

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL PURCHASE REQUEST
    Axios.all([
      PurchaseRequestApi.getSingle({
        no_purchase_request: no_purchase_request,
      }),
    ])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : 'V';
          setDataPR(detail);
          setDataApproval(approval ?? []);
          setDataListPR(detail.detail);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return '-';
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Input Data"
                value={tglInputFormat({
                  created_at: dataPR.created_at,
                  separator: ' - ',
                })}
              />
              <InfoItem
                title="Tgl. Purchase Request"
                value={
                  dataPR?.tgl_purchase_request
                    ? DateConvert(new Date(dataPR?.tgl_purchase_request)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Purchase Request"
                value={dataPR?.no_purchase_request ?? '-'}
              />
              <InfoItem title="Keperluan" value={dataPR.keperluan ?? '-'} />
              <InfoItem
                title="Tgl. Pemakaian"
                value={
                  dataPR?.tgl_pemakaian
                    ? getConvertedDate(dataPR?.tgl_pemakaian)
                    : '-'
                }
              />
              <InfoItem
                title="Proyek"
                value={dataPR?.nama_proyek ? dataPR.nama_proyek : '-'}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const TablePR = ({ dataListPR }) => {
    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item Purchase Request</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center bg-light p-1">
              <tr key="head1">
                <th
                  className="align-middle p-1"
                  style={{ ...tableStyling, width: '20px' }}
                >
                  No.
                </th>
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: '20px' }}
                >
                  Kode Item
                </th>
                <th className="align-middle" style={tableStyling}>
                  Nama Item
                </th>
                <th className="align-middle" style={tableStyling}>
                  Qty
                </th>
                <th className="align-middle" style={tableStyling}>
                  Satuan
                </th>
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: '10px' }}
                >
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {dataListPR && dataListPR.length > 0 ? (
                dataListPR.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td style={tableStyling} className="px-2">
                        {val.kode_item}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_item}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {val.qty}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_satuan}
                      </td>
                      <td>
                        <ActionButton
                          text={<IoCloudUploadOutline />}
                          size="sm"
                          className="m-1"
                          onClick={() => {
                            setModalGambarConfig({
                              show: true,
                              data: val,
                              index: index,
                            });
                            const image = val.path_gambar.map((item) => {
                              let newStr = item.replace('http://', 'https://');
                              return {
                                link: newStr,
                                nama: item,
                              };
                            });
                            setDataGambar(image);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={5}>
                    Tidak Ada Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <TextArea
          label="Catatan Purchase Request"
          value={dataPR.catatan_purchase_request}
          readOnly={true}
        />
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle('Detail Data Purchase Request');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Purchase Request (PR)</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && <TablePR dataListPR={dataListPR} />}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: '' }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required('Masukan Catatan'),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataPR.no_purchase_request,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataPR.baseline,
          };

          PurchaseRequestApi.approve(finalValues)
            .then((res) => {
              console.log('res', res);
              history.push('/human-resource/approval/purchase-request', {
                alert: {
                  show: true,
                  variant: 'primary',
                  text: `${
                    modalConfig.type == 'APP'
                      ? 'Approve'
                      : modalConfig.type == 'REV'
                      ? 'Revise'
                      : modalConfig.type == 'VER'
                      ? 'Verify'
                      : 'Reject'
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: 'danger',
                text: `Ubah data gagal! (${err.response.data.message})`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            {!isPageLoading && (
              <Approval
                values={values}
                handleChange={handleChange}
                dirty={dirty}
                validateForm={validateForm}
                errors={errors}
                touched={touched}
                approveStatus={approveStatus}
                data={dataApproval}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Purchase Request"
              />
            )}
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <ModalGambar
              modalConfig={modalGambarConfig}
              setModalConfig={setModalGambarConfig}
              list={dataListPR.detail}
              // setList={setList}
              setDataGambar={setDataGambar}
              dataGambar={dataGambar}
              type="DETAIL"
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailPurchaseRequest;
