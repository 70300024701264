import { Card, Col, Row } from 'react-bootstrap';
import { InfoItemVertical } from 'components2';
import { RupiahConvert } from 'utilities';

export const FormBarangAwal = ({ data = {} }) => {
  return (
    <>
      <div className="mb-1 font-weight-bold" style={{ fontSize: '.9rem' }}>
        Item Barang Awal
      </div>
      <Card>
        <Card.Body>
          <InfoItemVertical label="Kelompok Barang" text={data.nama_kelompok} />
          <InfoItemVertical label="Item Barang" text={data.nama_item} />
          <InfoItemVertical label="Satuan" text={data.nama_satuan} />
          <InfoItemVertical label="Gudang" text={data.nama_gudang} />

          <Row>
            <Col>
              <InfoItemVertical label="Stok" text={data.stok ?? 0} />
            </Col>
            <Col>
              <InfoItemVertical
                label="Nilai Persediaan"
                text={RupiahConvert(String(data.nilai_persediaan ?? 0)).detail}
              />
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <InfoItemVertical label="Qty. Convert" text={data.qty_convert} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
