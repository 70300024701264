import { Card } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components2';

export const InformasiVendorSection = ({ data }) => {
  return (
    <Card className="mt-4">
      <Card.Body>
        <InfoItemHorizontal
          label="Kode Vendor"
          text={data?.detail?.kode_vendor}
        />
        <InfoItemHorizontal
          label="Nama Vendor"
          text={data?.detail?.nama_vendor}
        />
        <InfoItemHorizontal
          label="Contact Person"
          text={data?.detail?.contact_person}
        />
        <InfoItemHorizontal
          label="Alamat Vendor"
          text={data?.detail?.alamat_vendor}
        />
      </Card.Body>
    </Card>
  );
};
