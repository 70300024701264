const RupiahConvert = (value) => {
  const convertToRupiah = () => {
    let rupiah = '';
    const convert = value.toString().split('').reverse().join('');

    for (var i = 0; i < convert.length; i++)
      if (i % 3 === 0) rupiah += convert.substr(i, 3) + '.';

    return rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('');
  };

  const withLableRupiah = () => {
    return `Rp${convertToRupiah()}`;
  };

  const convertToNumber = () => {
    return parseInt(value.replace(/,.*|[^0-9]/g, ''), 10);
  };

  const getWithComa = (number) => {
    const convertToNumber = parseFloat(number);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(checkConvert);
  };

  const getFromWithComaToFloat = (number) => {
    const removeStringAndDotThenSplitComaIntoArray = String(number)
      .replace(/[^\d|,-]/g, '')
      .split(',');
    return removeStringAndDotThenSplitComaIntoArray.join('.');
  };

  return {
    detail: withLableRupiah(), // => Rp1.000.000
    default: convertToNumber(), // => 1000000
    withoutLabel: convertToRupiah(), // => 1.000.000
    getWithComa: getWithComa(value),
    getFromWithComaToFloat: getFromWithComaToFloat(value),
  };
};

export default RupiahConvert;
