import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TablePlywood } from './TablePlywood';

export const ContainerPlywoodDetail = () => {
  const { dataPlywood } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TablePlywood
        jobOrder
        readOnly
        showTotalLuasVolume
        title="List Plywood Dari Job Order"
        data={dataPlywood.jobOrder}
      />
      <TablePlywood
        readOnly
        showTotalLuasVolume
        showStatusPermintaan
        title="List Permintaan Plywood"
        data={dataPlywood.permintaan}
      />
    </Row>
  );
};
