import { Fragment, useState } from 'react';

export const ItemPermintaanCollapse = ({ data = [] }) => {
  const [isMore, setMore] = useState(false);

  const splicedData = [...data].splice(0, 2);
  const results = isMore ? data : splicedData;

  const textItem = (v) => {
    const toFloat = (number) => parseFloat(number);

    if (v.id_permintaan_produksi_hardwood) {
      return `${v.deskripsi} 
      ${v.nama_jenis_kayu} 
      ${v.nama_part_kayu} 
      ${v.nama_finishing_barang_jadi ?? 'Tanpa Finishing'} 
      (${toFloat(v.t_raw ?? 0)} x ${toFloat(v.w_raw)} x ${toFloat(v.l_raw)}) 
      ${toFloat(v.qty_raw)} Batang`;
    }

    if (v.id_permintaan_produksi_plywood) {
      return `${v.deskripsi} 
      ${v.nama_item} 
      ${v.nama_finishing_barang_jadi ?? 'Tanpa Finishing'} 
      (${toFloat(v.t_raw ?? 0)} x ${toFloat(v.w_raw)} x ${toFloat(v.l_raw)}) 
      ${toFloat(v.qty_raw)} Lembar`;
    }

    // return finishing
    return `${v.nama_item ?? v.nama_item_aset ?? v.uraian_pekerjaan} 
    ${toFloat(v.qty)} 
    ${v.nama_satuan ?? ''}`;
  };

  return (
    <Fragment>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {results?.map((item, index) => (
          <li
            key={
              item.id_permintaan_produksi_finishing ??
              item.id_permintaan_produksi_hardwood ??
              item.id_permintaan_produksi_plywood
            }
            index={index}
          >
            {textItem(item)}
          </li>
        ))}
      </ul>

      {data.length > 2 && (
        <u
          className="text-primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setMore((prev) => !prev)}
        >
          {isMore ? 'Sembunyikan' : 'Selengkapnya...'}
        </u>
      )}
    </Fragment>
  );
};
