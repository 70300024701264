// Import depedencies
import React, {
  memo,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout, PageLoader } from '../components';
import { AuthApi } from 'api';

// Import page dashboard
import Dashboard from '../pages/Dashboard';

// Import page 404
import NotFound from '../pages/404';

import { AuthContext, NotifContext } from '../utilities';

import Login from '../pages/Login';
import Config from '../config';

const MyRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const { isAuthenticated, role } = state;
  const { ROUTES } = Config;

  if (role.length > 0) {
    role.map((val) => {
      let split = val?.split('_');
      if (split.length == 5) {
        let hak = `${split[0]}_${split[1]}_${split[2]}`;
        role.push(hak);
      }
    });
  }

  const AuthRoutes = memo(({ setNavbarTitle }) => (
    <Switch>
      {/* DASHBOARD */}
      <Route exact path="/">
        <Dashboard setNavbarTitle={setNavbarTitle} />
      </Route>

      {/* ROUTES */}
      {ROUTES.map(
        (val, index) =>
          val.hak &&
          val.hak.some((hak) => role.includes(hak)) && (
            <Route key={index} exact={val.exact} path={val.route}>
              <val.page setNavbarTitle={setNavbarTitle} />
            </Route>
          )
      )}

      {/* LOGIN FORM */}
      <Route path="/login">
        <Redirect to="/" />
      </Route>

      {/* 404-NOT FOUND */}
      <Route path="*">
        <NotFound setNavbarTitle={setNavbarTitle} />
      </Route>
    </Switch>
  ));

  const UnAuthRoutes = () => (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  // Routing keseluruhan
  const Routes = () => {
    if (!isAuthenticated) {
      return <UnAuthRoutes />;
    }

    return (
      <Layout>
        <AuthRoutes />
      </Layout>
    );
  };

  const Loading = () => {
    useEffect(() => {
      setTimeout(() => setIsLoading(false), 3000);
    }, []);
    return <PageLoader />;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter basename="/">
      <Routes />
    </BrowserRouter>
  );
};

export default MyRoutes;
