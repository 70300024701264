import { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import { Table, Th, Td, Input } from 'components';
import { RupiahConvert } from 'utilities';

const TableListAnalisaAlatBantuOnSite = ({
  dataAlatBantuOnSite,
  setDataAlatBantuOnSite,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const generateTotal = (qty, konst, price) => {
    const getQty = qty ? parseFloat(qty) : 0;
    const getKonst = konst ? parseFloat(konst) : 0;
    const getPrice = price ? parseFloat(price) : 0;

    return Math.round(getQty * getKonst * getPrice);
  };

  const checkInitialPrice = useMemo(() => {
    return dataAlatBantuOnSite[0]?.unit_price ?? 0;
  }, [dataAlatBantuOnSite]);

  const FormTable = () => {
    const formInitialValues = {
      qty: dataAlatBantuOnSite[0]?.qty ?? 0,
      unit_price: checkInitialPrice,
      konstanta: dataAlatBantuOnSite[0]?.konstanta ?? 0.03,
    };

    const formValidationSchema = Yup.object().shape({
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required(),
    });

    const formSubmitHandler = (values) => {
      setDataAlatBantuOnSite([
        {
          ...dataAlatBantuOnSite[0],
          ...values,
        },
      ]);
      setIsEdit(false);
    };

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    return (
      <tr>
        <Td textCenter>1</Td>
        <Td width={90}>50.000019</Td>
        <Td width={400}>Alat Bantu On-Site</Td>
        <Td width={100}>
          <Input
            noMargin
            value={values.qty}
            onChange={(e) => setFieldValue('qty', e.target.value)}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td>Lumpsum</Td>
        <Td width={200}>
          <Input
            noMargin
            value={RupiahConvert(String(values.unit_price)).withoutLabel}
            onChange={(e) => {
              const newValue = RupiahConvert(
                String(e.target.value.length > 0 ? e.target.value : 0)
              ).default;

              setFieldValue('unit_price', newValue);
            }}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td width={100}>
          <Input
            noMargin
            value={values.konstanta}
            onChange={(e) => setFieldValue('konstanta', e.target.value)}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td textRight>
          {
            RupiahConvert(
              String(
                generateTotal(values?.qty, values?.konstanta, checkInitialPrice)
              )
            ).withoutLabel
          }
        </Td>
      </tr>
    );
  };

  return (
    <div>
      <div className="p-1">
        <b>Alat bantu On-Site</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th width={20}>No</Th>
            <Th width={90}>Kode Item</Th>
            <Th width={400}>Item Labour Cost</Th>
            <Th>Qty</Th>
            <Th>Unit</Th>
            <Th>Unit Price (Rp)</Th>
            <Th>Konst.</Th>
            <Th>Sub Total Price (Rp)</Th>
          </tr>
        </thead>
        <tbody>
          {isEdit ? (
            <FormTable />
          ) : (
            <tr>
              <Td textCenter>1</Td>
              <Td>{dataAlatBantuOnSite[0].kode_item}</Td>
              <Td>{dataAlatBantuOnSite[0].nama_item}</Td>
              <Td textRight>
                {dataAlatBantuOnSite[0]?.qty
                  ? parseFloat(dataAlatBantuOnSite[0]?.qty)
                  : 0}
              </Td>
              <Td>{dataAlatBantuOnSite[0].nama_satuan}</Td>
              <Td textRight>
                <NumberFormat
                  value={parseFloat(checkInitialPrice)}
                  displayType="text"
                  prefix="Rp"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                />
              </Td>
              <Td textRight>
                {dataAlatBantuOnSite[0]?.konstanta
                  ? parseFloat(dataAlatBantuOnSite[0]?.konstanta).toPrecision()
                  : 0.03}
              </Td>
              <Td textRight>
                {
                  RupiahConvert(
                    String(
                      generateTotal(
                        dataAlatBantuOnSite[0]?.qty,
                        dataAlatBantuOnSite[0]?.konstanta,
                        checkInitialPrice
                      )
                    )
                  ).withoutLabel
                }
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableListAnalisaAlatBantuOnSite;
