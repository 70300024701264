import Services from 'services';

class AnalisaBarangJadiRABApi {
  getAnalisaSingle(params) {
    return Services.get('/hrdu/approval_sales_order_spk/analisa_barang_jadi', {
      params,
    });
  }

  getDropdownTipeFinishing() {
    return Services.get('/hrdu/approval_sales_order_spk/d_finishing');
  }

  getKelompokPrelim(params) {
    return Services.get('/hrdu/approval_sales_order_spk/list_kelompok_prelim', {
      params,
    });
  }
}

export default new AnalisaBarangJadiRABApi();
