import { useQuery } from 'react-query';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Approval, DataStatus } from 'components';
import PengajuanPettyCashApi from '../__PengajuanPettyCashApi__';

export const CatatanApprovalPengajuanPettyCashContent = () => {
  const { values } = useFormikContext();
  const { data: stakeholder, isLoading } = useQuery(
    ['stakeholder', values.id_pengajuan_petty_cash],
    () =>
      PengajuanPettyCashApi.getSingle({
        id_pengajuan_petty_cash: values.id_pengajuan_petty_cash,
      }).then((res) => res.data.data.stakeholder ?? []),
    { enabled: Boolean(values.id_pengajuan_petty_cash) }
  );

  return (
    <Row>
      <Col>
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data Approval..." />
        ) : (
          <Approval
            title="Pengajuan Petty Cash"
            tab="history"
            withLine={false}
            data={stakeholder ?? []}
          />
        )}
      </Col>
    </Row>
  );
};
