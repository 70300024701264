import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Approval,
} from '../../../components';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { RupiahConvert, DateConvert, ApprovalStatus } from '../../../utilities';
import { Formik } from 'formik';
import { AnalisaBarangJadiApi } from '../../../api';
import FormAnalisaBarangJadi from './FormAnalisaBarangJadi';

const DetailSalesOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_sales_order, tab = '' } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataSO, setDataSO] = useState({});
  const [dataBahanBaku, setDataBahanBaku] = useState({});
  const [dataBahanPenunjang, setDataBahanPenunjang] = useState({});
  const [dataFinishing, setDataFinishing] = useState({});
  const [dropdownTipeFinishing, setDropdownTipeFinishing] = useState([]);

  const [approveStatus, setApproveStatus] = useState('V');
  const [dataApproval, setDataApproval] = useState([]);
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [dataGambar, setDataGambar] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([
      AnalisaBarangJadiApi.getSingle({
        no_sales_order: no_sales_order,
        id_item_buaso: id,
      }),
      AnalisaBarangJadiApi.getDropdownTipeFinishing(),
    ])
      .then(
        Axios.spread((data, tipeFinishing) => {
          const detail = data?.data?.data?.detail ?? {};
          const approval = data?.data?.data?.approval ?? [];
          const stakeholder = detail.stakeholder ?? [];
          const behavior = data?.data?.data?.behavior ?? 'V';
          const jsonDeocde = JSON.parse(detail.json);
          const gambar = data?.data?.data?.detail?.gambar ?? [];

          setDataSO(detail);
          setDataBahanBaku({
            analisa_hardwood: jsonDeocde.analisa_hardwood,
            analisa_plywood: jsonDeocde.analisa_plywood,
          });
          setDataBahanPenunjang({
            analisa_fin_tp_fs: jsonDeocde.analisa_fin_tp_fs,
            analisa_fin_tp_lc: jsonDeocde.analisa_fin_tp_lc,
            analisa_fin_tp_mp: jsonDeocde.analisa_fin_tp_mp,
            analisa_fin_tp_bop: jsonDeocde.analisa_fin_tp_bop,
          });
          setDataFinishing({
            analisa_fin_fn_bp: jsonDeocde.analisa_fin_fn_bp,
            analisa_fin_fn_sc: jsonDeocde.analisa_fin_fn_sc,
            analisa_fin_fn_lc: jsonDeocde.analisa_fin_fn_lc,
          });
          setDataGambar(gambar);
          setDataApproval(approval);
          setDataStakeholder(stakeholder);
          setApproveStatus(behavior.toUpperCase());
          setDropdownTipeFinishing(tipeFinishing?.data?.data ?? []);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    console.log(approveStatus);

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle('Approval Analisa Barang Jadi');
    getInitialData();

    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <FormAnalisaBarangJadi
            dataAnalisaBarangJadi={dataSO}
            dataBahanBaku={dataBahanBaku}
            dataFinishing={dataFinishing}
            dataBahanPenunjang={dataBahanPenunjang}
            dropdownTipeFinishing={dropdownTipeFinishing}
            dataGambar={dataGambar}
          />

          <Formik
            initialValues={{ catatan: '' }}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required('Masukan Catatan'),
            })}
            onSubmit={(values) => {
              const finalValues = {
                ...values,
                no_transaksi: dataSO.no_transaksi,
                status_approval: modalConfig.type,
                tgl_approval: DateConvert(new Date()).default,
              };
              console.log(modalConfig);

              AnalisaBarangJadiApi.approve(finalValues)
                .then((res) => {
                  history.push('/human-resource/approval/analisa-barang-jadi', {
                    alert: {
                      show: true,
                      variant: 'primary',
                      text: `${
                        modalConfig.type === 'APP'
                          ? 'Approve'
                          : modalConfig.type === 'REV'
                          ? 'Revise'
                          : modalConfig.type === 'VER'
                          ? 'Verify'
                          : 'Reject'
                      } data berhasil!`,
                    },
                  });
                })
                .catch((err) => {
                  setAlertConfig({
                    show: true,
                    variant: 'danger',
                    text: `Simpan approval gagal! (${
                      err?.response?.data?.message ?? ''
                    })`,
                  });
                })
                .finally(() => setModalConfig({ show: false }));
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              errors,
              touched,
              validateForm,
              dirty,
            }) => (
              <>
                <Approval
                  values={values}
                  handleChange={handleChange}
                  dirty={dirty}
                  validateForm={validateForm}
                  errors={errors}
                  touched={touched}
                  approveStatus={approveStatus}
                  data={dataApproval}
                  tab={tab}
                  setModalConfig={setModalConfig}
                  title="Analisa Barang Jadi"
                />

                <ModalSection
                  values={values}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default DetailSalesOrder;
