import axios from 'axios';

export const uploadImageServices = (image) => {
  const { REACT_APP_FILE_BASE_URL } = process.env;
  const url = `${REACT_APP_FILE_BASE_URL}upload/penerimaan_petty_cash`;
  const formData = new FormData();
  formData.append('file', image);

  return axios.post(url, formData, {
    headers: {
      'Api-Key': 'TUdQQmFuZ3NhdFBhbnRla0FzdQ==',
      'Content-Type': 'multipart/form-data',
    },
  });
};
