import { dateConvert, InfoItemVertical } from '@bhawanadevteam/react-core';
import { Fragment } from 'react';

export const DetailInfoSection = ({ values = {} }) => {
  const date = dateConvert();

  return (
    <Fragment>
      <InfoItemVertical
        label="Tgl. Rencana Proyek"
        text={
          values?.tgl_rencana_proyek
            ? date.getSlashDMY(new Date(values.tgl_rencana_proyek))
            : '-'
        }
      />

      <InfoItemVertical
        label="No. Rencana Proyek"
        text={values?.no_rencana_proyek ?? '-'}
      />

      <InfoItemVertical
        label="Skup Proyek"
        text={values?.nama_skup_proyek ?? '-'}
      />

      <InfoItemVertical label="Baseline" text={values?.baseline ?? '-'} />
    </Fragment>
  );
};
