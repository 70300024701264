import { createContext, useState } from 'react';

export const RAEContext = createContext();

export const RAEContextProvider = ({ children }) => {
  const [indexAnalisa, setIndexAnalisa] = useState('');
  const [type, setType] = useState('');
  const [dataPrelim, setDataPrelim] = useState([]);
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([]);

  return (
    <RAEContext.Provider
      value={{
        indexAnalisa,
        type,
        dataPrelim,
        dataAnalisaBarangJadi,
        setIndexAnalisa,
        setType,
        setDataPrelim,
        setDataAnalisaBarangJadi,
      }}
    >
      {children}
    </RAEContext.Provider>
  );
};
