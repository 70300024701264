import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab } from './TabNavigations';

export const TabRoot = ({ children }) => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="bahan">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="bahan">
            <NavTab eventKey="bahan" title="Permintaan Bahan" />
            <NavTab eventKey="upah" title="Permintaan Upah" />
            <NavTab eventKey="alat" title="Permintaan Alat Mesin" />
            <NavTab eventKey="subkon" title="Permintaan Subcon" />
            <NavTab eventKey="overhead" title="Permintaan Overhead" />
          </Nav>
        </Card.Header>

        <Card.Body>{children}</Card.Body>
      </Tab.Container>
    </Card>
  );
};
