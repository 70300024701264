import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Approval,
} from '../../../components';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { RupiahConvert, DateConvert, ApprovalStatus } from '../../../utilities';
import { Formik } from 'formik';
import { FakturPenjualanApi } from '../../../api';
import { TableSection } from './Section';

const DetailFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_faktur, tab = '' } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListFP, setDataListFP] = useState([]);
  const [dataFP, setDataFP] = useState({});
  const [dataPrelim, setDataPrelim] = useState([]);
  const [dataApproval, setDataApproval] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const mappingDataPrelim = (data) => {
    const prelim = data?.map((item, index) =>
      Object({
        ...item,
        id_item: item.id_item_prelim,
        kode_item: item.kode_item_prelim,
        nama_item: item.nama_item_prelim,
        qty_item: item.qty_analisa,
        id_unit: item.id_satuan_prelim,
        nama_unit: item.nama_satuan_prelim,
        harga: parseFloat(item.unit_price ?? 0),
        qty_durasi: parseFloat(item.qty_durasi ?? 0),
        konstanta: parseFloat(item.konstanta ?? 0),
        order: index + 1,
      })
    );

    return prelim;
  };

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([FakturPenjualanApi.getSingle({ no_faktur })])
      .then(
        Axios.spread((data) => {
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : 'V';
          const { approval, detail } = data.data.data;
          const mapDataPrelim = mappingDataPrelim(detail.prelim ?? []);
          setDataFP(detail);
          setDataApproval(approval ?? []);
          setDataListFP(detail.detail);
          setDataPrelim(mapDataPrelim);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return '-';
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop }) => (
      <tr>
        <td
          style={{
            verticalAlign: 'top',
            paddingTop: isPaddingTop ? '10px' : '0',
          }}
        >
          {title}
        </td>
        <td className="pl-4 pr-2" style={{ verticalAlign: 'top' }}>
          :
        </td>
        <td>
          <span className="text-align-justify">{value}</span>
        </td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                isPaddingTop
                title="Tgl. Sales Order"
                value={
                  dataFP?.tgl_sales_order
                    ? DateConvert(new Date(dataFP?.tgl_sales_order)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Sales Order"
                value={dataFP?.no_sales_order ?? '-'}
              />
              <InfoItem title="Sales" value={dataFP.nama_sales ?? '-'} />
              <InfoItem title="Customer" value={dataFP?.nama_customer ?? '-'} />
              <InfoItem
                title="Tgl. Faktur"
                value={
                  dataFP?.tgl_faktur
                    ? DateConvert(new Date(dataFP?.tgl_faktur)).detail
                    : '-'
                }
              />
              <InfoItem title="No. Faktur" value={dataFP?.no_faktur ?? '-'} />
              <InfoItem title="No. Faktur" value={dataFP?.nama_proyek ?? '-'} />
              {/* <InfoItem title="Alamat Pengiriman" value={dataFP?.alamat_pengiriman ?? "-"} />
              <InfoItem title="Catatan" value={dataFP?.catatan ?? "-"} /> */}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle('Approval Faktur Penjualan');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Faktur Penjualan</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {/* {!isPageLoading && <TableFP dataFP={dataFP} dataListFP={dataListFP} />} */}
          <TableSection
            dataTable={dataListFP}
            dataInfo={dataFP}
            dataPrelim={dataPrelim}
            isPrelim={true}
          />
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: '' }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required('Masukan Catatan'),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataFP.no_faktur,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataFP?.baseline ?? 0,
          };

          FakturPenjualanApi.approve(finalValues)
            .then((res) => {
              history.push('/human-resource/approval/faktur-penjualan', {
                alert: {
                  show: true,
                  variant: 'primary',
                  text: `${
                    modalConfig.type == 'APP'
                      ? 'Approve'
                      : modalConfig.type == 'REV'
                      ? 'Revise'
                      : 'Reject'
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: 'danger',
                text: `Simpan approval gagal! (${
                  err?.response?.data?.message ?? ''
                })`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <Approval
              values={values}
              handleChange={handleChange}
              dirty={dirty}
              validateForm={validateForm}
              errors={errors}
              touched={touched}
              approveStatus={approveStatus}
              data={dataApproval}
              tab={tab}
              setModalConfig={setModalConfig}
              title="Faktur Penjualan"
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailFakturPenjualan;
