export const kalkulasiLuas = ({ id_tipe_sisi, t, w, l, qty }) => {
  const tFloat = parseFloat(t);
  const wFloat = parseFloat(w);
  const lFloat = parseFloat(l);
  const qtyFloat = parseFloat(qty);

  if (t && w && l) {
    if (id_tipe_sisi === '2')
      return ((tFloat + wFloat) * 2 * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '3')
      return ((tFloat + wFloat + tFloat) * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '4')
      return ((tFloat + wFloat + wFloat) * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '5')
      return ((tFloat + wFloat) * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '6')
      return ((tFloat + tFloat) * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '7')
      return ((wFloat + wFloat) * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '8') return (tFloat * qtyFloat * lFloat) / 10000;
    if (id_tipe_sisi === '9') return (wFloat * qtyFloat * lFloat) / 10000;

    return 0;
  }
};

export const getVolume = (qty_raw, t_raw, w_raw, l_raw) => {
  const countTotalUnit = parseFloat(
    (parseFloat(qty_raw).toFixed(6) *
      parseFloat(t_raw).toFixed(6) *
      parseFloat(w_raw).toFixed(6) *
      parseFloat(l_raw).toFixed(6)) /
      1000000
  ).toFixed(6);

  const totalUnit =
    parseFloat(countTotalUnit) < parseFloat(0.000001)
      ? parseFloat(0.000001)
      : parseFloat(countTotalUnit);

  return totalUnit;
};

//  hitung total volume dan total price hardwood
export const calcHW = (hardwood = []) => {
  return hardwood?.reduce(
    (result, item) => {
      const volume =
        getVolume(item.qty_raw, item.t_raw, item.w_raw, item.l_raw) ?? 0;
      const totalPrice = Math.round(
        parseFloat(volume) *
          parseFloat(item.unit_price) *
          parseFloat(item.konstanta)
      );

      return {
        volume: parseFloat(result.volume) + parseFloat(volume),
        totalPrice: Math.round(result.totalPrice + totalPrice),
      };
    },
    { volume: 0, totalPrice: 0 }
  );
};

//  hitung total volume dan total price plywood
export const calcPW = (plywood = []) => {
  return plywood?.reduce(
    (result, item) => {
      const volume =
        getVolume(item.qty_raw, item.t_raw, item.w_raw, item.l_raw) ?? 0;
      const totalPrice = Math.round(
        parseFloat(item.qty_raw) * parseFloat(item.unit_price) ?? 0
      );

      return {
        volume: parseFloat(result.volume) + parseFloat(volume),
        totalPrice: Math.round(result.totalPrice + totalPrice),
      };
    },
    { volume: 0, totalPrice: 0 }
  );
};

//  hitung total fs, lc, mp, bop, finishing
export const calculateSummaryLainnya = (data) => {
  return data?.reduce(
    (totalPrice, i) =>
      totalPrice +
      Math.round(
        parseFloat(i.qty).toFixed(6) *
          parseFloat(i.unit_price) *
          parseFloat(i.konstanta).toFixed(6) ?? 0
      ),
    0
  );
};

// hitung unit price alat bantu onsite
export const calcUnitPriceAlatBantuOnsite = (hardwood = [], plywood = []) => {
  const totalHW = hardwood?.reduce((result, item) => {
    const volume = getVolume(item.qty_raw, item.t_raw, item.w_raw, item.l_raw);
    const totalPrice = Math.round(
      parseFloat(item.harga_satuan) *
        parseFloat(item.konstanta) *
        parseFloat(volume)
    );

    return (result += totalPrice);
  }, 0);

  const totalPW = plywood?.reduce((result, item) => {
    const totalPrice = Math.round(
      parseFloat(item.qty_raw) * parseFloat(item.unit_price) ?? 0
    );
    return (result += totalPrice);
  }, 0);

  return totalHW + totalPW;
};

// hitung unit price alat biaya overhead kantor
export const calcUnitPriceBOK = (args) => {
  // get total hardwood
  const { totalPrice: totalHW } = calcHW(args?.hardwood ?? []);
  const { totalPrice: totalPW } = calcPW(args?.plywood ?? []);

  const totalFS = calculateSummaryLainnya(args?.fs ?? []);
  const totalLC = calculateSummaryLainnya(args?.lc ?? []);
  const totalMP = calculateSummaryLainnya(args?.mp ?? []);
  const totalBOP = calculateSummaryLainnya(args?.bop ?? []);
  const totalFin = calculateSummaryLainnya(
    [args?.fnbp, args?.fnsc, args?.fnlc].flat() ?? []
  );

  const alatBantuOnsite = () => {
    const itemAlatBantu = args?.alat_bantu_onsite[0] ?? {};
    console.log('helpers', { itemAlatBantu });
    const itemUnitPrice = calcUnitPriceAlatBantuOnsite(
      args?.hardwood,
      args?.plywood
    );
    const caclItemSubtotal =
      parseFloat(itemAlatBantu['qty'] ?? 0) *
      parseFloat(itemAlatBantu['konstanta'] ?? 0) *
      parseInt(itemUnitPrice ?? 0);
    return caclItemSubtotal;
  };

  const totalAlatBantuOnsite = alatBantuOnsite();
  const totalLabourCostOnsite = args?.labour_cost_on_site?.reduce(
    (result, i) =>
      result +
      parseFloat(i.qty ?? 0) *
        parseFloat(i.unit_price ?? 0) *
        parseFloat(i.konstanta ?? 0),
    0
  );

  return (
    totalHW +
    totalPW +
    totalFS +
    totalLC +
    totalMP +
    totalBOP +
    totalFin +
    totalAlatBantuOnsite +
    totalLabourCostOnsite
  );
};
