import { createContext, useEffect, useState } from 'react';

export const PermintaanProduksiContext = createContext();

export const PermintaanProduksiContextProvider = ({ children }) => {
  const [dataHardwood, setDataHardwood] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataPlywood, setDataPlywood] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataFS, setDataFS] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataBPF, setDataBPF] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataLC, setDataLC] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataLCF, setDataLCF] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataSF, setDataSF] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataMP, setDataMP] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  const [dataBO, setDataBO] = useState({
    jobOrder: [],
    permintaan: [],
    history: [],
  });

  useEffect(() => {
    console.log({ dataHardwood });
  }, [dataHardwood]);

  return (
    <PermintaanProduksiContext.Provider
      value={{
        dataHardwood,
        setDataHardwood,
        dataPlywood,
        setDataPlywood,
        dataFS,
        setDataFS,
        dataBPF,
        setDataBPF,
        dataLC,
        setDataLC,
        dataLCF,
        setDataLCF,
        dataSF,
        setDataSF,
        dataMP,
        setDataMP,
        dataBO,
        setDataBO,
      }}
    >
      {children}
    </PermintaanProduksiContext.Provider>
  );
};

export const withPermintaanProduksiContextProvider = (Component) => (props) => {
  return (
    <PermintaanProduksiContextProvider>
      <Component {...props} />
    </PermintaanProduksiContextProvider>
  );
};
