import Services from 'services';

class PrelimApi {
  getKelompokPrelim(params) {
    return Services.get('/hrdu/approval_rae/list_kelompok_prelim', { params });
  }

  getItemPrelim(params) {
    return Services.get('/hrdu/approval_rae/dropdown_item_prelim', { params });
  }
}

export default new PrelimApi();
