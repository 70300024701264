import { createContext } from 'react';
import { useQuery } from 'react-query';
import { PermintaanProduksiApi } from 'api';

export const DropdownPermintaanProduksiContext = createContext();

export const DropdownPermintaanProduksiContextProvider = ({ children }) => {
  const useQueryDropdown = {
    staleTime: 10 * 60 * 1000, // 10 MINUTES
    cacheTime: 15 * 60 * 1000, // 15 MINUTES
  };
  const dropdownJobOrder = useQuery(
    ['dropdown_job_order', 'permintaan_produksi'],
    () =>
      PermintaanProduksiApi.getDropdownJobOrder().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_item_buaso,
            label: v.nama_item ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownApproval = [
    { value: 'PEN', label: 'PENDING' },
    { value: 'APP', label: 'APPROVE' },
    { value: 'REV', label: 'REVISE' },
    { value: 'REJ', label: 'REJECT' },
    { value: 'VER', label: 'VERIFIED' },
  ];

  return (
    <DropdownPermintaanProduksiContext.Provider
      value={{
        dropdownJobOrder,
        dropdownApproval,
      }}
    >
      {children}
    </DropdownPermintaanProduksiContext.Provider>
  );
};

export const withDropdownPermintaanProduksiContextProvider =
  (Component) => (props) => {
    return (
      <DropdownPermintaanProduksiContextProvider>
        <Component {...props} />
      </DropdownPermintaanProduksiContextProvider>
    );
  };
