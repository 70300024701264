import Services from '../../services';

class RAEApi {
  get(params) {
    return Services.get('/hrdu/approval_rae/page', { params });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_rae/detail', { params });
  }

  getSingleAnalisa(params) {
    return Services.get('/hrdu/approval_rae/analisa_barang_jadi_single/', {
      params,
    });
  }

  getPeluang(params) {
    return Services.get('/hrdu/peluang/page', { params });
  }

  getDetailPeluangRAE(params) {
    return Services.get('/hrdu/approval_rae/detail_peluang', { params });
  }

  getSinglePeluang(params) {
    return Services.get('/hrdu/peluang/single', { params });
  }

  getNomorRAE(params) {
    return Services.get('/hrdu/approval_rae/no_baru', { params });
  }

  save(data) {
    return Services.post('/hrdu/approval_rae', data);
  }
}

export default new RAEApi();
