import { useRef, useMemo, useState, useCallback } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  IoDownloadOutline,
  IoImageOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import ImageViever from 'react-simple-image-viewer';
import FileSaver from 'file-saver';
import { ActionButton, DeleteModal } from 'components';

export const InputImages = ({
  label = '',
  readOnly,
  downloadButton,
  deleteButton,
  images,
  setImages,
}) => {
  const inputFileRef = useRef();
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false,
  });
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: '',
  });

  // Filter data gambar agar array hanya terisi link gambar
  const filterImageHandler = useMemo(
    () => images?.map((val) => val.link),
    [images]
  );

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback(
    (index) =>
      setViewImageConfig({
        show: true,
        index: index,
      }),
    [viewImageConfig]
  );

  // Trigger input file agar terclick
  const onClickFilesHandler = () => !readOnly && inputFileRef.current.click();

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = e.target.files[0];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1];

    // Check jika type file sudah benar
    if (['jpg', 'png', 'gif', 'jpeg'].includes(getFileType)) {
      const generateName = file.name;
      const generateLink = URL.createObjectURL(file);
      const finalValue = { data: file, nama: generateName, link: generateLink };

      setImages([...images, finalValue]);
    } else {
      window.alert(
        'File tidak valid! harap masukan gambar (.jpg, .png, .gif, .jpeg)'
      );
    }
  };

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({ ...modalDeleteConfig, loading: true });

    const filterData = images?.filter(
      (val, index) => index !== modalDeleteConfig.index
    );

    setTimeout(() => {
      setImages(filterData);
      setModalDeleteConfig({
        index: '',
        show: false,
        loading: false,
      });
    }, 300);
  }, [modalDeleteConfig]);

  // Menampilkan komponen foto
  const Image = ({ imageText, imageUrl, onView, onDownload, onDelete }) => {
    const [isHover, setIsHover] = useState(false);

    return (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        overlay={
          <Tooltip style={{ zIndex: 1 }}>
            {imageText ?? 'Foto Barang Jadi'}
          </Tooltip>
        }
      >
        <Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className="my-3"
          style={{
            position: 'relative',
            cursor: 'zoom-in',
            objectFit: 'cover',
          }}
          onClick={onView}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {deleteButton && (
            <ActionButton
              size="sm"
              variant="danger"
              style={{ position: 'absolute', zIndex: 1, right: 20 }}
              className="m-2 shadow"
              onClick={onDelete}
            >
              <IoTrashOutline />
            </ActionButton>
          )}

          {downloadButton && (
            <ActionButton
              size="sm"
              variant="primary"
              style={{
                position: 'absolute',
                zIndex: 1,
                right: 20,
                top: deleteButton ? 35 : 0,
              }}
              className="m-2 shadow"
              onClick={onDownload}
            >
              <IoDownloadOutline />
            </ActionButton>
          )}

          <img
            alt="thumbnail"
            className={`img-fluid rounded border ${
              isHover ? 'shadow' : 'shadow-sm'
            }`}
            style={{
              height: 200,
              width: '100%',
              objectFit: 'cover',
              transform: isHover ? 'scale(1.03)' : 'none',
              transition: 'all 0.1s ease-in-out',
            }}
            src={imageUrl}
          />
        </Col>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 ">
        <input
          ref={inputFileRef}
          type="file"
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{ display: 'none' }}
          onChange={onChangeFileHandler}
          onClick={onClickFilesHandler}
        />
      </div>

      {/* Image Section */}
      <small>{label}</small>
      <Container
        fluid
        className="bg-light rounded overflow-auto border"
        onClick={onClickFilesHandler}
        style={{ cursor: 'pointer', maxHeight: '60vh' }}
      >
        {images?.length > 0 ? (
          <Row>
            {images?.map((val, index) => (
              <Image
                key={index}
                imageText={val.nama}
                imageUrl={val.link}
                onView={(e) => {
                  e.stopPropagation();
                  setIndexImageHandler(index);
                }}
                onDownload={(e) => {
                  e.stopPropagation();
                  FileSaver.saveAs(val.link, val.nama);
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  setModalDeleteConfig({
                    show: true,
                    loading: false,
                    index: index,
                  });
                }}
              />
            ))}
          </Row>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <IoImageOutline size={60} />
            <b className="mt-2">Tidak ada gambar</b>
          </div>
        )}
      </Container>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show}
        title="Gambar"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() =>
          setModalDeleteConfig({
            show: false,
            index: '',
          })
        }
      />

      {/*Image Viewer  */}
      {viewImageConfig.show && (
        <div style={{ zIndex: 1000 }}>
          <ImageViever
            closeOnClickOutside
            disableScroll
            src={filterImageHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({ show: false, index: 0 })}
            backgroundStyle={{ zIndex: 1000 }}
          />
        </div>
      )}
    </div>
  );
};
