import { Fragment, useEffect, useState, useContext } from 'react';
import { Card, Tab, Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  DataStatus,
} from 'components';
import { ApprovalStatus } from 'utilities';
import { PermintaanProduksiApi } from 'api';
import {
  InfoSection,
  TabAlatMesin,
  TabBahan,
  TabOverhead,
  TabPane,
  TabRoot,
  TabSubcon,
  TabUpah,
} from './Components';
import {
  withPermintaanProduksiContextProvider,
  PermintaanProduksiContext,
} from './Context';
import { mapServerValues } from './Helpers';

const DetailApprovalPermintaanProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const { id_permintaan_produksi } = useParams();
  const { tab } = state;
  const {
    setDataHardwood,
    setDataPlywood,
    setDataFS,
    setDataBPF,
    setDataLC,
    setDataLCF,
    setDataMP,
    setDataSF,
    setDataBO,
  } = useContext(PermintaanProduksiContext);

  const [approveStatus, setApproveStatus] = useState('V');
  const [data, setData] = useState({});
  const [isFetch, setFetch] = useState({
    loading: false,
    error: false,
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setFetch({ loading: true, error: false });

    PermintaanProduksiApi.getSingle({ id_permintaan_produksi }).then((res) => {
      const {
        hardwood,
        plywood,
        factory_supply,
        bahan_penunjang,
        labour_cost,
        labour_cost_finishing,
        machine_process,
        subcon_finishing,
        biaya_overhead,
        analisa_hardwood,
        analisa_plywood,
        analisa_PFS,
        analisa_PPF,
        analisa_PLC,
        analisa_PLF,
        analisa_PMC,
        analisa_PSF,
        analisa_POP,
        ...jobOrder
      } = res?.data?.data?.detail ?? {};

      setData(jobOrder);

      setApproveStatus(res?.data?.data?.behavior?.toUpperCase() ?? 'V');

      setDataHardwood((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_hardwood).hardwood,
        permintaan: mapServerValues(hardwood).hardwood,
      }));

      setDataPlywood((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_plywood).plywood,
        permintaan: mapServerValues(plywood).plywood,
      }));

      setDataFS((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_PFS).factorySupply,
        permintaan: mapServerValues(factory_supply).factorySupply,
      }));

      setDataBPF((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_PPF).bahanPenunjang,
        permintaan: mapServerValues(bahan_penunjang).bahanPenunjang,
      }));

      setDataLC((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_PLC).labourCost,
        permintaan: mapServerValues(labour_cost).labourCost,
      }));

      setDataLCF((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_PPF).labourCostFinishing,
        permintaan: mapServerValues(labour_cost_finishing).labourCostFinishing,
      }));

      setDataMP((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_PMC).machineProcess,
        permintaan: mapServerValues(machine_process).machineProcess,
      }));

      setDataSF((prev) => ({
        ...prev,
        jobOrder: mapServerValues(analisa_PSF).subconFinishing,
        permintaan: mapServerValues(subcon_finishing).subconFinishing,
      }));

      setDataBO((prev) => ({
        ...prev,
        jobOrder: analisa_POP,
        permintaan: biaya_overhead,
      }));

      setFetch({ loading: false, error: false });
    });
  };

  const onSubmitApproval = (values, { setSubmitting }) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: data?.no_permintaan_produksi,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
    };

    PermintaanProduksiApi.approve(finalValues)
      .then(() =>
        history.push('/human-resource/approval/permintaan-produksi', {
          alert: {
            show: true,
            variant: 'primary',
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlert({
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setSubmitting(false);
        setModalConfig({
          ...modalConfig,
          show: false,
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Approval Permintaan Produksi');
    getInitialData();
  }, []);

  return (
    <Fragment>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Permintaan Produksi</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>

        <Card.Body>
          <Alert
            showCloseButton
            show={!!alert.text}
            variant={alert.variant}
            text={alert.text}
            onClose={() => setAlert({ text: '', variant: 'primary' })}
          />
          {isFetch.loading ? (
            <DataStatus loading={isFetch.loading} text="Memuat data ..." />
          ) : (
            <Fragment>
              <InfoSection showPermintaanProduksi dataJO={data} />

              <TabRoot>
                <Tab.Content>
                  <TabPane eventKey="bahan" component={<TabBahan readOnly />} />
                  <TabPane eventKey="upah" component={<TabUpah readOnly />} />
                  <TabPane
                    eventKey="alat"
                    component={<TabAlatMesin readOnly />}
                  />
                  <TabPane
                    eventKey="subkon"
                    component={<TabSubcon readOnly />}
                  />
                  <TabPane
                    eventKey="overhead"
                    component={<TabOverhead readOnly />}
                  />
                </Tab.Content>
              </TabRoot>

              <Formik
                enableReinitialize
                initialValues={{ catatan: '' }}
                validationSchema={yup.object().shape({
                  catatan: yup.string().required('Catatan tidak boleh kosong'),
                })}
                onSubmit={onSubmitApproval}
              >
                {(formik) => (
                  <Fragment>
                    <Approval
                      values={formik.values}
                      dirty={formik.dirty}
                      errors={formik.errors}
                      touched={formik.touched}
                      handleChange={formik.handleChange}
                      validateForm={formik.validateForm}
                      data={data?.stakeholder ?? []}
                      approveStatus={approveStatus}
                      tab={tab}
                      setModalConfig={setModalConfig}
                      title="Permintaan Produksi"
                    />

                    <Modal
                      show={modalConfig.show}
                      onHide={() =>
                        setModalConfig((prev) => ({
                          ...prev,
                          show: false,
                        }))
                      }
                    >
                      <Modal.Header
                        closeButton
                        className="py-2 d-flex align-items-center"
                      >
                        <Modal.Title>
                          <small>{modalConfig.title}</small>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        <p>
                          <h6>
                            <b>{modalConfig.title} dengan catatan :</b>
                          </h6>
                          <span>{formik.values.catatan}</span>
                        </p>
                      </Modal.Body>
                      <Modal.Footer className="justify-content-center">
                        <ActionButton
                          variant="outline-secondary"
                          text="Batal"
                          className="px-4"
                          onClick={() =>
                            setModalConfig((prev) => ({
                              ...prev,
                              show: false,
                            }))
                          }
                        />
                        <ActionButton
                          className="m-1 px-3 text-white"
                          onClick={formik.handleSubmit}
                          loading={formik.isSubmitting}
                          text={
                            ApprovalStatus(modalConfig.type, approveStatus)
                              ?.text
                          }
                          variant={
                            ApprovalStatus(modalConfig.type, approveStatus)
                              ?.variant
                          }
                        />
                      </Modal.Footer>
                    </Modal>
                  </Fragment>
                )}
              </Formik>
            </Fragment>
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default withPermintaanProduksiContextProvider(
  DetailApprovalPermintaanProduksi
);
