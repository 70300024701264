/**
 *  Table List Plywood pada Tab Rekapitulasi
 */

import { useEffect, useState } from 'react';
import { DataStatus } from 'components';
import { RupiahConvert } from 'utilities';
import { accumulatePlywood } from '../Utils';

const TableListPlywood = ({ data }) => {
  const [dataAkumulasi, setDataAkumulasi] = useState([]);

  let grandTotal = 0;
  const fixed = { width: '30px' };

  useEffect(() => {
    const accumulatePW = accumulatePlywood(data);
    setDataAkumulasi(accumulatePW ?? []);

    return () => {
      setDataAkumulasi([]);
    };
  }, [data]);

  return (
    <>
      <div className="font-weight-bold text-capitalize mb-1">List Plywood</div>

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: '14px' }}
      >
        <thead className="text-center bg-light">
          <tr>
            <th rowSpan={2} className="align-middle px-0" style={fixed}>
              No.
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Deskripsi
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Spesifikasi Plywood
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Tipe Finishing
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Tipe Sisi
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Qty. Final
            </th>
            <th colSpan={3} className="align-middle px-0">
              Final (cm)
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Qty. Raw
            </th>
            <th colSpan={3} className="align-middle px-0">
              Raw (cm)
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Luas (m2)
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Volume (m3)
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Unit Price (Rp)
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Konst.
            </th>
            <th rowSpan={2} className="align-middle px-0">
              Sub Total Price (Rp)
            </th>
          </tr>
          <tr>
            {/* Final */}
            <th className="align-middle px-0">T</th>
            <th className="align-middle px-0">W</th>
            <th className="align-middle px-0">L</th>
            {/* Raw */}
            <th className="align-middle px-0">T</th>
            <th className="align-middle px-0">W</th>
            <th className="align-middle px-0">L</th>
          </tr>
        </thead>
        <tbody>
          {dataAkumulasi && dataAkumulasi.length > 0 ? (
            dataAkumulasi.map((val, index) => {
              grandTotal += val.total_price ?? 0;
              return (
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td>{val.deskripsi}</td>
                  <td>{val.nama_item}</td>
                  <td>
                    {val.id_finishing_barang_jadi === '0'
                      ? 'Tanpa Finishing'
                      : val.nama_finishing_barang_jadi}
                  </td>
                  <td>{val.nama_tipe_sisi}</td>
                  <td className="text-right">
                    {val.qty_final
                      ? parseFloat(val.qty_final).toPrecision()
                      : ''}
                  </td>
                  <td className="text-right">
                    {val.t_final ? parseFloat(val.t_final).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.w_final ? parseFloat(val.w_final).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.l_final ? parseFloat(val.l_final).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.qty_raw ? parseFloat(val.qty_raw).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.t_raw ? parseFloat(val.t_raw).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.w_raw ? parseFloat(val.w_raw).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.l_raw ? parseFloat(val.l_raw).toPrecision() : ''}
                  </td>
                  <td className="text-right">
                    {val.total_luas
                      ? parseFloat(val.total_luas).toPrecision()
                      : ''}
                  </td>
                  <td className="text-right">
                    {val.total_unit
                      ? parseFloat(val.total_unit).toPrecision()
                      : ''}
                  </td>
                  <td className="text-right">
                    {
                      RupiahConvert(String(parseInt(val.unit_price ?? 0)))
                        .detail
                    }
                  </td>
                  <td className="text-right">
                    {val.konstanta
                      ? parseFloat(val.konstanta).toPrecision()
                      : ''}
                  </td>
                  <td className="text-right">
                    {
                      RupiahConvert(String(parseInt(val.total_price ?? 0)))
                        .detail
                    }
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={19}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}

          {/* Grand Total */}
          {dataAkumulasi?.length > 0 && (
            <tr>
              <td colSpan={17} className="font-weight-bold text-right">
                Grand Total
              </td>
              <td className="font-weight-bold text-right">
                {RupiahConvert(String(parseInt(grandTotal))).detail}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default TableListPlywood;
