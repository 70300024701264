import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import Axios from 'axios';
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  SelectSearch,
} from 'components';
import { AnalisaBarangJadiApi } from 'api';
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC,
  TableListSummary,
  TableListBOKProduksi,
  TableListLCOS,
  TableListMPOS,
} from './Table';
import FilesUpload from './FilesUpload';

const FormAnalisaBarangJadi = ({
  setNavbarTitle,
  dataAnalisaBarangJadi,
  dataBahanBaku,
  dataBahanPenunjang,
  dataFinishing,
  dropdownTipeFinishing,
  dataGambar,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const componentRef = useRef();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  // const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState({});
  // const [dataBahanBaku, setDataBahanBaku] = useState({});
  // const [dataBahanPenunjang, setDataBahanPenunjang] = useState({});
  // const [dataFinishing, setDataFinishing] = useState({});
  const [dataOnSite, setDataOnSite] = useState({});
  const [filesUpload, setFilesUpload] = useState([]);
  const [dropdownTipeSisi, setDropdownTipeSisi] = useState([]);
  // const [dropdownTipeFinishing, setDropdownTipeFinishing] = useState([]);
  const [dropdownSpekKayu, setDropdownSpekKayu] = useState([]);
  const [dropdownSpekPlywood, setDropdownSpekPlywood] = useState([]);
  const [dropdownDeskripsi, setDropdownDeskripsi] = useState([]);
  const [dropdownPart, setDropdownPart] = useState([]);
  const [dropdownTipeProduksi, setDropdownTipeProduksi] = useState([]);
  const [dropdownBentuk, setDropdownBentuk] = useState([]);
  const [dropdownUkuran, setDropdownUkuran] = useState([]);

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // const getBahanBaku = () =>
  //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "baku" }).then((res) =>
  //     setDataBahanBaku(res.data.data)
  //   );

  // const getPenunjangProduksi = () =>
  //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "penunjang" }).then((res) =>
  //     setDataBahanPenunjang(res.data.data)
  //   );

  // const getFinishing = () =>
  //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "fin" }).then((res) =>
  //     setDataFinishing(res.data.data)
  //   );

  const getInitialData = () => {
    // setIsPageLoading(true);
    // Axios.all([
    //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "umum" }),
    //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "gambar" }),
    //   AnalisaBarangJadiApi.getDropdownTipeSisi(),
    //   AnalisaBarangJadiApi.getDropdownTipeFinishing(),
    //   AnalisaBarangJadiApi.getDropdownSpekPlywood(),
    //   AnalisaBarangJadiApi.getDropdownDeskripsi(),
    //   AnalisaBarangJadiApi.getDropdownPart(),
    //   AnalisaBarangJadiApi.getDropdownSpekKayu(),
    //   AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: "tipe_prd" }),
    //   AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: "bentuk" }),
    //   AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: "ukuran" }),
    // ])
    //   .then(
    //     Axios.spread(
    //       (
    //         barang,
    //         img,
    //         tipeSisi,
    //         tipeFinishing,
    //         spekPlywood,
    //         deskripsi,
    //         part,
    //         spekKayu,
    //         tipeProduksi,
    //         bentuk,
    //         ukuran
    //       ) => {
    //         setDataAnalisaBarangJadi(barang.data.data);
    //         setFilesUpload(img?.data?.data?.gambar ?? []);
    //         setDropdownTipeSisi(tipeSisi?.data?.data ?? []);
    //         setDropdownTipeFinishing(tipeFinishing?.data?.data ?? []);
    //         setDropdownSpekPlywood(spekPlywood?.data?.data ?? []);
    //         setDropdownDeskripsi(deskripsi?.data?.data ?? []);
    //         setDropdownPart(part?.data?.data ?? []);
    //         setDropdownSpekKayu(spekKayu?.data?.data ?? []);
    //         setDropdownTipeProduksi(tipeProduksi?.data?.data ?? []);
    //         setDropdownBentuk(bentuk?.data?.data ?? []);
    //         setDropdownUkuran(ukuran?.data?.data ?? []);
    //       }
    //     )
    //   )
    //   .catch(() => {
    //     setIsFetchingFailed(true);
    //     setAlertConfig({
    //       show: true,
    //       variant: "danger",
    //       text: "Data gagal dimuat!",
    //     });
    //   })
    //   .finally(() => getBahanBaku())
    //   .finally(() => getPenunjangProduksi())
    //   .finally(() => getFinishing())
    //   .finally(() => {
    //     getFinishing();
    //     setIsPageLoading(false);
    //   });
  };

  useEffect(() => {
    // setNavbarTitle("Analisa Barang Jadi");
    getInitialData();

    return () => {};
  }, []);

  const InfoSection = () => {
    const [tabs, setTabs] = useState('informasi'); //State untuk menampung tabs yang aktif

    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    );

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div style={{ height: '400px', overflowY: 'auto' }}>{value}</div>
      </div>
    );

    const InfoItemLink = ({ title, value, text }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank">
            {text}
          </a>
        </b>
      </div>
    );

    const tabsConfig = [
      {
        tab: 'informasi',
        label: 'Informasi Umum',
        component: () => (
          <Row>
            <Col>
              <InfoItem
                title="Kode Barang Jadi"
                value={
                  dataAnalisaBarangJadi.kode_item
                    ? dataAnalisaBarangJadi.kode_item
                    : '-'
                }
              />
              <InfoItem
                title="Nama Barang Jadi"
                value={
                  dataAnalisaBarangJadi.nama_item
                    ? dataAnalisaBarangJadi.nama_item
                    : '-'
                }
              />
              <InfoItem
                title="Sumber Barang Jadi"
                value={
                  dataAnalisaBarangJadi.nama_kelompok
                    ? dataAnalisaBarangJadi.nama_kelompok
                    : '-'
                }
              />
              <InfoItem
                title="Satuan Jual"
                value={
                  dataAnalisaBarangJadi.nama_satuan
                    ? dataAnalisaBarangJadi.nama_satuan
                    : '-'
                }
              />
              {dataAnalisaBarangJadi.link_referensi ? (
                <InfoItemLink
                  title="Link Referensi"
                  value={dataAnalisaBarangJadi.link_referensi}
                  text="Lihat Referensi"
                />
              ) : (
                <InfoItem title="Link Referensi" value="-" />
              )}
            </Col>
            <Col>
              <InfoItemUraian
                title="Uraian"
                value={
                  dataAnalisaBarangJadi.uraian
                    ? htmlParser(dataAnalisaBarangJadi.uraian)
                    : '-'
                }
              />
            </Col>
          </Row>
        ),
      },
      {
        tab: 'gambar',
        label: 'Gambar',
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="images"
          />
        ),
      },
      {
        tab: 'file',
        label: 'File',
        component: () => (
          <FilesUpload
            filesUpload={dataGambar}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="documents"
          />
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <>
        <div className="mb-2 d-flex justify-content-between align-items-end">
          <h6 className="font-weight-bold text-uppercase">{`Detail Data ${
            dataAnalisaBarangJadi?.nama_item ?? ''
          }`}</h6>
          <BackButton onClick={() => history.goBack()} />
        </div>
        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>
      </>
    );
  };

  const TableSection = () => {
    const [tabs, setTabs] = useState('bahan_baku'); //State untuk menampung tabs yang aktif
    const [isModalSaveAs, setIsModalSaveAs] = useState({ show: false });
    const [analisaVariable, setAnalisaVariable] = useState([]); // menyimpan data analisa variable (BOK Produksi)
    const [dataHardwood, setDataHardwood] = useState([]);
    const [dataPlywood, setDataPlywood] = useState([]);
    const [dataTPFS, setDataTPFS] = useState([]);
    const [dataTPLC, setDataTPLC] = useState([]);
    const [dataTPMP, setDataTPMP] = useState([]);
    const [dataTPBOP, setDataTPBOP] = useState([]);
    const [dataSelectTPFS, setDataSelectTPFS] = useState([]);
    const [dataSelectTPLC, setDataSelectTPLC] = useState([]);
    const [dataSelectTPMP, setDataSelectTPMP] = useState([]);
    const [dataSelectTPBOP, setDataSelectTPBOP] = useState([]);
    const [dataFNBP, setDataFNBP] = useState([]);
    const [dataFNSC, setDataFNSC] = useState([]);
    const [dataFNLC, setDataFNLC] = useState([]);
    const [dataSelectFNBP, setDataSelectFNBP] = useState([]);
    const [dataSelectFNSC, setDataSelectFNSC] = useState([]);
    const [dataSelectFNLC, setDataSelectFNLC] = useState([]);
    const [dataMPOS, setDataMPOS] = useState([]);
    const [dataLCOS, setDataLCOS] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDropdownPenunjang, setSelectedDropdownPenunjang] = useState({
      id_barang_jadi_tipe_produksi: '',
      id_barang_jadi_bentuk: '',
      id_barang_jadi_ukuran: '',
      id_jenis_kayu: '',
    });
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false,
      variable: false,
    });
    const [prevFormState, setPrevFormState] = useState({
      // state menyimpan form value input item hardwood & plywood
      hardwood: {},
      plywood: {},
    });

    const getUniqueTipeFinishing = () => {
      // fungsi mengambil id tipe finishing yang unique
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let uniqueTipeFinishing = []; // menyimpan obj unique id tipe finishing

      // cari unique id tipe finishing
      listBahanBaku?.map((item) => {
        if (
          item?.id_finishing_barang_jadi !== '0' &&
          Boolean(!uniqueTipeFinishing.includes(item?.id_finishing_barang_jadi))
        ) {
          uniqueTipeFinishing.push(item?.id_finishing_barang_jadi);
        }
      });

      uniqueTipeFinishing = uniqueTipeFinishing?.map((item) => {
        return dropdownTipeFinishing.find(
          (val) => val?.id_finishing_barang_jadi === item
        );
      });

      return uniqueTipeFinishing;
    };

    const getTotalLuasTipeFinishing = () => {
      // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunakan sbg konst pada tab 3)
      const uniqueTipeFinishing = getUniqueTipeFinishing();
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let totalLuasTipeFinishing = []; // menyimpan total luas tiap tipe finishing

      // hitung total luas tiap tipe finishing
      uniqueTipeFinishing?.map((val) => {
        const totalLuas = listBahanBaku
          ?.filter(
            (item) =>
              item?.id_finishing_barang_jadi === val?.id_finishing_barang_jadi
          )
          .reduce(
            (acc, { total_luas }) => acc + parseFloat(total_luas ?? 0),
            0
          );
        totalLuasTipeFinishing.push({
          ...val,
          total_luas_tipe_finishing: parseFloat(totalLuas).toFixed(6),
        });
      });
      return totalLuasTipeFinishing;
    };

    const getTotalUnit = () => {
      // fungsi mengambil total unit/volume pada list bahan baku (plywood&hardwood) (digunakan sbg konst pada tab 2)
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      const totalUnit = listBahanBaku.reduce(
        (total, { total_unit }) => total + parseFloat(total_unit ?? 0),
        0
      );

      return parseFloat(totalUnit).toPrecision(6);
    };

    // const fetchAnalisaVariable = () => {
    //   // fetch value variable BOK Produksi
    //   setLoading({ ...loading, variable: true })
    //   AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id, tipe: "var" })
    //     .then((res) => setAnalisaVariable(res?.data?.data?.analisa_variabel ?? []))
    //     .finally(() => setLoading({ ...loading, variable: false }))
    // }

    const getSelectData = () => {
      // setLoading({
      //   tipe: true,
      //   finishing: true,
      // });
      // Axios.all([
      //   AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: "fs" }),
      //   AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: "lc" }),
      //   AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: "mp" }),
      //   AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: "bop" }),
      //   AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: "bp" }),
      //   AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: "sc" }),
      //   AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: "lc" }),
      // ])
      //   .then(
      //     Axios.spread((tpfs, tplc, tpmp, tpbop, fnbp, fnsc, fnlc) => {
      //       setDataSelectTPFS(tpfs.data.data);
      //       setDataSelectTPLC(tplc.data.data);
      //       setDataSelectTPMP(tpmp.data.data);
      //       setDataSelectTPBOP(tpbop.data.data);
      //       setDataSelectFNBP(fnbp.data.data);
      //       setDataSelectFNSC(fnsc.data.data);
      //       setDataSelectFNLC(fnlc.data.data);
      //     })
      //   )
      //   .catch(() => window.alert("Data dropdown tipe produksi & finishing gagal diperoleh!"))
      //   .finally(() =>
      //     setLoading({
      //       tipe: false,
      //       finishing: false,
      //     })
      //   );
    };

    const fetchTemplatePenunjang = (
      id_barang_jadi_tipe_produksi,
      id_barang_jadi_bentuk,
      id_jenis_kayu,
      id_barang_jadi_ukuran
    ) => {
      const totalVolume = getTotalUnit();

      if (
        id_barang_jadi_tipe_produksi &&
        id_barang_jadi_bentuk &&
        id_barang_jadi_ukuran &&
        id_jenis_kayu
      ) {
        setLoading({ ...loading, tipe: true });

        const params = {
          id_barang_jadi_tipe_produksi,
          id_barang_jadi_bentuk,
          id_barang_jadi_ukuran,
          id_jenis_kayu,
        };
        AnalisaBarangJadiApi.getSingleTemplatePenunjang(params)
          .then((val) => {
            const filterData = (data) => {
              const newData = [];
              data &&
                data.length > 0 &&
                data.map((val, index) => {
                  newData.push({
                    id_barang_jadi: id,
                    id_item_buaso: val.id_item_bahan,
                    qty: val.qty,
                    unit_price: val.harga_satuan
                      ? parseInt(val.harga_satuan)
                      : 0,
                    konstanta: parseFloat(totalVolume * val.koefisien),
                    kode_item_bahan: val.kode_item_bahan,
                    nama_item: val.nama_item,
                    nama_satuan: val.nama_satuan,
                    urutan_item: index + 1,
                  });
                });
              return newData;
            };

            const data = val.data.data;
            const newDataTPFS = filterData(data.analisa_fin_tp_fs);
            const newDataTPLC = filterData(data.analisa_fin_tp_lc);
            const newDataTPMP = filterData(data.analisa_fin_tp_mp);
            const newDataTPBOP = filterData(data.analisa_fin_tp_bop);

            setDataTPFS(newDataTPFS);
            setDataTPLC(newDataTPLC);
            setDataTPMP(newDataTPMP);
            setDataTPBOP(newDataTPBOP);
          })
          .catch(() => {
            window.alert('Data Template Penunjang Produksi tidak ditemukan');
            setDataTPFS([]);
            setDataTPLC([]);
            setDataTPMP([]);
            setDataTPBOP([]);
          })
          .finally(() => setLoading({ ...loading, tipe: false }));
      }
    };

    const getDetailDataFinishing = (id_tipe, is_fetch_master = false) => {
      setLoading({
        ...loading,
        finishing: true,
      });

      let totalLuasTipeFinishing = getTotalLuasTipeFinishing();
      // Jika user klik btn 'get master' / fetch data finishing dari master
      // maka konstanta finishing master dikali dengan total luas tipe finishing
      const getFinalKonst = (koefisien) => {
        const luasTipeFinishing = totalLuasTipeFinishing.find(
          (val) => val?.id_finishing_barang_jadi === id_tipe
        )?.total_luas_tipe_finishing;
        return luasTipeFinishing * koefisien;
      };

      AnalisaBarangJadiApi.getSingleFinishingBarangJadi({
        id_finishing_barang_jadi: id_tipe,
      })
        .then((val) => {
          const filterData = (prevLength, data) => {
            const newData = [];
            data &&
              data.length > 0 &&
              data.map((val, index) => {
                newData.push({
                  id_barang_jadi: id,
                  id_item_bahan: val.id_item_bahan,
                  id_finishing_barang_jadi: val.id_finishing_barang_jadi,
                  qty: val.qty,
                  unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                  konstanta: is_fetch_master
                    ? getFinalKonst(val.koefisien)
                    : val.koefisien,
                  kode_item_bahan: val.kode_item_bahan,
                  nama_item: val.nama_item,
                  nama_satuan: val.nama_satuan,
                  // urutan_item: index + 1,
                  urutan_item: prevLength + index + 1,
                });
              });
            return newData;
          };

          const data = val.data.data.detail;
          const dataFNBPLength = dataFNBP.length;
          const dataFNLCLength = dataFNLC.length;
          const dataFNSCLength = dataFNSC.length;

          const newDataFNBP = filterData(dataFNBPLength, data.bp);
          const newDataFNLC = filterData(dataFNLCLength, data.lc);
          const newDataFNSC = filterData(dataFNSCLength, data.sc);

          // jika is_fetch_master = true, maka hapus/filter data finishing sebelumnya berdasasarkan id_tipe (replace dengan yang baru)
          if (is_fetch_master) {
            const filteredFNBP = dataFNBP.filter(
              (val) => val.id_finishing_barang_jadi !== id_tipe
            );
            const filteredFNLC = dataFNLC.filter(
              (val) => val.id_finishing_barang_jadi !== id_tipe
            );
            const filteredFNSC = dataFNSC.filter(
              (val) => val.id_finishing_barang_jadi !== id_tipe
            );

            setDataFNBP([...filteredFNBP, ...newDataFNBP]);
            setDataFNLC([...filteredFNLC, ...newDataFNLC]);
            setDataFNSC([...filteredFNSC, ...newDataFNSC]);
          } else {
            setDataFNBP([...newDataFNBP]);
            setDataFNLC([...newDataFNLC]);
            setDataFNSC([...newDataFNSC]);
          }
        })
        .catch(() => {
          window.alert('Gagal memuat data tipe finishing');
          setDataFNBP([]);
          setDataFNLC([]);
          setDataFNSC([]);
        })
        .finally(() =>
          setLoading({
            ...loading,
            finishing: false,
          })
        );
    };

    const filterDataHardwood = () => {
      let finalData = [];
      const data = dataBahanBaku?.analisa_hardwood;
      const sorting = data?.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting?.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          id_jenis_kayu: val.id_jenis_kayu,
          nama_jenis_kayu: val.nama_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
          id_part_kayu: val.id_part_kayu,
          nama_part_kayu: val.nama_part_kayu,
        });
      });

      setDataHardwood(finalData);
    };

    const filterDataPlywood = () => {
      let finalData = [];
      const data = dataBahanBaku?.analisa_plywood;
      const sorting = data?.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting?.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_final: val.qty_final ?? 0,
          t_final: val.t_final ?? 0,
          w_final: val.w_final ?? 0,
          l_final: val.l_final ?? 0,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          kode_item_bahan: val.kode_item_bahan,
          nama_satuan: val.nama_satuan,
          id_item_buaso: val.id_plywood,
          nama_item: val.nama_plywood,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
        });
      });

      setDataPlywood(finalData);
    };

    const filterDataTipeBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_barang_jadi: val.id_barang_jadi,
              id_item_buaso: val.id_item_bahan,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataTPFS = filterData(dataBahanPenunjang?.analisa_fin_tp_fs);
      const newDataTPLC = filterData(dataBahanPenunjang?.analisa_fin_tp_lc);
      const newDataTPMP = filterData(dataBahanPenunjang?.analisa_fin_tp_mp);
      const newDataTPBOP = filterData(dataBahanPenunjang?.analisa_fin_tp_bop);
      const newDataLCOS = filterData(dataOnSite?.analisa_fin_os_lc);
      const newDataMPOS = filterData(dataOnSite?.analisa_fin_os_mp);

      setDataTPFS(newDataTPFS);
      setDataTPLC(newDataTPLC);
      setDataTPMP(newDataTPMP);
      setDataTPBOP(newDataTPBOP);
      setDataLCOS(newDataLCOS);
      setDataMPOS(newDataMPOS);
    };

    const filterDataFinishingBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_analisa_barang_jadi_tipe_fs:
                val.id_analisa_barang_jadi_tipe_fs,
              id_barang_jadi: val.id_barang_jadi,
              id_item_bahan: val.id_item_bahan,
              id_finishing_barang_jadi: val.id_finishing_barang_jadi,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataFNBP = filterData(dataFinishing?.analisa_fin_fn_bp);
      const newDataFNSC = filterData(dataFinishing?.analisa_fin_fn_sc);
      const newDataFNLC = filterData(dataFinishing?.analisa_fin_fn_lc);

      setDataFNBP(newDataFNBP);
      setDataFNSC(newDataFNSC);
      setDataFNLC(newDataFNLC);
    };

    const mappingHardwood = () => {
      const final = [];

      dataHardwood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          is_header: val.is_header,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_jenis_kayu: val.id_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          id_part_kayu: val.id_part_kayu,
        });
      });

      return final;
    };

    const mappingPlywood = () => {
      const final = [];

      dataPlywood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_item_buaso: val.id_item_buaso,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
        });
      });

      return final;
    };

    const mappingTP = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        });
      });

      return final;
    };

    const getGrandTotal = () => {
      const uniqueTipe = getUniqueTipeFinishing().map(
        (val) => val?.id_finishing_barang_jadi
      );
      let totalHardwood = 0;
      let totalPlywood = 0;
      let totalTPFS = 0;
      let totalTPLC = 0;
      let totalTPMP = 0;
      let totalTPBOP = 0;
      let totalFNBP = 0;
      let totalFNSC = 0;
      let totalFNLC = 0;
      let totalLCOS = 0;
      let totalMPOS = 0;

      const totalHW = dataHardwood.reduce((prev, val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
            1000000
        ).toFixed(6);

        const totalUnit =
          parseFloat(countTotalUnit) < 0.000001
            ? '0.000001'
            : parseFloat(countTotalUnit);
        const totalPrice = Math.round(
          parseFloat(totalUnit) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta)
        );
        const checkPrice = val.is_header ? 0 : totalPrice;
        totalHardwood = parseInt(checkPrice);

        return prev + totalHardwood;
      }, 0);

      dataPlywood.map((val) => {
        const total = Math.round(
          parseFloat(val.qty_raw).toFixed(6) *
            parseInt(val.unit_price ?? 0) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalPlywood = parseInt(totalPlywood) + parseInt(total || 0);
      });

      dataTPFS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPFS = parseInt(totalTPFS) + parseInt(total);
      });

      dataTPLC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPLC = parseInt(totalTPLC) + parseInt(total);
      });

      dataTPMP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPMP = parseInt(totalTPMP) + parseInt(total);
      });

      dataTPBOP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
      });

      dataFNBP.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNBP = parseInt(totalFNBP) + parseInt(total);
        }
      });

      dataFNSC.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNSC = parseInt(totalFNSC) + parseInt(total);
        }
      });

      dataFNLC.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNLC = parseInt(totalFNLC) + parseInt(total);
        }
      });

      dataLCOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalLCOS = parseInt(totalLCOS) + parseInt(total);
      });

      dataMPOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalMPOS = parseInt(totalMPOS) + parseInt(total);
      });

      const totalFinishing =
        parseInt(totalFNBP) + parseInt(totalFNSC) + parseInt(totalFNLC);

      const grandTotal =
        parseInt(totalHW) +
        parseInt(totalPlywood) +
        parseInt(totalTPFS) +
        parseInt(totalTPLC) +
        parseInt(totalTPMP) +
        parseInt(totalTPBOP) +
        parseInt(totalFinishing);

      const summaryBiaya = [
        { kode: 'hw', nama_biaya: 'Hardwood', subtotal: parseInt(totalHW) },
        { kode: 'pw', nama_biaya: 'Plywood', subtotal: parseInt(totalPlywood) },
        {
          kode: 'fs',
          nama_biaya: 'Factory Supply',
          subtotal: parseInt(totalTPFS),
        },
        {
          kode: 'lc',
          nama_biaya: 'Labour Cost',
          subtotal: parseInt(totalTPLC),
        },
        {
          kode: 'mp',
          nama_biaya: 'Machine Process',
          subtotal: parseInt(totalTPMP),
        },
        {
          kode: 'bop',
          nama_biaya: 'Overhead Pabrik',
          subtotal: parseInt(totalTPBOP),
        },
        {
          kode: 'fin',
          nama_biaya: 'Finishing',
          subtotal: parseInt(totalFinishing),
        },
      ];

      return summaryBiaya;
      // return RupiahConvert(grandTotal.toString()).detail;
    };

    const submitDataHandler = (id_barang_jadi) => {
      setIsSubmitting(true);

      const analisaHardwood = mappingHardwood();
      const analisaPlywood = mappingPlywood();
      const analisaFinTpFs = mappingTP(dataTPFS);
      const analisaFinTpLc = mappingTP(dataTPLC);
      const analisaFinTpMp = mappingTP(dataTPMP);
      const analisaFinTpBop = mappingTP(dataTPBOP);
      const analisaFinFnBp = mappingFN(dataFNBP);
      const analisaFinFnSc = mappingFN(dataFNSC);
      const analisaFinFnLc = mappingFN(dataFNLC);
      const analisaFinOsLc = mappingTP(dataLCOS);
      const analisaFinOsMp = mappingTP(dataMPOS);

      const finalValues = {
        id_barang_jadi: id_barang_jadi,
        analisa_hardwood: analisaHardwood,
        analisa_plywood: analisaPlywood,
        analisa_fin_tp_fs: analisaFinTpFs,
        analisa_fin_tp_lc: analisaFinTpLc,
        analisa_fin_tp_mp: analisaFinTpMp,
        analisa_fin_tp_bop: analisaFinTpBop,
        analisa_fin_fn_bp: analisaFinFnBp,
        analisa_fin_fn_sc: analisaFinFnSc,
        analisa_fin_fn_lc: analisaFinFnLc,
        analisa_fin_os_mp: analisaFinOsMp,
        analisa_fin_os_lc: analisaFinOsLc,
        // analisa_variabel: analisaVariable
      };

      AnalisaBarangJadiApi.save(finalValues)
        .then(() =>
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil disimpan!',
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal disimpan!',
          })
        )
        .finally(() =>
          history.push('/transaksi/analisa-barang-jadi', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil simpan!',
            },
          })
        );
    };

    useEffect(() => {
      getSelectData();
      // fetchAnalisaVariable();
      filterDataHardwood();
      filterDataPlywood();
      filterDataTipeBarangJadi();
      filterDataFinishingBarangJadi();
    }, []);

    const SelectKelompokTipeBarangJadi = () => {
      const totalVolume = getTotalUnit();
      const updateKonst = () => {
        // Fungsi untuk mengupdate konstanta
        const newDataTPFS = dataTPFS.map((val) =>
          Object({ ...val, konstanta: totalVolume })
        );
        const newDataTPLC = dataTPLC.map((val) =>
          Object({ ...val, konstanta: totalVolume })
        );
        const newDataTPMP = dataTPMP.map((val) =>
          Object({ ...val, konstanta: totalVolume })
        );
        const newDataTPBOP = dataTPBOP.map((val) =>
          Object({ ...val, konstanta: totalVolume })
        );
        setDataTPFS(newDataTPFS);
        setDataTPLC(newDataTPLC);
        setDataTPMP(newDataTPMP);
        setDataTPBOP(newDataTPBOP);
      };
      return (
        <div className="d-flex justify-content-end align-items-start mb-3">
          {/* Total Volume Section */}
          <div className="col-md-2">
            <small className="pl-2 mt-1">Total Volume</small>
            <br />
            <div className="pl-2 mt-1 mr-2 font-weight-bold">
              {`${parseFloat(totalVolume).toPrecision()} (m3)`}
              {/* <ActionButton
                tooltip
                tooltipText="Update Konstanta"
                size="sm"
                className="ml-2"
                onClick={() => updateKonst()}
              >
                <IoSync />
              </ActionButton> */}
            </div>
          </div>
        </div>
      );
    };

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'bahan_baku',
        label: 'Analisa Bahan Baku',
        component: () => (
          <>
            <TableListHardwood
              dataHardwood={dataHardwood}
              setDataHardwood={setDataHardwood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekKayu={dropdownSpekKayu}
              dropdownDeskripsi={dropdownDeskripsi}
              dropdownPart={dropdownPart}
              prevFormState={prevFormState}
              setPrevFormState={setPrevFormState}
            />
            <TableListPlywood
              dataPlywood={dataPlywood}
              setDataPlywood={setDataPlywood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekPlywood={dropdownSpekPlywood}
              dropdownDeskripsi={dropdownDeskripsi}
              prevFormState={prevFormState}
              setPrevFormState={setPrevFormState}
            />
          </>
        ),
      },
      {
        tab: 'penunjang',
        label: 'Analisa Penunjang Produksi',
        component: () => {
          let totalVolume = getTotalUnit();
          return (
            <>
              <SelectKelompokTipeBarangJadi />
              {loading.tipe ? (
                <div style={{ height: '800px' }}>
                  <DataStatus
                    loading={true}
                    text="Memuat data penunjang produksi"
                  />
                </div>
              ) : (
                <>
                  <TableListTPFS
                    dataSelectTPFS={dataSelectTPFS}
                    dataTPFS={dataTPFS}
                    setDataTPFS={setDataTPFS}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPLC
                    dataSelectTPLC={dataSelectTPLC}
                    dataTPLC={dataTPLC}
                    setDataTPLC={setDataTPLC}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPMP
                    dataSelectTPMP={dataSelectTPMP}
                    dataTPMP={dataTPMP}
                    setDataTPMP={setDataTPMP}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPBOP
                    dataSelectTPBOP={dataSelectTPBOP}
                    dataTPBOP={dataTPBOP}
                    setDataTPBOP={setDataTPBOP}
                    defaultKonst={totalVolume}
                  />
                </>
              )}
            </>
          );
        },
      },
      {
        tab: 'finishing',
        label: 'Analisa Finishing',
        component: () => {
          let totalLuasTipeFinishing = getTotalLuasTipeFinishing();
          return (
            <>
              {loading.finishing ? (
                <DataStatus loading={loading.finishing} text="Memuat data" />
              ) : totalLuasTipeFinishing.length > 0 ? (
                totalLuasTipeFinishing.map((val) => {
                  return (
                    <div className="mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="pl-2 font-weight-bold text-uppercase">
                          {val.nama_finishing_barang_jadi}
                        </h5>
                        <div className="d-flex justify-content-center align-items-start mx-2">
                          <div className="mr-3">
                            <small className="pl-2">{`Total Luas Permukaan`}</small>
                            <br />
                            <span className="pl-2 font-weight-bold">{`${parseFloat(
                              val.total_luas_tipe_finishing
                            ).toPrecision()} (m2)`}</span>
                          </div>
                          {/* <div className="pt-1">
                            <ActionButton
                              size="sm"
                              text="Get Master"
                              onClick={() => {
                                getDetailDataFinishing(val.id_finishing_barang_jadi, true);
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                      <TableListFNBP
                        id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                        dataSelectFNBP={dataSelectFNBP}
                        dataFNBP={dataFNBP}
                        setDataFNBP={setDataFNBP}
                        defaultKonst={val.total_luas_tipe_finishing}
                      />
                      <TableListFNSC
                        id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                        dataSelectFNSC={dataSelectFNSC}
                        dataFNSC={dataFNSC}
                        setDataFNSC={setDataFNSC}
                        defaultKonst={val.total_luas_tipe_finishing}
                      />
                      <TableListFNLC
                        id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                        dataSelectFNLC={dataSelectFNLC}
                        dataFNLC={dataFNLC}
                        setDataFNLC={setDataFNLC}
                        defaultKonst={val.total_luas_tipe_finishing}
                      />
                      <hr />
                    </div>
                  );
                })
              ) : (
                <DataStatus text="Data Tipe Finishing Bahan Baku diperlukan" />
              )}
            </>
          );
        },
      },
      // {
      //   tab: "analisa_bok",
      //   label: "Analisa BOK Produksi",
      //   component: () => {
      //     return (
      //       <div className="mt-2">
      //         <TableListBOKProduksi
      //           analisaVariable={analisaVariable}
      //           setAnalisaVariable={setAnalisaVariable}
      //           getGrandTotal={getGrandTotal} />
      //       </div>
      //     )
      //   },
      // },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <>
        {/* Card Analisa Barang Jadi */}
        <h6 className="font-weight-bold text-uppercase">
          Data Analisa Barang Jadi
        </h6>
        <Card className="mb-4">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>

        {/* Card Summary Biaya */}
        <h6 className="font-weight-bold text-uppercase">Summary Biaya</h6>
        <Card>
          <Card.Body>
            <TableListSummary
              getGrandTotal={getGrandTotal}
              // analisaVariable={analisaVariable}
            />
          </Card.Body>
        </Card>

        <div className="col d-flex justify-content-end p-0">
          {/* <ActionButton
            text="Simpan Analisa Barang jadi"
            className="px-4 my-4"
            onClick={() => submitDataHandler(id)}
            loading={isSubmitting}
          />

          <ActionButton
            variant="warning"
            text="Save As"
            className="px-4 ml-2 my-4 text-white"
            onClick={() => setIsModalSaveAs({ show: true })}
          /> */}
        </div>

        {/* {isModalSaveAs.show && (
          <ModalFormBarangJadi
            ID={id}
            modalConfig={isModalSaveAs}
            setModalConfig={setIsModalSaveAs}
            submitDataHandler={submitDataHandler}
          />
        )} */}
      </>
    );
  };

  if (isPageLoading) {
    return <DataStatus loading={true} text="Memuat data . . ." />;
  } else if (isFetchingFailed) {
    return <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />;
  }

  return (
    <>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {!isPageLoading && !isFetchingFailed && <InfoSection />}

      {/* Table Section */}
      {!isPageLoading && !isFetchingFailed && (
        <TableSection
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
      )}

      {/* {!isPageLoading && !isFetchingFailed && (
        <div style={{ display: "none" }}>
          <PrintAnalisaBarangJadi
            ref={componentRef}
            dataAnalisaBarangJadi={dataAnalisaBarangJadi}
          />
        </div>
      )} */}
    </>
  );
};

export default FormAnalisaBarangJadi;
