import { Card } from 'react-bootstrap';
import { CRUDLayout, THead, TBody, Tr, ThFixed, Th, Td } from 'components';
import { RupiahConvert } from 'utilities';

export const TableHasilConvert = ({ data }) => {
  return (
    <>
      <div className="mb-1 font-weight-bold" style={{ fontSize: '.9rem' }}>
        Item Hasil Convert
      </div>
      <Card>
        <Card.Body>
          <CRUDLayout.Table>
            <THead>
              <Tr className="text-center">
                <ThFixed>No.</ThFixed>
                <Th width={200}>Item Barang</Th>
                <Th>Satuan</Th>
                <Th width={200}>Gudang</Th>
                <Th>Qty.</Th>
                <Th>Nilai Persediaan</Th>
              </Tr>
            </THead>
            <TBody>
              {data.detail?.map((val, index) => (
                <Tr key={val.id_convert_barang_detail ?? index}>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td>{val.nama_gudang}</Td>
                  <Td textRight>{val.qty_convert}</Td>
                  <Td textRight>
                    {
                      RupiahConvert(String(parseFloat(val.nilai_persediaan)))
                        .detail
                    }
                  </Td>
                </Tr>
              ))}
            </TBody>
          </CRUDLayout.Table>
        </Card.Body>
      </Card>
    </>
  );
};
