import React, { Fragment, useMemo, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Table, Td, Th, ThFixed } from 'components';
import { ButtonDetail } from 'components2';
import { rupiahConvert } from 'utilities2';
import { calcJumlah } from '../__PengembalianSisaPettyCashUtils__';
import { ModalFormRincian } from './ModalFormRincian';

export const TableSection = ({ action = 'CREATE', data = [] }) => {
  // action : CONFIRM | DETAIL
  const [modal, setModal] = useState({
    show: false,
    type: 'CREATE', // CREATE |  UPDATE
    data: { index: null },
  });
  const { values, setValues } = useFormikContext();
  const petty_cash = values.nominal_penerimaan_petty_cash;
  let saldo_petty_cash = petty_cash;
  const isCreate = modal.type === 'CREATE';

  const jumlah = useMemo(
    () => calcJumlah({ data, petty_cash }),
    [data, petty_cash]
  );

  const checkSaldo = ({ harga_satuan = 0, qty = 0, index = null }) => {
    const itemPrice = Math.round(harga_satuan) * Math.round(qty);

    if (isCreate && !index) {
      return jumlah.sisa_petty_cash >= itemPrice;
    } else {
      const itemBeforeUpdate = data[index];
      const itemPriceBeforeUpdate =
        Math.round(itemBeforeUpdate.harga_satuan) *
        Math.round(itemBeforeUpdate.qty);
      const sisa_petty_cash = jumlah.sisa_petty_cash + itemPriceBeforeUpdate;

      return sisa_petty_cash >= itemPrice;
    }
  };

  return (
    <Fragment>
      <div className="font-weight-bold mb-1">List Data Rincian Petty Cash</div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 100 }} className="p-1">
              Item
            </Th>
            <Th style={{ width: 10 }} className="p-1">
              Qty.
            </Th>
            <Th style={{ width: 100 }} className="p-1">
              Satuan
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Harga
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Jumlah
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Saldo Petty Cash
            </Th>
            <Th style={{ width: 250 }} className="p-1">
              Keterangan
            </Th>
            {action !== 'CONFIRM' && <ThFixed rowSpan={2}>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td textRight colSpan={6} className="font-weight-bold">
              Saldo Petty Cash
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(saldo_petty_cash))}
            </Td>
            <Td>{values.keterangan}</Td>
            {action !== 'CONFIRM' && <Td></Td>}
          </tr>

          {data?.map((e, index) => {
            const jumlah = Math.round(e.qty ?? 0) * Math.round(e.harga_satuan);
            const item_saldo = saldo_petty_cash - jumlah;
            saldo_petty_cash = item_saldo;

            return (
              <tr key={index + e.nama_item}>
                <Td>{index + 1}</Td>
                <Td>{e.nama_item}</Td>
                <Td textRight>{e.qty}</Td>
                <Td>{e.nama_satuan}</Td>
                <Td textRight>
                  {rupiahConvert().getWithComa(String(e.harga_satuan))}
                </Td>
                <Td textRight>{rupiahConvert().getWithComa(String(jumlah))}</Td>
                <Td textRight>
                  {rupiahConvert().getWithComa(String(item_saldo))}
                </Td>
                <Td>{e.keterangan}</Td>
                {action !== 'CONFIRM' && (
                  <Td>
                    <ButtonGroup>
                      <ButtonDetail
                        icon
                        noText
                        onClick={() =>
                          setModal({
                            show: true,
                            type: 'DETAIL',
                            data: { ...e, index },
                          })
                        }
                      />
                    </ButtonGroup>
                  </Td>
                )}
              </tr>
            );
          })}

          <tr>
            <Td colSpan={4} className="font-weight-bold text-right">
              Jumlah
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(jumlah.harga_satuan))}
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(jumlah.jumlah))}
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(jumlah.sisa_petty_cash))}
            </Td>
            <Td colSpan={2}></Td>
          </tr>
        </tbody>
      </Table>

      <ModalFormRincian
        show={modal.show}
        type={modal.type}
        data={modal.data}
        onHide={() => setModal({ show: false, type: 'CREATE', data: {} })}
      />
    </Fragment>
  );
};
