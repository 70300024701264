import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableSF } from './TableSF';

export const ContainerSFDetail = () => {
  const { dataSF } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableSF
        jobOrder
        readOnly
        showTotalPrice
        title="List Subcon Finishing Dari Job Order"
        data={dataSF.jobOrder}
      />
      <TableSF
        readOnly
        showTotalPrice
        title="List Permintaan Subcon Finishing"
        data={dataSF.permintaan}
      />
    </Row>
  );
};
