import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Approval,
} from 'components';
import { InfoItemHorizontal } from 'components2';
import { DateConvert, RupiahConvert, ApprovalStatus } from 'utilities';
import { ApprovalSeleksiVendorApi } from 'api';
import { tglInputFormat, STATUS } from './Utils';
import { InputImages } from './Components';

const DetailApprovalSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const noSeleksiVendor = state?.no_seleksi_vendor;
  const tab = state?.tab ?? '';
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalSeleksiVendorApi.getSingle({ no_seleksi_vendor: noSeleksiVendor })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataSeleksiVendor(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoSeleksiVendor = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataSeleksiVendor.detail.no_transaksi,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataSeleksiVendor.detail.baseline,
    };

    ApprovalSeleksiVendorApi.save(finalValues)
      .then(() =>
        history.push('/human-resource/approval/seleksi-vendor', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Approval berhasil disimpan!',
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Seleksi Vendor');
    noSeleksiVendor ? getInitialData() : whenNoSeleksiVendor();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {props.children}
    </td>
  );

  const InfoSection = () => {
    return (
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Nama Pembuat Pengajuan"
            text={dataSeleksiVendor?.detail?.nama_karyawan_pengaju ?? '-'}
          />

          <InfoItemHorizontal
            label="Jabatan Pembuat Pengajuan"
            text={dataSeleksiVendor?.detail?.nama_jabatan_pengaju ?? '-'}
          />

          <InfoItemHorizontal
            label="Tgl. Input Data"
            text={tglInputFormat({
              created_at: dataSeleksiVendor?.detail?.created_at,
              separator: ' - ',
            })}
          />

          <InfoItemHorizontal
            label="Tgl. Seleksi Vendor"
            text={
              dataSeleksiVendor?.detail?.tgl_seleksi_vendor
                ? DateConvert(
                    new Date(dataSeleksiVendor?.detail?.tgl_seleksi_vendor)
                  ).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Seleksi Vendor"
            text={dataSeleksiVendor?.detail?.no_seleksi_vendor ?? '-'}
          />

          <InfoItemHorizontal
            label="Tgl. Purchase Request"
            text={
              dataSeleksiVendor?.detail?.tgl_purchase_request
                ? DateConvert(
                    new Date(dataSeleksiVendor?.detail?.tgl_purchase_request)
                  ).detail
                : '-'
            }
          />

          <InfoItemHorizontal
            label="No. Purchase Request"
            text={dataSeleksiVendor?.detail?.no_purchase_request ?? '-'}
          />

          <InfoItemHorizontal
            label="Status Approval"
            text={
              STATUS[dataSeleksiVendor?.detail?.status_approval ?? 'PEN'].text
            }
          />
        </Col>

        <Col>
          <InfoItemHorizontal
            label="Proyek"
            text={
              dataSeleksiVendor?.detail?.nama_proyek
                ? dataSeleksiVendor?.detail?.nama_proyek
                : '-'
            }
          />

          <InfoItemHorizontal
            label="Keperluan"
            text={
              dataSeleksiVendor?.detail?.keperluan
                ? dataSeleksiVendor?.detail?.keperluan
                : '-'
            }
          />

          <InfoItemHorizontal
            label="Item Barang"
            text={dataSeleksiVendor?.detail?.nama_item ?? '-'}
          />

          <InfoItemHorizontal
            label="Qty. Seleksi Vendor"
            text={`${parseFloat(dataSeleksiVendor?.detail?.qty_order ?? 0)} ${
              dataSeleksiVendor?.detail?.satuan_beli ?? ''
            }`}
          />

          <InfoItemHorizontal
            label="Vendor"
            text={dataSeleksiVendor?.detail?.nama_vendor ?? '-'}
          />

          <InfoItemHorizontal
            label="Harga Satuan Kesepakatan"
            text={
              dataSeleksiVendor?.detail?.harga_kesepakatan
                ? RupiahConvert(
                    String(
                      parseInt(dataSeleksiVendor?.detail?.harga_kesepakatan)
                    )
                  ).detail
                : 'Rp0'
            }
          />

          <InfoItemHorizontal
            label="Total Harga"
            text={
              RupiahConvert(
                String(
                  Math.round(
                    parseFloat(dataSeleksiVendor?.detail?.harga_kesepakatan) *
                      parseFloat(dataSeleksiVendor?.detail?.qty_order)
                  )
                )
              ).detail
            }
          />

          <InfoItemHorizontal
            label="Catatan Seleksi Vendor"
            text={dataSeleksiVendor?.detail?.keterangan ?? '-'}
          />
        </Col>
      </Row>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const formValidation = Yup.object().shape({
    catatan: Yup.string().required('Masukan Catatan'),
  });

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Seleksi Vendor</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <InputImages
                readOnly
                downloadButton
                label="Dokumen Seleksi Vendor"
                images={
                  dataSeleksiVendor?.detail?.gambar?.map((v) => ({
                    ...v,
                    link: `${REACT_APP_SHOW_FILE_BASE_URL}${v.path_gambar}`,
                  })) ?? []
                }
              />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: '' }}
          validationSchema={formValidation}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            validateForm,
            errors,
            touched,
            setTouched,
            dirty,
          }) => (
            <>
              <Approval
                values={values}
                handleChange={handleChange}
                dirty={dirty}
                validateForm={validateForm}
                errors={errors}
                touched={touched}
                approveStatus={approveStatus}
                data={
                  dataSeleksiVendor?.approval ? dataSeleksiVendor.approval : []
                }
                tab={tab}
                setModalConfig={setModalConfig}
                title="Seleksi Vendor"
              />

              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalSeleksiVendor;
