import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerLCDetail } from '../LabourCost';
import { ContainerLCFDetail } from '../LabourCostFinishing';

export const TabUpah = () => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="lc">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="lc">
            <NavTab eventKey="lc" title="Labour Cost" />
            <NavTab eventKey="lcf" title="Labour Cost Finishing" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane eventKey="lc" component={<ContainerLCDetail />} />
            <TabPane eventKey="lcf" component={<ContainerLCFDetail />} />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
