import {
  ButtonDelete,
  ButtonUpdate,
  DataStatus,
  dateConvert,
  rupiahConvert,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
} from '@bhawanadevteam/react-core';
import { ButtonGroup, Table } from 'react-bootstrap';
import { days } from '../functions';

export const TableItemSection = ({
  isForm = false,
  values = {},
  onUpdate,
  onDelete,
}) => {
  const date = dateConvert();
  const rupiah = rupiahConvert();

  const total_hari = values.reduce(
    (previousValue, { periode_proyek }) =>
      previousValue +
      periode_proyek.reduce(
        (previousValue, { rentang_waktu_end, rentang_waktu_start }) =>
          previousValue + days(rentang_waktu_start, rentang_waktu_end),
        0
      ),
    0
  );
  const total_harga = values.reduce(
    (previousValue, { total_harga }) => previousValue + total_harga,
    0
  );
  const thStyle = {
    position: 'sticky',
    fontSize: '14px',
    border: '0.5px solid rgb(222, 226, 230)',
    textAlign: 'center',
    backgroundColor: '#f3f4f6',
  };
  const borderRight = {
    borderRight: '0.5px solid rgb(222, 226, 230)',
    borderBottom: '0.5px solid rgb(222, 226, 230)',
  };
  const borderLeft = {
    borderLeft: '0.5px solid rgb(222, 226, 230)',
    borderBottom: '0.5px solid rgb(222, 226, 230)',
  };

  return (
    <Table responsive>
      <Thead>
        <tr>
          <Th width={10} rowSpan={2}>
            No.
          </Th>
          <Th className="text-nowrap" width={10} rowSpan={2}>
            Tgl. & No. SPK
          </Th>
          <Th rowSpan={2} width={400}>
            Item Barang Jadi
          </Th>
          <Th width={10} rowSpan={2} className="text-nowrap">
            Total Harga
          </Th>
          <Th colSpan={4}>Periode Proyek</Th>
          {isForm && (
            <Th width={10} rowSpan={2}>
              Aksi
            </Th>
          )}
        </tr>
        <tr>
          <th style={{ ...thStyle, width: '100px' }}>Nama Periode</th>
          <th style={{ ...thStyle, width: '100px' }}>Rentang Waktu</th>
          <th style={{ ...thStyle, width: '80px' }}>Total Hari</th>
          <th style={{ ...thStyle, width: '50px' }}>%</th>
        </tr>
      </Thead>

      <Tbody>
        {values.length === 0 ? (
          <Td colSpan={isForm ? 6 : 5}>
            <DataStatus text="Belum Ada Data BQ Yang Ditambahkan" />
          </Td>
        ) : (
          values.map((val, index) => (
            <tr key={index}>
              <Td>{String(index + 1)}</Td>
              <Td>
                <div>{date.getSlashDMY(new Date(val.tgl_spk))}</div>
                <div className="text-break">{val.no_spk}</div>
              </Td>
              <Td>
                <ul className="pl-3">
                  {val.item_barang_jadi.map((val, index) => (
                    <li key={index}>{val.nama_barang_jadi}</li>
                  ))}
                </ul>
              </Td>
              <Td textRight className="text-nowrap">
                {rupiah.getWithComa(parseInt(val.total_harga ?? 0))}
              </Td>
              <Td noPadding colSpan={4}>
                {val.periode_proyek.map((val, index) => {
                  return (
                    <Table key={index} className="m-0">
                      <Tbody>
                        <td
                          style={{ ...borderRight, width: '100px' }}
                          className="p-2"
                        >
                          {val.nama_periode}
                        </td>
                        <td
                          style={{ ...borderLeft, width: '100px' }}
                          className="p-2"
                        >
                          {date.getSlashDMY(new Date(val.rentang_waktu_start))}{' '}
                          - {date.getSlashDMY(new Date(val.rentang_waktu_end))}
                        </td>
                        <td
                          style={{ ...borderLeft, width: '80px' }}
                          className="p-2 text-right"
                        >
                          {days(val.rentang_waktu_start, val.rentang_waktu_end)}{' '}
                          Hari
                        </td>
                        <td
                          style={{ ...borderLeft, width: '50px' }}
                          className="p-2 text-right"
                        >
                          {parseFloat(val.persentase_periode ?? 0) * 100}%
                        </td>
                      </Tbody>
                    </Table>
                  );
                })}
              </Td>
              {isForm && (
                <Td textCenter>
                  <ButtonGroup>
                    <ButtonDelete icon noText onClick={() => onDelete(index)} />

                    <ButtonUpdate
                      icon
                      noText
                      onClick={() => onUpdate(val, index)}
                    />
                  </ButtonGroup>
                </Td>
              )}
            </tr>
          ))
        )}
      </Tbody>

      <Tfoot>
        <tr>
          <Th colSpan={3} textRight>
            Grand Total Harga
          </Th>
          <Th textRight>{rupiah.getWithComa(total_harga)}</Th>
          <Th colSpan={2} textRight>
            Grand Total Hari
          </Th>
          <Th textRight>{total_hari} Hari</Th>
          <Th></Th>
          {isForm && <Th></Th>}
        </tr>
      </Tfoot>
    </Table>
  );
};
