import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Approval,
} from 'components';
import { InfoItemHorizontal } from 'components2';
import { ApprovalStatus, DateConvert } from 'utilities';
import { MutasiBarangJadiApi } from 'api';

const DetailMutasiAntarGudang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_mutasi_antar_gudang = state?.no_mutasi_antar_gudang;
  const tab = state?.tab ?? '';
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataMutasiGudang, setDataMutasiGudang] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    MutasiBarangJadiApi.getSingle({
      no_mutasi_antar_gudang: no_mutasi_antar_gudang,
    })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataMutasiGudang(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoMutasiGudang = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataMutasiGudang.detail.no_transaksi,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataMutasiGudang.detail.baseline,
    };

    MutasiBarangJadiApi.approve(finalValues)
      .then(() =>
        history.push('/human-resource/approval/mutasi-barang-jadi', {
          alert: {
            show: true,
            variant: 'primary',
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Approval Mutasi Barang Jadi');
    getInitialData();
    no_mutasi_antar_gudang ? getInitialData() : whenNoMutasiGudang();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    return (
      <Row>
        <Col md>
          {/* 
          <InfoItemHorizontal
            label='No. SPK'
            text={dataMutasiGudang?.detail?.no_spk ?? '-'}
          />
          <InfoItemHorizontal
            label='Nama Proyek'
            text={dataMutasiGudang?.detail?.nama_proyek ?? '-'}
          />
          <InfoItemHorizontal
            className='mb-1'
            label='Nama Customer'
            text={dataMutasiGudang?.detail?.nama_customer ?? '-'}
          />
          */}
          <InfoItemHorizontal
            label="Tgl. Mutasi"
            text={
              dataMutasiGudang?.detail?.tgl_mutasi_antar_gudang
                ? DateConvert(
                    new Date(dataMutasiGudang?.detail?.tgl_mutasi_antar_gudang)
                  ).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Mutasi"
            text={dataMutasiGudang?.detail?.no_mutasi_antar_gudang ?? '-'}
          />
          <InfoItemHorizontal
            label="Gudang Asal"
            text={dataMutasiGudang?.detail?.nama_gudang_asal ?? '-'}
          />
          <InfoItemHorizontal
            label="Gudang Tujuan"
            text={dataMutasiGudang?.detail?.nama_gudang_tujuan ?? '-'}
          />
          <InfoItemHorizontal
            label="Item Barang"
            text={`${
              dataMutasiGudang?.detail?.kode_item
                ? `(${dataMutasiGudang?.detail?.kode_item})`
                : ''
            } ${dataMutasiGudang?.detail?.nama_item ?? '-'}`}
          />
          <InfoItemHorizontal
            label="Qty. Mutasi"
            text={`${dataMutasiGudang?.detail?.qty_mutasi ?? '0'} ${
              dataMutasiGudang?.detail?.nama_satuan ?? ''
            }`}
          />
          <InfoItemHorizontal
            label="Catatan Mutasi"
            text={dataMutasiGudang?.detail?.catatan_mutasi ?? '-'}
          />
        </Col>
      </Row>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text ?? '-'}
            variant={
              ApprovalStatus(modalConfig.type, approveStatus).variant ?? '-'
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Mutasi Barang Jadi</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <InfoSection />
          )}
        </Card.Body>
      </Card>

      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: '' }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={
                  dataMutasiGudang?.approval ? dataMutasiGudang.approval : []
                }
                tab={tab}
                setModalConfig={setModalConfig}
                title="Mutasi Barang Jadi"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailMutasiAntarGudang;
