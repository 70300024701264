import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input, DatePicker, SelectSearch } from 'components';
import { SalesOrderSPKApi } from 'api';

const Form = ({ type, formik, dataKaryawan }) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue } = formik;

  const getNomor = (date) => {
    SalesOrderSPKApi.getNomor({ tanggal: date })
      .then(({ data }) => {
        formik.setFieldValue('tgl_sales_order_spk', date);
        formik.setFieldValue('no_sales_order_spk', data.data);
      })
      .catch(() => window.alert('Nomor Sales Order SPK gagal di generate!'));
  };

  useEffect(() => {
    type === 'CREATE' && getNomor(today);
    type === 'CREATE' && setFieldValue('tgl_batas_waktu', today);
  }, []);

  return (
    <Row>
      <Col sm={3}>
        <DatePicker
          disabled={Boolean(type === 'DETAIL')}
          label="Tgl. Sales Order SPK"
          placeholderText="Pilih Tgl. Sales Order SPK"
          selected={
            values.tgl_sales_order_spk
              ? new Date(values.tgl_sales_order_spk)
              : ''
          }
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue('tgl_sales_order_spk', value);
            type === 'CREATE' && getNomor(value);
          }}
          error={
            errors.tgl_sales_order_spk && touched.tgl_sales_order_spk && true
          }
          errorText={
            errors.tgl_sales_order_spk &&
            touched.tgl_sales_order_spk &&
            errors.tgl_sales_order_spk
          }
        />
      </Col>
      <Col sm={3}>
        <Input
          label="No. Sales Order SPK"
          placeholder="Pilih Tgl. Sales Order SPK"
          value={values.no_sales_order_spk}
          readOnly={true}
          error={
            errors.no_sales_order_spk && touched.no_sales_order_spk && true
          }
          errorText={
            errors.no_sales_order_spk &&
            touched.no_sales_order_spk &&
            errors.no_sales_order_spk
          }
        />
      </Col>
      <Col sm={3}>
        <DatePicker
          disabled={Boolean(type === 'DETAIL')}
          label="Tgl. Batas Waktu Pengiriman"
          placeholderText="Pilih Tgl. Batas Waktu"
          selected={
            values.tgl_batas_waktu ? new Date(values.tgl_batas_waktu) : ''
          }
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue('tgl_batas_waktu', value);
          }}
          error={errors.tgl_batas_waktu && touched.tgl_batas_waktu && true}
          errorText={
            errors.tgl_batas_waktu &&
            touched.tgl_batas_waktu &&
            errors.tgl_batas_waktu
          }
        />
      </Col>
      <Col sm={3}>
        <SelectSearch
          isDisabled={Boolean(type === 'DETAIL')}
          label="Sales"
          type="text"
          placeholder="Pilih Sales"
          defaultValue={{ value: values.id_sales, label: values.nama_sales }}
          onChange={(val) => setFieldValue('id_sales', val.value)}
          // option={dataKaryawan}
          error={errors.id_sales && touched.id_sales && true}
          errorText={
            Boolean(errors.id_sales && touched.id_sales) && errors.id_sales
          }
        />
      </Col>
    </Row>
  );
};

export default Form;
