import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components2';
import { DateConvert } from 'utilities';

export const InfoSection = ({
  dataJO,
  showPermintaanProduksi = false,
  showApproval = false,
}) => {
  return (
    <Fragment>
      <Row>
        <Col lg={6}>
          <InfoItemHorizontal
            label="Tgl. RAP"
            text={
              dataJO?.tgl_rap
                ? DateConvert(new Date(dataJO?.tgl_rap)).detail
                : '-'
            }
          />
          <InfoItemHorizontal label="No. RAP" text={dataJO?.no_rap ?? '-'} />
          <InfoItemHorizontal
            label="Customer"
            text={dataJO?.nama_customer ?? '-'}
          />
          <InfoItemHorizontal
            label="Proyek"
            text={dataJO?.nama_proyek ?? '-'}
          />

          {showPermintaanProduksi && (
            <>
              <InfoItemHorizontal
                label="Tgl. Permintaan Produksi"
                text={
                  dataJO?.tgl_permintaan_produksi
                    ? DateConvert(new Date(dataJO?.tgl_permintaan_produksi))
                        .detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Permintaan Produksi"
                text={dataJO?.no_permintaan_produksi ?? '-'}
              />
            </>
          )}

          {showApproval && (
            <InfoItemHorizontal
              label="Status Approval"
              text={STATUS[dataJO?.status_approval ?? 'pen']}
            />
          )}
        </Col>
        <Col lg={6}>
          <InfoItemHorizontal
            label="Tgl. Job Order"
            text={
              dataJO?.tgl_job_order
                ? DateConvert(new Date(dataJO?.tgl_job_order)).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Job Order"
            text={dataJO?.no_job_order ?? '-'}
          />
          <InfoItemHorizontal
            label="Kode Barang Jadi"
            text={dataJO?.kode_barang_jadi ?? '-'}
          />
          <InfoItemHorizontal
            label="Item Job Order"
            text={`${dataJO?.nama_barang_jadi} ${
              dataJO?.qty_rap ? parseFloat(dataJO.qty_rap) : 0
            } ${dataJO?.nama_satuan}`}
          />
          <InfoItemHorizontal
            label="Tgl. Pengerjaan Job Order"
            text={`
            ${
              dataJO?.tgl_mulai_jo
                ? DateConvert(new Date(dataJO?.tgl_mulai_jo)).defaultDMY
                : '-'
            } 
            s.d 
            ${
              dataJO?.tgl_selesai_jo
                ? DateConvert(new Date(dataJO?.tgl_selesai_jo)).defaultDMY
                : '-'
            } `}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

const STATUS = {
  PEN: 'PENDING',
  REV: 'REVISE',
  REJ: 'REJECT',
  VER: 'VERIFY',
  APP: 'APPROVED',
};
