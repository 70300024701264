import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const FilterModal = ({
  show,
  setShow,
  children,
  onResetButtonClick,
  onFilterButtonClick,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <b>Filter Data</b>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onResetButtonClick}>
          RESET
        </Button>
        <Button type="submit" variant="primary" onClick={onFilterButtonClick}>
          FILTER
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
