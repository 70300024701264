export const tglInputFormat = ({ created_at = null, separator = ' ' }) => {
  if (created_at) {
    const inputDate = new Date(created_at);
    const date = inputDate.toLocaleDateString('id-ID');
    const time = inputDate.toLocaleTimeString('en-US', { hour12: false });

    return `${date}${separator}${time}`;
  }
  return '';
};
