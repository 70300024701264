import Services from '../../services';

class ApprovalRAPApi {
  get(params) {
    return Services.get('/hrdu/approval_rap/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_rap/history/list', { params });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_rap/detail', { params });
  }

  save(data) {
    return Services.post('/hrdu/approval_rap/approve', data);
  }
  saveLogProgess(data) {
    return Services.post('/hrdu/log_progress', data);
  }

  // Analisa Barang Jadi
  getAnalisaSingle(params) {
    return Services.get('/hrdu/approval_rap/analisa_barang_jadi_single', {
      params,
    });
  }

  getDropdownTipeFinishing(params) {
    return Services.get('hrdu/approval_rap/d_finishing', { params });
  }
}

export default new ApprovalRAPApi();
