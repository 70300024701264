import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableHardwood } from './TableHardwood';

export const ContainerHardwoodDetail = () => {
  const { dataHardwood } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableHardwood
        jobOrder
        readOnly
        showTotalLuasVolume
        title="List Hardwood Dari Job Order"
        data={dataHardwood.jobOrder}
      />
      <TableHardwood
        readOnly
        showTotalLuasVolume
        showStatusPermintaan
        title="List Permintaan Hardwood"
        data={dataHardwood.permintaan}
      />
    </Row>
  );
};
