import Services from '../../services';

class MutasiAntarGudangApi {
  // API SERVER
  get(params) {
    return Services.get('/hrdu/approval_mutasi_barang_jadi/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_mutasi_barang_jadi/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_mutasi_barang_jadi/detail', { params });
  }

  approve(data) {
    return Services.post('/hrdu/approval_mutasi_barang_jadi/approve', data);
  }
}

export default new MutasiAntarGudangApi();
