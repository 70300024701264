import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoSection = ({ data }) => {
  const InfoItem = ({ title, text }) => (
    <tr>
      <td className="align-top" style={{ fontSize: 14 }}>
        {title}
      </td>
      <td className="pl-4 pr-2 align-top" style={{ fontSize: 14 }}>
        :
      </td>
      <td className="align-top" style={{ fontSize: 14 }}>
        {text}
      </td>
    </tr>
  );
  return (
    <>
      <Row>
        <Col md={6}>
          <InfoItem
            title="Tgl. Faktur"
            text={
              data.tanggal ? DateConvert(new Date(data.tanggal)).detail : '-'
            }
          />
          <InfoItem title="No. Faktur" text={data.no_faktur ?? '-'} />
          <InfoItem title="Keterangan Proyek" text={data.ket_proyek ?? '-'} />
        </Col>
        <Col mmd={6}>
          <InfoItem title="Customer" text={data.nama_customer ?? '-'} />
          <InfoItem title="No. Nota" text={data.no_nota ?? '-'} />
          <InfoItem title="Nama Proyek" text={data.nama_proyek ?? '-'} />
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;
