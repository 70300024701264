import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { DetailInfoSection, TableItemSection } from './components';
import { baseData } from './functions';
import { DataStatus } from '@bhawanadevteam/react-core';
import { Card } from 'react-bootstrap';
import { BackButton } from 'components';

export const RencanaProyekDetail = ({ getData }) => {
  const history = useHistory();

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-end mb-3">
        <h5 className="m-0 font-weight-bold">Detail Data {baseData.title2}</h5>

        <BackButton onClick={() => history.goBack()} />
      </div>

      {getData.isLoading || getData.isError ? (
        <DataStatus
          loading={getData.isLoading}
          text={getData.isLoading ? 'Memuat data' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik initialValues={getData.data?.data?.data?.detail ?? {}}>
          {({ values }) => (
            <Fragment>
              <Card className="mb-2">
                <Card.Header>
                  <b>Informasi Dasar {baseData.title}</b>
                </Card.Header>
                <Card.Body>
                  <DetailInfoSection values={values} />
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  <b>List Item Analisa BQ {baseData.title2}</b>
                </Card.Header>
                <Card.Body>
                  <TableItemSection values={values.item_bq} />
                </Card.Body>
              </Card>
            </Fragment>
          )}
        </Formik>
      )}
    </Fragment>
  );
};
