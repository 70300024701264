import { Row, Col, Card } from 'react-bootstrap';
import { InfoItemVertical } from 'components2';
import { DateConvert } from 'utilities';

export const InfoSection = ({ data }) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Purchase Order"
              text={
                data?.detail?.tgl_purchase_order
                  ? DateConvert(new Date(data?.detail?.tgl_purchase_order))
                      .detail
                  : '-'
              }
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="No. Purchase Order"
              text={data?.detail?.no_purchase_order}
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="Status Approval"
              text={data?.detail?.status_approval}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InfoItemVertical
              label="Metode Pembayaran"
              text={data?.detail?.pembayaran}
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="Tgl. Jatuh Tempo"
              text={
                data?.detail?.tgl_jatuh_tempo
                  ? DateConvert(new Date(data?.detail?.tgl_jatuh_tempo)).detail
                  : '-'
              }
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="Status Purchase Order"
              text={data?.detail?.status_purchase_order}
            />
          </Col>
        </Row>

        <InfoItemVertical
          label="Alamat Tujuan Pengiriman"
          text={data?.detail?.alamat_tujuan_pengiriman}
        />

        <InfoItemVertical
          label="Keterangan Purchase Order"
          text={
            <pre
              style={{
                fontFamily: 'Roboto',
                fontSize: 13,
                lineHeight: 1.7,
              }}
            >
              {data?.detail?.keterangan}
            </pre>
          }
        />
      </Card.Body>
    </Card>
  );
};
