import Services from '../../services';

class PeluangApi {
  get(params) {
    return Services.get('/hrdu/approval_peluang/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_peluang/history', { params });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_peluang/detail', { params });
  }

  getSingleAnalisa(params) {
    return Services.get('/hrdu/approval_peluang/analisa_barang_jadi_single/', {
      params,
    });
  }
  getDropdownTipeFinishing() {
    return Services.get('/hrdu/approval_peluang/d_finishing');
  }

  save(data) {
    return Services.post('/hrdu/approval_peluang/approve', data);
  }
}

export default new PeluangApi();
