import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoSection = ({ data }) => {
  const InfoItem = ({ title, text }) => (
    <tr>
      <td className="align-top" style={{ fontSize: 14 }}>
        {title}
      </td>
      <td className="pl-4 pr-2 align-top" style={{ fontSize: 14 }}>
        :
      </td>
      <td className="align-top" style={{ fontSize: 14 }}>
        {text}
      </td>
    </tr>
  );
  return (
    <>
      <Row>
        <Col md={6}>
          <InfoItem
            title="Tgl. Mutasi"
            text={
              data.tanggal ? DateConvert(new Date(data.tanggal)).detail : '-'
            }
          />
          <InfoItem title="No. Bukti" text={data.nomor_bukti ?? '-'} />
        </Col>
        <Col mmd={6}>
          <InfoItem title="Keterangan" text={data.keterangan ?? '-'} />
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;
