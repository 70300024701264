import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerHardwoodDetail } from '../Hardwood';
import { ContainerPlywoodDetail } from '../Plywood';
import { ContainerFSDetail } from '../FactorySupply';
import { ContainerBPFDetail } from '../BahanPenunjangFinishing';

export const TabBahan = () => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="hardwood">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="hardwood">
            <NavTab eventKey="hardwood" title="Hardwood" />
            <NavTab eventKey="plywood" title="Plywood" />
            <NavTab eventKey="factory" title="Factory Supply" />
            <NavTab eventKey="penunjang" title="Bahan Penunjang Finishing" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane
              eventKey="hardwood"
              component={<ContainerHardwoodDetail />}
            />
            <TabPane
              eventKey="plywood"
              component={<ContainerPlywoodDetail />}
            />
            <TabPane eventKey="factory" component={<ContainerFSDetail />} />
            <TabPane eventKey="penunjang" component={<ContainerBPFDetail />} />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
