import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import {
  CRUDLayout,
  Input,
  InputSearch,
  Tr,
  Th,
  Td,
  ActionButton,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  Alert,
  Pagination,
  Select,
  ReadModal,
} from 'components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { HargaPerkiraanSendiriApi } from 'api';
import { TableNumber, RupiahConvert, DateConvert } from 'utilities';

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const title = 'Harga Perkiraan Sendiri';
  const buaso = [
    {
      label: 'Semua Data',
      value: '',
    },
    {
      label: 'Bahan',
      value: 'Bahan',
    },
    {
      label: 'Upah',
      value: 'Upah',
    },
    {
      label: 'Alat dan mesin',
      value: 'Alat dan mesin',
    },
    {
      label: 'Subkon',
      value: 'Subkon',
    },
    {
      label: 'Overhead',
      value: 'Overhead',
    },
  ];

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // MODALS SHOW STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [key, setKey] = useState('');
  const [filter, setFilter] = useState({
    label: 'Semua Data',
    value: '',
  });
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([
    {
      harga_perkiraan_sendiri: 10000,
      detail: {},
      grup_buaso: 'B',
    },
  ]);
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setShowAlert(false);

    const param = {
      page: page,
      per_page: dataLength,
      q: key,
    };
    if (filter.value !== '') {
      param.grup_buaso = filter.value;
    }

    HargaPerkiraanSendiriApi.page(param)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (key !== '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${key}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, key, filter]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    hps_beli: Yup.string().required(
      'Harga Perkiraan Sendiri Per Satuan Beli wajib diisi'
    ),
    hps_pakai: Yup.string().required(
      'Harga Perkiraan Sendiri Per Satuan Pakai wajib diisi'
    ),
  });

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_item_hps: updateData.id_item,
      hps_beli: RupiahConvert(String(parseInt(updateData.hps_beli))).detail,
      hps_pakai: RupiahConvert(String(parseInt(updateData.hps_pakai))).detail,
      buaso: String(updateData.grup_buaso).charAt(0).toUpperCase(),
      tgl_harga_perkiraan_sendiri: DateConvert(new Date()).default,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        hps_beli: RupiahConvert(values.hps_beli).default,
        hps_pakai: RupiahConvert(values.hps_pakai).default,
      };

      // SEND UPDATED DATA TO SERVER
      HargaPerkiraanSendiriApi.update(finalValues)
        .then(() => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Ubah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <ReadModal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col md={3}>
                    <small>Kode Item</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.kode_item ? updateData.kode_item : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Nama Item</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.nama_item ? updateData.nama_item : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Grup BUASO</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.grup_buaso ? updateData.grup_buaso : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Satuan Pakai</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      :{' '}
                      {updateData.satuan_pakai ? updateData.satuan_pakai : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Satuan Beli</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.satuan_beli ? updateData.satuan_beli : '-'}
                    </small>
                  </Col>
                </Row>
                <hr></hr>

                <Input
                  label="Harga Perkiraan Sendiri Per Satuan Pakai"
                  type="text"
                  name="hps_pakai"
                  value={values.hps_pakai}
                  autoComplete="off"
                  readOnly={true}
                  onChange={(e) => {
                    let nilai = e.target.value;
                    let number = nilai.replace(/[^,\d]/g, '').toString();
                    let convert = RupiahConvert(number).detail;
                    setFieldValue('hps_pakai', convert);
                  }}
                  error={errors.hps_pakai && touched.hps_pakai && true}
                  errorText={errors.hps_pakai}
                />
                <Input
                  label="Harga Perkiraan Sendiri Per Satuan Beli"
                  type="text"
                  name="hps_beli"
                  value={values.hps_beli}
                  autoComplete="off"
                  readOnly={true}
                  onChange={(e) => {
                    let nilai = e.target.value;
                    let number = nilai.replace(/[^,\d]/g, '').toString();
                    let convert = RupiahConvert(number).detail;
                    setFieldValue('hps_beli', convert);
                  }}
                  error={errors.hps_beli && touched.hps_beli && true}
                  errorText={errors.hps_beli}
                />
              </Modal.Body>

              <Modal.Footer>
                {/* <ActionButton
                  type="submit"
                  variant="primary"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                /> */}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </ReadModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th>Kode Item</Th>
              <Th>Nama Item</Th>
              <Th>Grup BUASO</Th>
              <Th>Satuan Pakai</Th>
              <Th>HPS Pakai</Th>
              <Th>Satuan Beli</Th>
              <Th>HPS Beli</Th>
              <Th>Tgl. HPS</Th>
              <Th>Aksi</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <Td className="text-center">
                    {val.kode_item ? val.kode_item : '-'}
                  </Td>
                  <Td>{val.nama_item ? val.nama_item : '-'}</Td>
                  <Td>{val.grup_buaso ? val.grup_buaso : '-'}</Td>
                  <Td>{val.satuan_pakai ? val.satuan_pakai : '-'}</Td>
                  <Td className="text-right text-nowrap">
                    {val.hps_pakai
                      ? RupiahConvert(String(parseInt(val.hps_pakai))).detail
                      : 'Rp 0'}
                  </Td>
                  <Td>{val.satuan_beli ? val.satuan_beli : '-'}</Td>
                  <Td className="text-right text-nowrap">
                    {val.hps_beli
                      ? RupiahConvert(String(parseInt(val.hps_beli))).detail
                      : 'Rp 0'}
                  </Td>
                  <Td>
                    {val.tgl_hps
                      ? DateConvert(new Date(val.tgl_hps)).defaultDMY
                      : '-'}
                  </Td>
                  <TdFixed>
                    <ActionButton
                      type="button"
                      variant="primary"
                      size="sm"
                      text="Detail"
                      onClick={() => {
                        setUpdateData(val);
                        setIsUpdateForm(true);
                      }}
                      className="flex"
                    />
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        {!(key.length > 0) && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <Select
            onChange={(e) =>
              setFilter({
                label: '',
                value: e.target.value,
              })
            }
          >
            <option value="" hidden>
              Pilih Buaso
            </option>
            {buaso.map((val) => (
              <option value={val.value}>{val.label}</option>
            ))}
          </Select>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <UbahModal />
    </CRUDLayout>
  );
};

export default HargaPerkiraanSendiri;
