import {
  accumulateHardwood,
  accumulatePlywood,
  accumulateOthers,
} from './accumulateList';

export const exportHelper = (
  id_rae = null,
  tipe = 'excel',
  dataSummary = []
) => {
  const analisa_hardwood = accumulateHardwood(
    dataSummary?.find((val) => val.nama === 'Hardwood')?.data ?? []
  ).map((val) => ({
    deskripsi: val.deskripsi,
    nama_part_kayu: val.nama_part_kayu,
    nama_jenis_kayu: val.nama_jenis_kayu,
    nama_finishing_barang_jadi: val.nama_finishing_barang_jadi ?? '',
    nama_tipe_sisi: val.nama_tipe_sisi,
    qty_raw: String(val.qty_raw),
    t_raw: String(val.t_raw),
    w_raw: String(val.w_raw),
    l_raw: String(val.l_raw),
    qty_final: String(val.qty_final),
    t_final: String(val.t_final),
    w_final: String(val.w_final),
    l_final: String(val.l_final),
    unit_price: String(val.unit_price),
    konstanta: String(val.konstanta),
    kalkulasi_luas: String(val.total_luas),
    kalkulasi_volume: String(val.total_unit),
    subtotal_harga: String(val.total_price),
  }));

  const analisa_plywood = accumulatePlywood(
    dataSummary?.find((val) => val.nama === 'Plywood')?.data ?? []
  )?.map((val) => ({
    deskripsi: val.deskripsi,
    qty_raw: String(val.qty_raw),
    t_raw: String(val.t_raw),
    w_raw: String(val.w_raw),
    l_raw: String(val.l_raw),
    qty_final: String(val.qty_final),
    t_final: String(val.t_final),
    w_final: String(val.w_final),
    l_final: String(val.l_final),
    unit_price: String(val.unit_price),
    konstanta: String(val.konstanta),
    kode_item: String(val.kode_item),
    nama_item: val.nama_item,
    nama_finishing_barang_jadi: val.nama_finishing_barang_jadi ?? '',
    nama_tipe_sisi: val.nama_tipe_sisi,
    kalkulasi_luas: String(val.total_luas),
    kalkulasi_volume: String(val.total_unit),
    subtotal_harga: String(val.total_price),
  }));

  // map data untuk Biaya Lainnya
  const mapOthers = (dataOthers = []) =>
    dataOthers?.map((val) => ({
      qty: String(val.qty),
      unit_price: String(val.unit_price),
      konstanta: String(val.konstanta),
      ref: String(val.ref),
      kode_item: String(val.kode_item),
      nama_item: val.nama_item,
      kode_satuan: val.kode_satuan,
      nama_satuan: val.nama_satuan,
      nama_finishing_barang_jadi: val.nama_finishing_barang_jadi ?? '',
      subtotal_harga: String(val.total_price),
    }));

  const analisa_fin_tp_fs = mapOthers(
    accumulateOthers(
      dataSummary?.find((val) => val.nama === 'Factory Supply')?.data ?? []
    )
  );
  const analisa_fin_tp_lc = mapOthers(
    accumulateOthers(
      dataSummary?.find((val) => val.nama === 'Labour Cost')?.data ?? []
    )
  );
  const analisa_fin_tp_mp = mapOthers(
    accumulateOthers(
      dataSummary?.find((val) => val.nama === 'Machine Process')?.data ?? []
    )
  );
  const analisa_fin_tp_bop = mapOthers(
    accumulateOthers(
      dataSummary?.find((val) => val.nama === 'Biaya Overhead')?.data ?? []
    )
  );
  const analisa_fin_fn_bp = mapOthers(
    accumulateOthers(
      dataSummary
        ?.find((val) => val.nama === 'Finishing')
        ?.data?.filter(({ ref }) => ref === 'fn_bp')
    )
  );
  const analisa_fin_fn_sc = mapOthers(
    accumulateOthers(
      dataSummary
        ?.find((val) => val.nama === 'Finishing')
        ?.data?.filter(({ ref }) => ref === 'fn_sc')
    )
  );
  const analisa_fin_fn_lc = mapOthers(
    accumulateOthers(
      dataSummary
        ?.find((val) => val.nama === 'Finishing')
        ?.data?.filter(({ ref }) => ref === 'fn_lc')
    )
  );
  const analisa_labour_cost_onsite = mapOthers(
    accumulateOthers(
      dataSummary?.find((val) => val.nama === 'Labour Cost On-Site')?.data
        ?.allLabourCostOnsite ?? []
    )
  );
  const analisa_biaya_overhead_kantor = mapOthers(
    dataSummary?.find((val) => val.nama === 'Biaya Overhead Kantor')?.data ?? []
  );
  const analisa_alat_bantu_onsite = mapOthers(
    dataSummary?.find((val) => val.nama === 'Alat Bantu On-Site')?.data ?? []
  );

  const data = {
    id_rae,
    tipe,
    analisa_hardwood,
    analisa_plywood,
    analisa_fin_tp_fs,
    analisa_fin_tp_lc,
    analisa_fin_tp_mp,
    analisa_fin_tp_bop,
    analisa_fin_fn_bp,
    analisa_fin_fn_sc,
    analisa_fin_fn_lc,
    analisa_labour_cost_onsite,
    analisa_biaya_overhead_kantor,
    analisa_alat_bantu_onsite,
  };

  return data;
};
