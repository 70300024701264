import { Fragment, useState } from 'react';

export const ItemPurchaseOrderCollapse = ({ data = [] }) => {
  const [isMore, setMore] = useState(false);

  const splicedData = [...data].splice(0, 2);
  const results = isMore ? data : splicedData;

  const textItem = (v) => {
    const toFloat = (number) => parseFloat(number);

    return `${v.nama_item ?? v.nama_item_aset ?? v.uraian_pekerjaan} 
    ${toFloat(v.qty_order ?? 0)} 
    ${v.nama_satuan ?? ''}`;
  };

  return (
    <Fragment>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {results?.map((item, index) => (
          <li key={item.id_transfer_sisa_produksi_detail} index={index}>
            {textItem(item)}
          </li>
        ))}
      </ul>

      {data.length > 2 && (
        <u
          className="text-primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setMore((prev) => !prev)}
        >
          {isMore ? 'Sembunyikan' : 'Selengkapnya...'}
        </u>
      )}
    </Fragment>
  );
};
