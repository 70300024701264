import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableMP } from './TableMP';

export const ContainerMPDetail = () => {
  const { dataMP } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableMP
        jobOrder
        readOnly
        showTotalPrice
        title="List Machine Process Dari Job Order"
        data={dataMP.jobOrder}
      />
      <TableMP
        readOnly
        showTotalPrice
        title="List Permintaan Machine Process"
        data={dataMP.permintaan}
      />
    </Row>
  );
};
