export const formInitialValues = (data = {}, action = 'CREATE') => {
  const isCreate = action === 'CREATE';

  return Object({
    id_penerimaan_petty_cash: isCreate
      ? undefined
      : data?.id_penerimaan_petty_cash,
    tgl_penerimaan_petty_cash: isCreate ? '' : data?.tgl_penerimaan_petty_cash,
    no_penerimaan_petty_cash: isCreate ? '' : data?.no_penerimaan_petty_cash,
    nominal_penerimaan_petty_cash: isCreate
      ? data?.nominal_mutasi
      : data?.nominal_penerimaan_petty_cash,
    keterangan: isCreate ? '' : data.keterangan,
    bukti: isCreate ? null : data.foto_penerimaan_petty_cash,

    tgl_pengajuan_petty_cash: data?.tgl_pengajuan_petty_cash ?? '',
    no_pengajuan_petty_cash: data?.no_pengajuan_petty_cash ?? '',
    nominal_pengajuan: data?.nominal_pengajuan ?? 0,
    nama_proyek: data?.nama_proyek ?? '',
    kode_modul: data?.kode_modul ?? '',
    tgl_mutasi_petty_cash: data?.tgl_mutasi_petty_cash ?? '',
    no_bukti_mutasi: data?.no_bukti_mutasi ?? '',
    nominal_mutasi: data?.nominal_mutasi ?? 0,
    tgl_awal_petty_cash: data?.tgl_awal_petty_cash,
    tgl_akhir_petty_cash: data?.tgl_akhir_petty_cash,
  });
};
