import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import {
  ApprovalStatusButton,
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ReadButton,
  FilterButton,
} from 'components';
import {
  DateConvert,
  PageNumber as TableNumber,
  RupiahConvert,
} from 'utilities';
import { ApprovalPurchaseOrderApi } from 'api';
import { refetchInterval } from 'config/refetchInterval';
import { ItemPurchaseOrderCollapse } from '../Components';
import { calculatePageGrandTotal } from '../Utils';
import { useSessionStorage } from 'hooks';
import { ModalFilterPurchaseOrder } from '../Components/ModalFilterPurchaseOrder';

const ApprovalPurchaseOrder = () => {
  const history = useHistory();
  const location = useLocation();

  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(
    `${location.pathname}_pagination`,
    {
      q: '',
      page: '1',
      per_page: '10',

      active: false,
      tgl_input_at_start: undefined,
      tgl_input_at_end: undefined,
      tgl_purchase_order_start: undefined,
      tgl_purchase_order_end: undefined,
      id_jabatan_pengaju: undefined,
      id_karyawan_pengaju: undefined,
      id_vendor: undefined,
      list_item_po: undefined,
      pembayaran: undefined,
      tgl_jatuh_tempo_start: undefined,
      tgl_jatuh_tempo_end: undefined,
      status_approval: undefined,
    }
  );

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const { data, isLoading } = useQuery(
    ['history', 'purchasedOrder', 'list', pagination],
    () => ApprovalPurchaseOrderApi.getHistory(pagination),
    {
      refetchInterval,
      onError: () =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat',
        }),
    }
  );

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const checkStatus = (stat) => {
    let data = stat ? stat.toUpperCase() : '';
    const convert = data.substring(0, 3);

    if (convert === 'APP') {
      return {
        variant: 'outline-success',
        label: 'APPROVED',
      };
    }
    if (convert === 'VER') {
      return {
        variant: 'outline-success',
        label: 'VERIFIED',
      };
    }
    if (convert === 'REV') {
      return {
        variant: 'outline-warning',
        label: 'REVISION',
      };
    }
    if (convert === 'REJ') {
      return {
        variant: 'outline-danger',
        label: 'REJECTED',
      };
    }
    return {
      variant: 'outline-secondary',
      label: 'PENDING',
    };
  };

  useEffect(() => {
    checkAlert();
  }, []);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Informasi Purchase Order</Th>
            <Th>Pembuat Purchase Order</Th>
            <Th>Vendor</Th>
            <Th>Item Purchase Order</Th>
            <Th>Grand Total</Th>
            <Th>Metode Pembayaran</Th>
            <Th>Tgl. Jatuh Tempo</Th>
            <Th>Status Approval</Th>
            <Th>Aksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.data?.data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>

              <Td>
                <div>
                  {val.tgl_purchase_order
                    ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY
                    : '-'}
                </div>
                <div>
                  {val.no_purchase_order ? val.no_purchase_order : '-'}{' '}
                </div>
              </Td>

              <Td>
                <div>
                  {val.nama_karyawan_pengaju ? val.nama_karyawan_pengaju : '-'}
                </div>
                <div>
                  {val.nama_jabatan_pengaju ? val.nama_jabatan_pengaju : '-'}
                </div>
              </Td>

              <Td>{val.nama_vendor ? val.nama_vendor : '-'}</Td>

              <Td>
                <ItemPurchaseOrderCollapse data={val.detail ?? []} />
              </Td>

              <Td textRight>
                {
                  RupiahConvert(
                    String(calculatePageGrandTotal(val.detail ?? []))
                  ).detail
                }
              </Td>

              <Td>{val.pembayaran ?? ''}</Td>

              <Td>
                {val.tgl_jatuh_tempo
                  ? DateConvert(new Date(val.tgl_jatuh_tempo)).defaultDMY
                  : '-'}
              </Td>

              <TdFixed>
                <ApprovalStatusButton
                  variant={checkStatus(val.status_approval).variant}
                >
                  {checkStatus(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>

              <TdFixed>
                <ButtonGroup>
                  <ReadButton
                    onClick={() =>
                      history.push(
                        '/human-resource/approval/purchase-order/detail',
                        {
                          no_po: val.no_purchase_order,
                          tab: 'history',
                        }
                      )
                    }
                  />
                </ButtonGroup>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            data?.data?.data_count < pagination.per_page
              ? data?.data?.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={data?.data?.data_count}
          currentPage={pagination?.page}
          totalPage={data?.data?.total_page}
          onPaginationChange={onPaginationChange}
          onDataLengthChange={onDataLengthChange}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-1">
            <Col className="d-flex flex-row justify-space-between align-items-center">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(
                  (e) =>
                    setPagination((prv) => ({
                      ...prv,
                      q: e.target.value,
                      page: 1,
                    })),
                  1500
                )}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data.data.data.length > 0 ? (
        <PageContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterPurchaseOrder
        showFilterApproval
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default ApprovalPurchaseOrder;
