import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Th,
  Tr,
  Td,
  TBody,
  CRUDLayout,
  TdFixed,
  ThFixed,
  THead,
  Approval,
} from '../../../components';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { RupiahConvert, DateConvert, ApprovalStatus } from '../../../utilities';
import { Formik } from 'formik';
import { KegiatanApi } from '../../../api';

const DetailKegiatan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_kegiatan, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListSO, setDataListSO] = useState([]);
  const [dataKegiatan, setDataKegiatan] = useState({});
  const [data, setData] = useState({ sumber_daya: [] });
  const [approveStatus, setApproveStatus] = useState('V');
  const [dataApproval, setDataApproval] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([
      KegiatanApi.getSingle({ no_kegiatan: no_kegiatan, id_kegiatan: id }),
    ])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : 'V';
          setDataKegiatan(detail);
          setDataApproval(approval ?? []);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return '-';
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty,
  }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Kegiatan</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == 'VER'
                    ? `PEMERIKSA ${val.approval_level}`
                    : 'PENGESAH'
                }
                value1={val.nama_karyawan ?? '-'}
                title2={'Catatan'}
                value2={val.catatan ?? '-'}
              />
            ))}
          </div>
          <hr />
          {tab !== 'history' && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === 'A' ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: 'REJ',
                            title: (
                              <span className="text-danger">
                                Reject Kegiatan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === 'A' ? 'Approve' : 'Verify'}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: 'APP',
                            title: (
                              <span className="text-success">
                                Approve Kegiatan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: 'REJ',
                            title: (
                              <span className="text-danger">
                                Reject Kegiatan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: 'REV',
                            title: (
                              <span className="text-warning">
                                Revise Kegiatan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === 'A' ? 'Approve' : 'Verify'}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: 'APP',
                            title: (
                              <span className="text-success">
                                Approve Kegiatan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  const DetailData = () => {
    const totalSumberDaya = () => {
      let total = 0;

      dataKegiatan?.sumber_daya?.map((val) => {
        const subTotal = parseInt(val.harga_satuan) * parseInt(val.qty);
        total = total + subTotal;
      });

      return total;
    };

    const InfoItem = ({ title, text }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{text}</td>
      </tr>
    );

    const ShowData = ({ title, text }) => (
      <div className="mb-2">
        <div className="text-secondary text-capitalize">
          <small>{title}</small>
        </div>
        <div className="text-dark">{text ? text : '-'}</div>
      </div>
    );

    return (
      <>
        <Row>
          <Col>
            <table>
              <InfoItem
                title="Departemen"
                text={dataKegiatan.program?.nama_unit_organisasi ?? '-'}
              />
              <InfoItem
                title="Penanggung Jawab"
                text={dataKegiatan?.program?.nama_jabatan ?? '-'}
              />
              <InfoItem
                title="Jenis Anggaran"
                text={dataKegiatan?.program?.nama_jenis_anggaran ?? '-'}
              />
            </table>
          </Col>
          <Col>
            <table>
              <InfoItem
                title="Kelompok Anggaran"
                text={dataKegiatan?.program?.nama_kelompok_anggaran ?? '-'}
              />
              <InfoItem
                title="Sub Kelompok Anggaran"
                text={dataKegiatan?.program?.nama_sub_kelompok_anggaran ?? '-'}
              />
              <InfoItem
                title="Nama Program"
                text={dataKegiatan?.program?.nama_program ?? '-'}
              />
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <ShowData
              title="Tanggal Kegiatan :"
              text={
                dataKegiatan?.tgl_input
                  ? DateConvert(new Date(dataKegiatan?.tgl_input)).detail
                  : '-'
              }
              line={true}
            />
            <ShowData
              title="Nama Kegiatan :"
              text={dataKegiatan?.nama_kegiatan}
              line={true}
            />
            <ShowData
              title="Penanggung Jawab :"
              text={dataKegiatan?.nama_karyawan ?? '-'}
              line={true}
            />
            <ShowData
              title="Periode Mulai :"
              text={
                dataKegiatan?.tgl_mulai
                  ? DateConvert(new Date(dataKegiatan?.tgl_mulai)).detail
                  : '-'
              }
              line={true}
            />
            <ShowData
              title="Periode Selesai :"
              text={
                dataKegiatan?.tgl_selesai
                  ? DateConvert(new Date(dataKegiatan?.tgl_selesai)).detail
                  : '-'
              }
              line={true}
            />
          </Col>
          <Col lg={6}>
            <ShowData
              title="No. kegiatan :"
              text={dataKegiatan?.no_kegiatan}
              line={true}
            />
            <ShowData
              title="Keterangan :"
              text={dataKegiatan?.deskripsi_kegiatan}
              line={true}
            />
            {/* <ShowData title="Catatan Approval :" text={dataKegiatan?.catatan} line={true} /> */}
          </Col>
        </Row>
        <hr />
        <div className="pb-2">
          <b>Daftar Sumber Daya</b>
        </div>
        <CRUDLayout.Table>
          <THead>
            <tr className="bg-light">
              <ThFixed>No</ThFixed>
              <Th>Sumber Daya </Th>
              <Th>Kategori Anggaran</Th>
              <Th>Qty</Th>
              <Th>Satuan</Th>
              <Th>Harga Satuan</Th>
              <Th>Sub Total</Th>
            </tr>
          </THead>
          <TBody>
            {dataKegiatan?.sumber_daya &&
            dataKegiatan.sumber_daya.length > 0 ? (
              dataKegiatan?.sumber_daya.map((val, index) => {
                let subTotal;
                subTotal = String(val.harga_satuan * val.qty);
                return (
                  <>
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.item_sumber_daya}</Td>
                      <Td>{val.nama_kategori_sumber_daya}</Td>
                      <Td textRight>{val.qty}</Td>
                      <Td>{val.satuan}</Td>
                      <Td textRight>
                        {RupiahConvert(val.harga_satuan).detail}
                      </Td>
                      <Td textRight>{RupiahConvert(subTotal).detail}</Td>
                    </Tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}
            <tr className="bg-light">
              <td
                colSpan={6}
                className="text-right"
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                {' '}
                Total :{' '}
              </td>
              <td
                className="text-right"
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                <strong>
                  {RupiahConvert(totalSumberDaya().toString()).detail}
                </strong>
              </td>
            </tr>
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  useEffect(() => {
    setNavbarTitle('Approval Kegiatan');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Kegiatan</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <DetailData />
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: '' }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required('Masukan Catatan'),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataKegiatan.no_transaksi,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataKegiatan.baseline,
          };

          KegiatanApi.approve(finalValues)
            .then((res) => {
              history.push('/human-resource/approval/kegiatan', {
                alert: {
                  show: true,
                  variant: 'primary',
                  text: `${
                    modalConfig.type == 'APP'
                      ? 'Approve'
                      : modalConfig.type == 'REV'
                      ? 'Revise'
                      : 'Reject'
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: 'danger',
                text: `Simpan approval gagal! (${
                  err?.response?.data?.message ?? ''
                })`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            {!isPageLoading && (
              <Approval
                values={values}
                handleChange={handleChange}
                dirty={dirty}
                validateForm={validateForm}
                errors={errors}
                touched={touched}
                approveStatus={approveStatus}
                data={dataApproval}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Kegiatan"
              />
            )}
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailKegiatan;
