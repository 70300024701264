/*eslint-disable*/

import {
  IoCheckboxOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoCashOutline,
} from 'react-icons/io5';
import Logo from '../assets/image/LogoSadhana.png';
import Profil from '../pages/Profil';
import {
  MainApprovalRAP,
  DetailRAP,
  MainApprovalSPK,
  DetailApprovalSPK,
  MainApprovalDeliveryOrder,
  DetailApprovalDeliveryOrder,
  MainApprovalFakturPenjualan,
  DetailFakturPenjualan,
  MainApprovalKegiatan,
  DetailKegiatan,
  MainApprovalMutasiAntarGudang,
  DetailMutasiAntarGudang,
  MainApprovalPenerimaanBarang,
  DetailPenerimaanBarang,
  MainApprovalPPA,
  DetailPPA,
  MainApprovalPurchaseOrder,
  DetailApprovalPurchaseOrder,
  MainApprovalPurchaseRequest,
  DetailPurchaseRequest,
  MainApprovalSalesOrder,
  DetailSalesOrder,
  MainApprovalSeleksiVendor,
  DetailApprovalSeleksiVendor,
  MainApprovalSuratJalan,
  DetailSuratJalan,
  MainApprovalPenawaran,
  DetailPenawaran,
  MainApprovalRAB,
  DetailRAB,
  MainApprovalRAE,
  DetailRAE,
  MainApprovalMutasiBarangJadi,
  DetailMutasiBarangJadi,
  MainApprovalPemeliharaanAset,
  DetailPemeliharaanAset,
  MainApprovalPeluang,
  DetailPeluang,
  HargaPerkiraanSendiri,
  DetailAnalisaBarangJadi,
  MainApprovalAnalisaBarangJadi,
  MainApprovalSOSPK,
  DetailApprovalSOSPK,

  // sia
  MainApprovalPengakuanHutang,
  DetailPengakuanHutang,
  MainApprovalJurnalUmum,
  DetailJurnalUmum,
  MainApprovalVoucherPembayaran,
  DetailVoucherPembayaran,
  MainApprovalPengakuanPiutang,
  DetailPengakuanPiutang,
  MainApprovalInvoice,
  DetailInvoice,
  MainApprovalPenyesuaianHutang,
  DetailPenyesuaianHutang,
  MainApprovalPenyesuaianPiutang,
  DetailPenyesuaianPiutang,
  MainApprovalBayarDPVendor,
  DetailBayarDPVendor,
  MainApprovalPenerimaanDPCustomer,
  DetailPenerimaanDPCustomer,
  MainApprovalVoucherGC,
  DetailVoucherGC,
  MainApprovalInvoiceGC,
  DetailInvoiceGC,
  MainApprovalNonVoucherInvoice,
  DetailNonVoucherInvoice,
  MainApprovalMutasi,
  DetailMutasi,
  MainApprovalPengajuanPettyCash,
  DetailApprovalPengajuanPettyCash,
  MainApprovalPenerimaPettyCash,
  DetailApprovalPenerimaPettyCash,
  MainApprovalRencanaProyek,
  DetailApprovalRencanaProyek,
  MainApprovalPenerimaanPettyCash,
  DetailApprovalPenerimaanPettyCash,
  MainApprovalRealisasiPettyCash,
  DetailApprovalRealisasiPettyCash,
  MainApprovalPermintaanProduksi,
  DetailApprovalPermintaanProduksi,
  MainApprovalConvertBarang,
  DetailApprovalConvertBarang,
} from 'pages/Approval';

import {
  PengajuanPettyCashList,

  // Penerima Petty Cash
  PenerimaanPettyCashList,
  PenerimaanMutasiPettyCashList,
  PenerimaanPettyCashTambah,
  PenerimaanPettyCashDetail,
  PenerimaanPettyCashUbah,

  // Realisasi Petty Cash
  RealisasiPettyCashList,
  PenerimaanRealisasiPettyCashList,
  RealisasiPettyCashTambah,
  RealisasiPettyCashUbah,
  RealisasiPettyCashDetail,

  // Pengembalian Sisa Petty Cash
  PengembalianSisaPettyCashList,
  RealisasiPengembalianSisaPettyCashList,
  PengembalianSisaPettyCashTambah,
  PengembalianSisaPettyCashDetail,
} from 'pages/Transaksi';

const generateSpecialHAK = (prefix) => {
  const moduleAlias = [
    'PRY',
    'ASM',
    'TNK',
    'SIA',
    'ANG',
    'CRM',
    'PRO',
    'PRD',
    'SLS',
    'COC',
    'INV',
    'MKT',
  ];

  if (prefix) {
    return moduleAlias.map((modul) => `${prefix}${modul}`);
  }

  return [];
};

export default {
  LOGO: Logo,
  MODUL: 'HRD User',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'HRDU'],
    },
    {
      text: 'Approval',
      type: 'dropdown',
      icon: <IoCheckboxOutline />,
      hak: [
        'SUPA',
        'HRDU',
        'HRDU_APR_KEG',
        'HRDU_APR_PR',
        'HRDU_APR_RK',
        'HRDU_APR_REGRAB',
        'HRDU_APR_REGPNW',
        'HRDU_APR_PVSPR',
        'HRDU_APR_REGPRO',
        'HRDU_APR_PENPO',
        'HRDU_APR_REGFPNJ',
        'HRDU_APR_PENFPNJ',
        'HRDU_APR_REGDO',
        'HRDU_APR_REGPO',
        'HRDU_APR_REGRAE',
        'HRDU_APR_REGRAP',
        'HRDU_APR_REGSJ',
        'HRDU_APR_REGSO',
        'HRDU_APR_REGSPK',
        'HRDU_APR_REGPPA',
        'HRDU_APR_MAG',
        'HRDU_APR_MBJ',
        'HRDU_APR_PA',
        'HRDU_APR_REGPLG',
        'HRDU_APR_REGPEMASET',
        'HRDU_MAS_HPS',
        'HRDU_APR_REGABJ',
        'HRDU_APR_REGRPR',
        'HRDU_APR_PPD',
        'HRDU_APR_CVB',
      ]
        .concat([
          generateSpecialHAK('HRDU_APR_REGPC'),
          generateSpecialHAK('HRDU_APR_REGPNPC'),
          generateSpecialHAK('HRDU_APR_REGRPC'),
        ])
        .flat(),
      menu: [
        {
          text: 'Kegiatan',
          link: '/human-resource/approval/kegiatan',
          hak: ['SUPA', 'HRDU_APR_KEG', 'HRDU_APR_RK'],
        },
        {
          text: 'PPA',
          link: '/human-resource/approval/ppa',
          hak: ['SUPA', 'HRDU_APR_REGPPA'],
        },
        {
          text: 'RAE',
          link: '/human-resource/approval/rae',
          hak: ['SUPA', 'HRDU_APR_REGRAE'],
        },
        {
          text: 'RAB',
          link: '/human-resource/approval/rab',
          hak: ['SUPA', 'HRDU_APR_REGRAB'],
        },
        {
          text: 'RAP',
          link: '/human-resource/approval/rap',
          hak: ['SUPA', 'HRDU_APR_REGRAP'],
        },
        {
          text: 'Penawaran',
          link: '/human-resource/approval/penawaran',
          hak: ['SUPA', 'HRDU_APR_REGPNW'],
        },
        {
          text: 'Purchase Order',
          link: '/human-resource/approval/purchase-order',
          hak: ['SUPA', 'HRDU_APR_REGPO'],
        },
        {
          text: 'Purchase Request (PR)',
          link: '/human-resource/approval/purchase-request',
          hak: ['SUPA', 'HRDU_APR_PR'],
        },
        {
          text: 'Penerimaan Barang',
          link: '/human-resource/approval/penerimaan-barang',
          hak: ['SUPA', 'HRDU_APR_PENPO'],
        },
        {
          text: 'Seleksi Vendor',
          link: '/human-resource/approval/seleksi-vendor',
          hak: ['SUPA', 'HRDU_APR_PVSPR'],
        },
        {
          text: 'Sales Order',
          link: '/human-resource/approval/sales-order',
          hak: ['SUPA', 'HRDU_APR_REGSO'],
        },
        {
          text: 'Surat Perjanjian Kerja',
          link: '/human-resource/approval/surat-perjanjian-kerja',
          hak: ['SUPA', 'HRDU_APR_REGSPK'],
        },
        {
          text: 'Sales Order SPK',
          link: '/human-resource/approval/sales-order-spk',
          hak: ['SUPA', 'HRDU_APR_REGSSPK'],
        },
        {
          text: 'Faktur Penjualan',
          link: '/human-resource/approval/faktur-penjualan',
          hak: ['SUPA', 'HRDU_APR_REGFPNJ'],
        },
        {
          text: 'Delivery Order',
          link: '/human-resource/approval/delivery-order',
          hak: ['SUPA', 'HRDU_APR_REGDO'],
        },
        {
          text: 'Surat Jalan',
          link: '/human-resource/approval/surat-jalan',
          hak: ['SUPA', 'HRDU_APR_REGSJ'],
        },
        {
          text: 'Mutasi Antar Gudang',
          link: '/human-resource/approval/mutasi-antar-gudang',
          hak: ['SUPA', 'HRDU_APR_MAG'],
        },
        {
          text: 'Mutasi Barang Jadi',
          link: '/human-resource/approval/mutasi-barang-jadi',
          hak: ['SUPA', 'HRDU_APR_MBJ'],
        },
        {
          text: 'Pemeliharaan Aset',
          link: '/human-resource/approval/pemeliharaan-aset',
          hak: ['SUPA', 'HRDU_APR_PA', 'HRDU_APR_REGPEMASET'],
        },
        {
          text: 'Peluang',
          link: '/human-resource/approval/peluang',
          hak: ['SUPA', 'HRDU_APR_REGPLG'],
        },
        {
          text: 'Harga Perkiraan Sendiri',
          link: '/human-resource/approval/harga_perkiraan_sendiri',
          hak: ['SUPA', 'HRDU_MAS_HPS'],
        },
        {
          text: 'Analisa Barang Jadi',
          link: '/human-resource/approval/analisa-barang-jadi',
          hak: ['SUPA', 'HRDU_APR_REGABJ'],
        },
        {
          text: 'Jurnal Umum',
          link: '/human-resource/approval/jurnal-umum',
          hak: ['SUPA', 'HRDU_APR_REGGL'],
        },
        {
          text: 'Pengakuan Hutang',
          link: '/human-resource/approval/pengakuan-hutang',
          hak: ['SUPA', 'HRDU_APR_REGAPPH'],
        },
        {
          text: 'Voucher',
          link: '/human-resource/approval/voucher',
          hak: ['SUPA', 'HRDU_APR_REGAPPVC'],
        },
        {
          text: 'Penyesuaian Hutang',
          link: '/human-resource/approval/penyesuaian-hutang',
          hak: ['SUPA', 'HRDU_APR_REGAPPSH'],
        },
        {
          text: 'Pengakuan Piutang',
          link: '/human-resource/approval/pengakuan-piutang',
          hak: ['SUPA', 'HRDU_APR_REGARPP'],
        },
        {
          text: 'Invoice',
          link: '/human-resource/approval/invoice',
          hak: ['SUPA', 'HRDU_APR_REGARPIV'],
        },
        {
          text: 'Penyesuaian Piutang',
          link: '/human-resource/approval/penyesuaian-piutang',
          hak: ['SUPA', 'HRDU_APR_REGARPNP'],
        },
        {
          text: 'Bayar DP Vendor',
          link: '/human-resource/approval/bayar-dp-vendor',
          hak: ['SUPA', 'HRDU_APR_REGGCBDPV'],
        },
        {
          text: 'Penerimaan DP Customer',
          link: '/human-resource/approval/penerimaan-dp-customer',
          hak: ['SUPA', 'HRDU_APR_REGGCPDPK'],
        },
        {
          text: 'Pembayaran Voucher',
          link: '/human-resource/approval/pembayaran-voucher',
          hak: ['SUPA', 'HRDU_APR_REGGCPLKB'],
        },
        {
          text: 'Pembayaran Invoice',
          link: '/human-resource/approval/pembayaran-invoice',
          hak: ['SUPA', 'HRDU_APR_REGGCPNKB'],
        },
        {
          text: 'Non Voucher Invoice',
          link: '/human-resource/approval/non-voucher-invoice',
          hak: ['SUPA', 'HRDU_APR_REGGCNON'],
        },
        {
          text: 'Mutasi',
          link: '/human-resource/approval/mutasi',
          hak: ['SUPA', 'HRDU_APR_REGGCMTKB'],
        },
        {
          text: 'Pengajuan Petty Cash',
          link: '/human-resource/approval/pengajuan-petty-cash',
          hak: ['SUPA'].concat(generateSpecialHAK('HRDU_APR_REGPC')),
        },
        {
          text: 'Penerima Petty Cash',
          link: '/human-resource/approval/penerima-petty-cash',
          hak: ['SUPA', 'HRDU_APR_REGPEC'],
        },
        {
          text: 'Penerimaan Petty Cash',
          link: '/human-resource/approval/penerimaan-petty-cash',
          hak: ['SUPA'].concat(generateSpecialHAK('HRDU_APR_REGPNPC')),
        },
        {
          text: 'Realisasi Petty Cash',
          link: '/human-resource/approval/realisasi-petty-cash',
          hak: ['SUPA'].concat(generateSpecialHAK('HRDU_APR_REGRPC')),
        },
        {
          text: 'Rencana Proyek',
          link: '/human-resource/approval/rencana-proyek',
          hak: ['SUPA', 'HRDU_APR_REGRPR'],
        },
        {
          text: 'Permintaan Produksi',
          link: '/human-resource/approval/permintaan-produksi',
          hak: ['SUPA', 'HRDU_APR_PPD'],
        },
        {
          text: 'Convert Barang',
          link: '/human-resource/approval/convert-barang',
          hak: ['SUPA', 'HRDU_APR_CVB'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: ['SUPA', 'HRDU'],
      menu: [
        {
          text: 'Pengajuan Petty Cash',
          link: '/human-resource/transaksi/pengajuan-petty-cash',
          hak: ['SUPA', 'HRDU_TRN_PJC'],
        },
        {
          text: 'Penerimaan Petty Cash',
          link: '/human-resource/transaksi/penerimaan-petty-cash',
          hak: ['SUPA', 'HRDU_TRN_PNP'],
        },
        {
          text: 'Realisasi Petty Cash',
          link: '/human-resource/transaksi/realisasi-petty-cash',
          hak: ['SUPA', 'HRDU_TRN_RPC'],
        },
        {
          text: 'Pengembalian Sisa Petty Cash',
          link: '/human-resource/transaksi/pengembalian-sisa-petty-cash',
          hak: ['SUPA', 'HRDU_TRN_PSPC'],
        },
      ],
    },
    {
      text: 'Profil',
      type: 'dropdown',
      icon: <IoPersonCircleOutline />,
      hak: ['SUPA', 'HRDU'],
      menu: [
        {
          text: 'Akun Saya',
          link: '/profil',
          hak: ['SUPA', 'HRDU'],
        },
        {
          text: 'Ubah Akun',
          link: '/ubah-profil',
          hak: ['SUPA', 'HRDU'],
        },
        {
          text: 'Ganti Password',
          link: '/ganti-password',
          hak: ['SUPA', 'HRDU'],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      route: '/profil',
      hak: [
        'SUPA',
        'ASM',
        'MKT',
        'CRM',
        'INV',
        'ANG',
        'HRDU',
        'HRDA',
        'HRDU_APR_KEG',
        'HRDU_APR_PR',
        'HRDU_APR_REGRAB',
        'HRDU_APR_REGPNW',
        'HRDU_APR_PVSPR',
        'HRDU_APR_REGPRO',
        'HRDU_APR_PENPO',
        'HRDU_APR_REGFPNJ',
        'HRDU_APR_PENFPNJ',
        'HRDU_APR_REGDO',
        'HRDU_APR_REGPO',
        'HRDU_APR_REGRAE',
        'HRDU_APR_REGRAP',
        'HRDU_APR_REGSJ',
        'HRDU_APR_REGSO',
        'HRDU_APR_REGSPK',
        'HRDU_APR_RK',
        'HRDU_APR_REGPPA',
        'HRDU_APR_MAG',
        'HRDU_APR_MBJ',
        'HRDU_APR_PA',
        'HRDU_APR_REGPLG',
        'HRDU_APR_REGABJ',
        'HRDU_APR_PPD',
      ],
      page: Profil,
    },
    {
      exact: true,
      route: '/human-resource/approval/kegiatan',
      hak: ['SUPA', 'HRDU_APR_KEG', 'HRDU_APR_RK'],
      page: MainApprovalKegiatan,
    },
    {
      exact: true,
      route: '/human-resource/approval/kegiatan/detail/:id',
      hak: ['SUPA', 'HRDU_APR_KEG', 'HRDU_APR_RK'],
      page: DetailKegiatan,
    },
    {
      exact: true,
      route: '/human-resource/approval/ppa',
      hak: ['SUPA', 'HRDU_APR_REGPPA'],
      page: MainApprovalPPA,
    },
    {
      exact: true,
      route: '/human-resource/approval/ppa/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGPPA'],
      page: DetailPPA,
    },
    {
      exact: true,
      route: '/human-resource/approval/rae',
      hak: ['SUPA', 'HRDU_APR_REGRAE'],
      page: MainApprovalRAE,
    },
    {
      exact: true,
      route: '/human-resource/approval/rae/detail',
      hak: ['SUPA', 'HRDU_APR_REGRAE'],
      page: DetailRAE,
    },
    {
      exact: true,
      route: '/human-resource/approval/rab',
      hak: ['SUPA', 'HRDU_APR_REGRAB'],
      page: MainApprovalRAB,
    },
    {
      exact: true,
      route: '/human-resource/approval/rab/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGRAB'],
      page: DetailRAB,
    },
    {
      exact: true,
      route: '/human-resource/approval/rap',
      hak: ['SUPA', 'HRDU_APR_REGRAP'],
      page: MainApprovalRAP,
    },
    {
      exact: true,
      route: '/human-resource/approval/rap/detail',
      hak: ['SUPA', 'HRDU_APR_REGRAP'],
      page: DetailRAP,
    },
    {
      exact: true,
      route: '/human-resource/approval/penawaran',
      hak: ['SUPA', 'HRDU_APR_REGPNW'],
      page: MainApprovalPenawaran,
    },
    {
      exact: true,
      route: '/human-resource/approval/penawaran/detail/:id_penawaran',
      hak: ['SUPA', 'HRDU_APR_REGPNW'],
      page: DetailPenawaran,
    },
    {
      exact: true,
      route: '/human-resource/approval/purchase-request',
      hak: ['SUPA', 'HRDU_APR_PR'],
      page: MainApprovalPurchaseRequest,
    },
    {
      exact: true,
      route: '/human-resource/approval/purchase-request/detail/:id',
      hak: ['SUPA', 'HRDU_APR_PR'],
      page: DetailPurchaseRequest,
    },
    {
      exact: true,
      route: '/human-resource/approval/penerimaan-barang',
      hak: ['SUPA', 'HRDU_APR_PENPO'],
      page: MainApprovalPenerimaanBarang,
    },
    {
      exact: true,
      route: '/human-resource/approval/penerimaan-barang/detail/:id',
      hak: ['SUPA', 'HRDU_APR_PENPO'],
      page: DetailPenerimaanBarang,
    },
    {
      exact: true,
      route: '/human-resource/approval/seleksi-vendor',
      hak: ['SUPA', 'HRDU_APR_PVSPR'],
      page: MainApprovalSeleksiVendor,
    },
    {
      exact: true,
      route: '/human-resource/approval/seleksi-vendor/detail',
      hak: ['SUPA', 'HRDU_APR_PVSPR'],
      page: DetailApprovalSeleksiVendor,
    },
    {
      exact: true,
      route: '/human-resource/approval/purchase-order',
      hak: ['SUPA', 'HRDU_APR_REGPO'],
      page: MainApprovalPurchaseOrder,
    },
    {
      exact: true,
      route: '/human-resource/approval/purchase-order/detail',
      hak: ['SUPA', 'HRDU_APR_REGPO'],
      page: DetailApprovalPurchaseOrder,
    },
    {
      exact: true,
      route: '/human-resource/approval/sales-order',
      hak: ['SUPA', 'HRDU_APR_REGSO'],
      page: MainApprovalSalesOrder,
    },
    {
      exact: true,
      route: '/human-resource/approval/sales-order/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGSO'],
      page: DetailSalesOrder,
    },
    {
      exact: true,
      route: '/human-resource/approval/surat-perjanjian-kerja',
      hak: ['SUPA', 'HRDU_APR_REGSPK'],
      page: MainApprovalSPK,
    },
    {
      exact: true,
      route: '/human-resource/approval/surat-perjanjian-kerja/detail',
      hak: ['SUPA', 'HRDU_APR_REGSPK'],
      page: DetailApprovalSPK,
    },
    {
      exact: true,
      route: '/human-resource/approval/faktur-penjualan',
      hak: ['SUPA', 'HRDU_APR_REGFPNJ'],
      page: MainApprovalFakturPenjualan,
    },
    {
      exact: true,
      route: '/human-resource/approval/faktur-penjualan/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGFPNJ'],
      page: DetailFakturPenjualan,
    },
    {
      exact: true,
      route: '/human-resource/approval/delivery-order',
      hak: ['SUPA', 'HRDU_APR_REGDO'],
      page: MainApprovalDeliveryOrder,
    },
    {
      exact: true,
      route: '/human-resource/approval/delivery-order/detail',
      hak: ['SUPA', 'HRDU_APR_REGDO'],
      page: DetailApprovalDeliveryOrder,
    },
    {
      exact: true,
      route: '/human-resource/approval/surat-jalan',
      hak: ['SUPA', 'HRDU_APR_REGSJ'],
      page: MainApprovalSuratJalan,
    },
    {
      exact: true,
      route: '/human-resource/approval/surat-jalan/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGSJ'],
      page: DetailSuratJalan,
    },
    {
      exact: true,
      route: '/human-resource/approval/mutasi-antar-gudang',
      hak: ['SUPA', 'HRDU_APR_MAG'],
      page: MainApprovalMutasiAntarGudang,
    },
    {
      exact: true,
      route: '/human-resource/approval/mutasi-antar-gudang/detail/:id',
      hak: ['SUPA', 'HRDU_APR_MAG'],
      page: DetailMutasiAntarGudang,
    },
    {
      exact: true,
      route: '/human-resource/approval/mutasi-barang-jadi',
      hak: ['SUPA', 'HRDU_APR_MBJ'],
      page: MainApprovalMutasiBarangJadi,
    },
    {
      exact: true,
      route: '/human-resource/approval/mutasi-barang-jadi/detail/:id',
      hak: ['SUPA', 'HRDU_APR_MBJ'],
      page: DetailMutasiBarangJadi,
    },
    {
      exact: true,
      route: '/human-resource/approval/pemeliharaan-aset',
      hak: ['SUPA', 'HRDU_APR_PA', 'HRDU_APR_REGPEMASET'],
      page: MainApprovalPemeliharaanAset,
    },
    {
      exact: true,
      route: '/human-resource/approval/pemeliharaan-aset/detail/:id',
      hak: ['SUPA', 'HRDU_APR_MBJ'],
      page: DetailPemeliharaanAset,
    },
    {
      exact: true,
      route: '/human-resource/approval/peluang',
      hak: ['SUPA', 'HRDU_APR_REGPLG'],
      page: MainApprovalPeluang,
    },
    {
      exact: true,
      route: '/human-resource/approval/peluang/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGPLG'],
      page: DetailPeluang,
    },
    {
      exact: true,
      route: '/human-resource/approval/harga_perkiraan_sendiri',
      hak: ['SUPA', 'HRDU_MAS_HPS'],
      page: HargaPerkiraanSendiri,
    },
    {
      exact: true,
      route: '/human-resource/approval/analisa-barang-jadi/detail/:id',
      hak: ['SUPA', 'HRDU_APR_REGABJ'],
      page: DetailAnalisaBarangJadi,
    },
    {
      exact: true,
      route: '/human-resource/approval/analisa-barang-jadi',
      hak: ['SUPA', 'HRDU_APR_REGABJ'],
      page: MainApprovalAnalisaBarangJadi,
    },
    {
      exact: true,
      route: '/human-resource/approval/sales-order-spk',
      hak: ['SUPA', 'HRDU_APR_REGSSPK'],
      page: MainApprovalSOSPK,
    },
    {
      exact: true,
      route:
        '/human-resource/approval/sales-order-spk/detail/:id_sales_order_spk',
      hak: ['SUPA', 'HRDU_APR_REGSSPK'],
      page: DetailApprovalSOSPK,
    },

    // Approval Pengakuan Hutang
    {
      exact: true,
      route: '/human-resource/approval/pengakuan-hutang',
      hak: ['HRDU', 'HRDU_APR_REGAPPH'],
      page: MainApprovalPengakuanHutang,
    },
    {
      exact: true,
      route: '/human-resource/approval/pengakuan-hutang/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGAPPH'],
      page: DetailPengakuanHutang,
    },

    // Approval Jurnal Umum
    {
      exact: true,
      route: '/human-resource/approval/jurnal-umum',
      hak: ['HRDU', 'HRDU_APR_REGGL'],
      page: MainApprovalJurnalUmum,
    },
    {
      exact: true,
      route: '/human-resource/approval/jurnal-umum/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGL'],
      page: DetailJurnalUmum,
    },

    // Approval Voucher Pembayaran
    {
      exact: true,
      route: '/human-resource/approval/voucher',
      hak: ['HRDU', 'HRDU_APR_REGAPPVC'],
      page: MainApprovalVoucherPembayaran,
    },
    {
      exact: true,
      route: '/human-resource/approval/voucher/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGAPPVC'],
      page: DetailVoucherPembayaran,
    },

    // Approval Penyesuaian Hutang
    {
      exact: true,
      route: '/human-resource/approval/penyesuaian-hutang',
      hak: ['HRDU', 'HRDU_APR_REGAPPSH'],
      page: MainApprovalPenyesuaianHutang,
    },
    {
      exact: true,
      route: '/human-resource/approval/penyesuaian-hutang/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGAPPSH'],
      page: DetailPenyesuaianHutang,
    },

    // Approval Pengakuan Piutang
    {
      exact: true,
      route: '/human-resource/approval/pengakuan-piutang',
      hak: ['HRDU', 'HRDU_APR_REGARPP'],
      page: MainApprovalPengakuanPiutang,
    },
    {
      exact: true,
      route: '/human-resource/approval/pengakuan-piutang/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGARPP'],
      page: DetailPengakuanPiutang,
    },

    // Approval Invoice
    {
      exact: true,
      route: '/human-resource/approval/invoice',
      hak: ['HRDU', 'HRDU_APR_REGARPIV'],
      page: MainApprovalInvoice,
    },
    {
      exact: true,
      route: '/human-resource/approval/invoice/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGARPIV'],
      page: DetailInvoice,
    },

    // Approval Penyesuaian Piutang
    {
      exact: true,
      route: '/human-resource/approval/penyesuaian-piutang',
      hak: ['HRDU', 'HRDU_APR_REGARPNP'],
      page: MainApprovalPenyesuaianPiutang,
    },
    {
      exact: true,
      route: '/human-resource/approval/penyesuaian-piutang/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGARPNP'],
      page: DetailPenyesuaianPiutang,
    },

    // Approval Bayar DP Vendor
    {
      exact: true,
      route: '/human-resource/approval/bayar-dp-vendor',
      hak: ['HRDU', 'HRDU_APR_REGGCBDPV'],
      page: MainApprovalBayarDPVendor,
    },
    {
      exact: true,
      route: '/human-resource/approval/bayar-dp-vendor/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGCBDPV'],
      page: DetailBayarDPVendor,
    },

    // Approval Penerimaan DP Customer
    {
      exact: true,
      route: '/human-resource/approval/penerimaan-dp-customer',
      hak: ['HRDU', 'HRDU_APR_REGGCPDPK'],
      page: MainApprovalPenerimaanDPCustomer,
    },
    {
      exact: true,
      route: '/human-resource/approval/penerimaan-dp-customer/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGCPDPK'],
      page: DetailPenerimaanDPCustomer,
    },

    // Approval Pembayaran Voucher
    {
      exact: true,
      route: '/human-resource/approval/pembayaran-voucher',
      hak: ['HRDU', 'HRDU_APR_REGGCPLKB'],
      page: MainApprovalVoucherGC,
    },
    {
      exact: true,
      route: '/human-resource/approval/pembayaran-voucher/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGCPLKB'],
      page: DetailVoucherGC,
    },

    // Approval Pembayaran Invoice
    {
      exact: true,
      route: '/human-resource/approval/pembayaran-invoice',
      hak: ['HRDU', 'HRDU_APR_REGGCPNKB'],
      page: MainApprovalInvoiceGC,
    },
    {
      exact: true,
      route: '/human-resource/approval/pembayaran-invoice/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGCPNKB'],
      page: DetailInvoiceGC,
    },

    // Approval Non Voucher Invoice
    {
      exact: true,
      route: '/human-resource/approval/non-voucher-invoice',
      hak: ['HRDU', 'HRDU_APR_REGGCNON'],
      page: MainApprovalNonVoucherInvoice,
    },
    {
      exact: true,
      route: '/human-resource/approval/non-voucher-invoice/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGCNON'],
      page: DetailNonVoucherInvoice,
    },

    // Approval Mutasi
    {
      exact: true,
      route: '/human-resource/approval/mutasi',
      hak: ['HRDU', 'HRDU_APR_REGGCMTKB'],
      page: MainApprovalMutasi,
    },
    {
      exact: true,
      route: '/human-resource/approval/mutasi/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGGCMTKB'],
      page: DetailMutasi,
    },

    // Approval Pengajuan Petty Cash
    {
      exact: true,
      route: '/human-resource/approval/pengajuan-petty-cash',
      hak: ['HRDU'].concat(generateSpecialHAK('HRDU_APR_REGPC')),
      page: MainApprovalPengajuanPettyCash,
    },
    {
      exact: true,
      route: '/human-resource/approval/pengajuan-petty-cash/detail/:id',
      hak: ['HRDU'].concat(generateSpecialHAK('HRDU_APR_REGPC')),
      page: DetailApprovalPengajuanPettyCash,
    },

    // Approval Penerima Petty Cash
    {
      exact: true,
      route: '/human-resource/approval/penerima-petty-cash',
      hak: ['HRDU', 'HRDU_APR_REGPEC'],
      page: MainApprovalPenerimaPettyCash,
    },
    {
      exact: true,
      route: '/human-resource/approval/penerima-petty-cash/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGPEC'],
      page: DetailApprovalPenerimaPettyCash,
    },

    // Approval Realisasi Petty Cash
    {
      exact: true,
      route: '/human-resource/approval/realisasi-petty-cash',
      hak: ['HRDU'].concat(generateSpecialHAK('HRDU_APR_REGRPC')),
      page: MainApprovalRealisasiPettyCash,
    },
    {
      exact: true,
      route: '/human-resource/approval/realisasi-petty-cash/detail/:id',
      hak: ['HRDU'].concat(generateSpecialHAK('HRDU_APR_REGRPC')),
      page: DetailApprovalRealisasiPettyCash,
    },

    // Approval Rencana Proyek
    {
      exact: true,
      route: '/human-resource/approval/rencana-proyek',
      hak: ['HRDU', 'HRDU_APR_REGRPR'],
      page: MainApprovalRencanaProyek,
    },
    {
      exact: true,
      route: '/human-resource/approval/rencana-proyek/detail/:id',
      hak: ['HRDU', 'HRDU_APR_REGRPR'],
      page: DetailApprovalRencanaProyek,
    },

    // Approval Penerimaan Petty Cash
    {
      exact: true,
      route: '/human-resource/approval/penerimaan-petty-cash',
      hak: ['HRDU'].concat(generateSpecialHAK('HRDU_APR_REGPNPC')),
      page: MainApprovalPenerimaanPettyCash,
    },
    {
      exact: true,
      route: '/human-resource/approval/penerimaan-petty-cash/detail/:id',
      hak: ['HRDU'].concat(generateSpecialHAK('HRDU_APR_REGPNPC')),
      page: DetailApprovalPenerimaanPettyCash,
    },

    // Aproval Permintaan Produksi
    {
      exact: true,
      route: '/human-resource/approval/permintaan-produksi',
      hak: ['HRDU', 'HRDU_APR_PPD'],
      page: MainApprovalPermintaanProduksi,
    },
    {
      exact: true,
      route:
        '/human-resource/approval/permintaan-produksi/detail/:id_permintaan_produksi',
      hak: ['HRDU', 'HRDU_APR_PPD'],
      page: DetailApprovalPermintaanProduksi,
    },

    // Aproval Convert Barang
    {
      exact: true,
      route: '/human-resource/approval/convert-barang',
      hak: ['HRDU', 'HRDU_APR_CVB'],
      page: MainApprovalConvertBarang,
    },
    {
      exact: true,
      route:
        '/human-resource/approval/convert-barang/detail/:id_convert_barang',
      hak: ['HRDU', 'HRDU_APR_CVB'],
      page: DetailApprovalConvertBarang,
    },

    // TRANSAKSI

    // Pengajuan Petty Cash
    {
      exact: true,
      route: '/human-resource/transaksi/pengajuan-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_PJC'],
      page: PengajuanPettyCashList,
    },

    // Penerimaan Petty Cash
    {
      exact: true,
      route: '/human-resource/transaksi/penerimaan-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_PNP'],
      page: PenerimaanPettyCashList,
    },
    {
      exact: true,
      route:
        '/human-resource/transaksi/penerimaan-petty-cash/mutasi-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_PNP'],
      page: PenerimaanMutasiPettyCashList,
    },
    {
      exact: true,
      route: '/human-resource/transaksi/penerimaan-petty-cash/tambah',
      hak: ['HRDU', 'HRDU_TRN_PNP'],
      page: PenerimaanPettyCashTambah,
    },
    {
      exact: true,
      route: '/human-resource/transaksi/penerimaan-petty-cash/ubah/:id',
      hak: ['HRDU', 'HRDU_TRN_PNP'],
      page: PenerimaanPettyCashUbah,
    },
    {
      exact: true,
      route: '/human-resource/transaksi/penerimaan-petty-cash/detail/:id',
      hak: ['HRDU', 'HRDU_TRN_PNP'],
      page: PenerimaanPettyCashDetail,
    },

    // Realisasi Petty Cash
    {
      exact: true,
      route: '/human-resource/transaksi/realisasi-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_RPC'],
      page: RealisasiPettyCashList,
    },
    {
      exact: true,
      route:
        '/human-resource/transaksi/realisasi-petty-cash/penerimaan-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_RPC'],
      page: PenerimaanRealisasiPettyCashList,
    },
    {
      exact: true,
      route: '/human-resource/transaksi/realisasi-petty-cash/tambah/:id',
      hak: ['HRDU', 'HRDU_TRN_RPC'],
      page: RealisasiPettyCashTambah,
    },

    {
      exact: true,
      route: '/human-resource/transaksi/realisasi-petty-cash/ubah/:id',
      hak: ['HRDU', 'HRDU_TRN_RPC'],
      page: RealisasiPettyCashUbah,
    },
    {
      exact: true,
      route: '/human-resource/transaksi/realisasi-petty-cash/detail/:id',
      hak: ['HRDU', 'HRDU_TRN_RPC'],
      page: RealisasiPettyCashDetail,
    },

    // Pengembalian Sisa Petty Cash
    {
      exact: true,
      route: '/human-resource/transaksi/pengembalian-sisa-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_PSPC'],
      page: PengembalianSisaPettyCashList,
    },
    {
      exact: true,
      route:
        '/human-resource/transaksi/pengembalian-sisa-petty-cash/realisasi-petty-cash',
      hak: ['HRDU', 'HRDU_TRN_PSPC'],
      page: RealisasiPengembalianSisaPettyCashList,
    },
    {
      exact: true,
      route:
        '/human-resource/transaksi/pengembalian-sisa-petty-cash/tambah/:id',
      hak: ['HRDU', 'HRDU_TRN_PSPC'],
      page: PengembalianSisaPettyCashTambah,
    },
    {
      exact: true,
      route:
        '/human-resource/transaksi/pengembalian-sisa-petty-cash/detail/:id',
      hak: ['HRDU', 'HRDU_TRN_PSPC'],
      page: PengembalianSisaPettyCashDetail,
    },
  ],
};
