import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Row, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { CRUDLayout } from 'components';
import { DataStatus, ButtonBack, ButtonCreate } from 'components2';
import { useModalConfirm } from 'hooks2';
import {
  formInitialValues,
  formValidationSchema,
  formSubmitValueMapper,
  uploadMultiFilesServices,
} from './__RealisasiPettyCashUtils__';
import {
  RealisasiPettyCashContent,
  TableSection,
} from './__RealisasiPettyCashComps__';
import RealisasiPettyCashApi from './__RealisasiPettyCashApi__';

const RealisasiPettyCashTambah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_penerimaan_petty_cash } = useParams();
  const modalConfirm = useModalConfirm();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['single', 'penerimaan_petty_cash', id_penerimaan_petty_cash],
    queryFn: () =>
      RealisasiPettyCashApi.getSinglePenerimaan({ id_penerimaan_petty_cash }),
  });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: 'xl',
      type: 'create',
      customTextHeader: ' ',
      title:
        'Apakah Anda yakin akan menambah data Realisasi Petty Cash berikut?',
      component: (
        <Formik initialValues={values}>
          <Fragment>
            <RealisasiPettyCashContent action="DETAIL" />
            <hr />
            <TableSection action="CONFIRM" data={values?.detail ?? []} />
          </Fragment>
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        const realisasiDetail = [...values?.detail];

        // Upload images in item rincian
        const uploadFilesRincian = await Promise.all(
          realisasiDetail.map(
            async (data) =>
              await uploadMultiFilesServices(data.files)
                .then((res) => ({
                  ...data,
                  media: Object.values(res?.data?.data ?? []).map((path) => ({
                    path_media: path,
                  })),
                }))
                .catch((err) =>
                  alert(
                    `Gagal mengupload files ${err.response.data.message ?? ''}`
                  )
                )
          )
        ).then((uploaded) => uploaded);

        const finalValue = formSubmitValueMapper({
          ...values,
          detail: uploadFilesRincian,
        });

        RealisasiPettyCashApi.create(finalValue)
          .then(() =>
            modalConfirm.infoSuccess({
              title: 'Data Realisasi Petty Cash Berhasil Ditambah',
              customTextInfoDetail: 'Data telah tersimpan ke database',
              size: 'md',
              onHide: () => {
                resetForm();
                modalConfirm.close();
                history.replace(
                  '/human-resource/transaksi/realisasi-petty-cash'
                );
              },
            })
          )
          .catch(({ response }) => {
            modalConfirm.infoError({
              title: 'Data Realisasi Petty Cash Gagal Ditambah',
              customTextInfoTitle: 'Penyebab Kegagalan:',
              customTextInfoDetail: response?.data?.message,
            });
          });
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle('Realisasi Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(data.data, 'CREATE')}
          validationSchema={formValidationSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <Card className="p-4 mt-4">
              <RealisasiPettyCashContent action="CREATE" />
              <hr />
              <TableSection action="CREATE" data={values?.detail ?? []} />
              <Row className="mt-4 px-3 d-flex justify-content-end align-items-center">
                <ButtonCreate
                  loading={isSubmitting}
                  size="md"
                  onClick={() => handleSubmit()}
                />
              </Row>
            </Card>
          )}
        </Formik>
      )}
    </CRUDLayout>
  );
};

export default RealisasiPettyCashTambah;
