import React, { useContext } from 'react';
import { AuthContext } from 'utilities';

const useIsGuest = () => {
  const auth = useContext(AuthContext);
  const role = auth?.state?.role ?? [];
  const isGuest = role.some((val) => val === 'VISIT');

  return isGuest;
};

export default useIsGuest;
