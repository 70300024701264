import { getVolume, kalkulasiLuas } from './helpers';

export const accumulateHardwood = (data) => {
  return data.reduce((agg, curr) => {
    // find identical curr data if exists in agg

    let found = agg.find(
      (x) =>
        Boolean(x.id_deskripsi === curr.id_deskripsi) &&
        Boolean(x.id_jenis_kayu === curr.id_jenis_kayu) &&
        Boolean(x.id_part_kayu === curr.id_part_kayu) &&
        Boolean(x.id_finishing_barang_jadi === curr.id_finishing_barang_jadi) &&
        Boolean(x.id_tipe_sisi === curr.id_tipe_sisi) &&
        Boolean(parseFloat(x.konstanta) === parseFloat(curr.konstanta)) &&
        Boolean(parseFloat(x.t_raw) === parseFloat(curr.t_raw)) &&
        Boolean(parseFloat(x.w_raw) === parseFloat(curr.w_raw)) &&
        Boolean(parseFloat(x.l_raw) === parseFloat(curr.l_raw)) &&
        Boolean(parseFloat(x.t_final) === parseFloat(curr.t_final)) &&
        Boolean(parseFloat(x.w_final) === parseFloat(curr.w_final)) &&
        Boolean(parseFloat(x.l_final) === parseFloat(curr.l_final))
    );

    if (found) {
      const currVolume = parseFloat(
        getVolume(curr.qty_raw, curr.t_raw, curr.w_raw, curr.l_raw)
      );
      const currLuas = parseFloat(
        kalkulasiLuas({
          id_tipe_sisi: curr.id_tipe_sisi,
          t: curr.t_final,
          w: curr.w_final,
          l: curr.l_final,
          qty: curr.qty_final,
        })
      );
      const currPrice = Math.round(
        parseFloat(curr.unit_price) * parseFloat(curr.konstanta) * currVolume
      );

      found['qty_final'] =
        parseFloat(found.qty_final) + parseFloat(curr.qty_final);
      found['qty_raw'] = parseFloat(found.qty_raw) + parseFloat(curr.qty_raw);
      found['total_luas'] = parseFloat(found.total_luas) + currLuas;
      found['total_unit'] = parseFloat(
        parseFloat(found.total_unit) + currVolume
      ).toFixed(6);
      found['total_price'] = Math.round(found.total_price) + currPrice;
    } else {
      const obj = { ...curr };
      const objVolume = parseFloat(
        getVolume(obj.qty_raw, obj.t_raw, obj.w_raw, obj.l_raw)
      );
      const objKonvol = parseFloat(obj.konstanta) * parseFloat(objVolume);
      const objPrice = Math.round(parseFloat(obj.unit_price) * objKonvol);
      const objTotalLuas = kalkulasiLuas({
        id_tipe_sisi: curr.id_tipe_sisi,
        t: curr.t_final,
        w: curr.w_final,
        l: curr.l_final,
        qty: curr.qty_final,
      });

      obj['total_unit'] = objVolume;
      obj['total_luas'] = objTotalLuas;
      obj['total_price'] = objPrice;

      agg.push(obj);
    }

    return agg;
  }, []);
};

export const accumulatePlywood = (data) => {
  return data.reduce((agg, curr) => {
    let found = agg.find(
      (x) =>
        Boolean(x.id_deskripsi === curr.id_deskripsi) &&
        Boolean(x.id_jenis_kayu === curr.id_jenis_kayu) &&
        Boolean(x.id_part_kayu === curr.id_part_kayu) &&
        Boolean(x.id_finishing_barang_jadi === curr.id_finishing_barang_jadi) &&
        Boolean(x.id_tipe_sisi === curr.id_tipe_sisi) &&
        Boolean(parseFloat(x.konstanta) === parseFloat(curr.konstanta)) &&
        Boolean(parseFloat(x.t_raw) === parseFloat(curr.t_raw)) &&
        Boolean(parseFloat(x.w_raw) === parseFloat(curr.w_raw)) &&
        Boolean(parseFloat(x.l_raw) === parseFloat(curr.l_raw)) &&
        Boolean(parseFloat(x.t_final) === parseFloat(curr.t_final)) &&
        Boolean(parseFloat(x.w_final) === parseFloat(curr.w_final)) &&
        Boolean(parseFloat(x.l_final) === parseFloat(curr.l_final))
    );

    if (found) {
      const currVolume = parseFloat(
        getVolume(curr.qty_raw, curr.t_raw, curr.w_raw, curr.l_raw)
      );
      const currLuas = parseFloat(
        kalkulasiLuas({
          id_tipe_sisi: curr.id_tipe_sisi,
          t: curr.t_raw,
          w: curr.w_raw,
          l: curr.l_raw,
          qty: curr.qty_raw,
        })
      );
      const currPrice = parseFloat(curr.qty_raw) * parseFloat(curr.unit_price);

      found['qty_final'] =
        parseFloat(found.qty_final) + parseFloat(curr.qty_final);
      found['qty_raw'] = parseFloat(found.qty_raw) + parseFloat(curr.qty_raw);
      found['total_luas'] = parseFloat(found.total_luas) + currLuas;
      found['total_unit'] = parseFloat(
        parseFloat(found.total_unit) + currVolume
      ).toFixed(6);
      found['total_price'] = Math.round(
        parseFloat(found.total_price) + parseFloat(currPrice)
      );
      found['total_qty_rae'] =
        parseFloat(curr.qty_rae) + parseFloat(found.total_qty_rae);
    } else {
      const obj = { ...curr };
      const objVolume = parseFloat(
        getVolume(obj.qty_raw, obj.t_raw, obj.w_raw, obj.l_raw)
      );
      const objPrice = Math.round(
        parseFloat(obj.qty_raw) * parseFloat(obj.unit_price)
      );
      const objTotalLuas = kalkulasiLuas({
        id_tipe_sisi: curr.id_tipe_sisi,
        t: curr.t_raw,
        w: curr.w_raw,
        l: curr.l_raw,
        qty: curr.qty_raw,
      });

      obj['total_unit'] = objVolume;
      obj['total_luas'] = objTotalLuas;
      obj['total_price'] = objPrice;

      agg.push(obj);
    }
    return agg;
  }, []);
};

export const accumulateOthers = (data) => {
  return data.reduce((agg, curr) => {
    let found = agg.find(
      (x) =>
        Boolean(x.kode_item === curr.kode_item) &&
        Boolean(parseFloat(x.konstanta) === parseFloat(curr.konstanta))
    );

    if (found) {
      const currPrice = Math.round(
        parseFloat(curr.qty ?? 0).toFixed(6) *
          parseFloat(curr.konstanta ?? 0).toFixed(6) *
          parseFloat(curr.unit_price)
      );

      found['qty'] = parseFloat(found.qty) + parseFloat(curr.qty);
      found['total_price'] = Math.round(found.total_price) + currPrice;
    } else {
      const obj = { ...curr };
      obj['total_price'] = Math.round(
        parseFloat(obj.qty ?? 0).toFixed(6) *
          parseFloat(obj.konstanta ?? 0).toFixed(6) *
          parseFloat(obj.unit_price)
      );

      agg.push(obj);
    }
    return agg;
  }, []);
};
