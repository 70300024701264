export const mapServerValues = (data) => {
  const hardwood = data?.map((v) => ({ ...v }));

  const plywood = data?.map((v) => ({
    ...v,
  }));

  const factorySupply = data?.map((v) => ({
    ...v,
    kode_item_bahan: v.kode_item,
  }));

  const bahanPenunjang = data?.map((v) => ({
    ...v,
    kode_item_bahan: v.kode_item,
  }));

  const labourCost = data?.map((v) => ({
    ...v,
    uraian: v.uraian_pekerjaan ?? v.nama_item,
  }));

  const labourCostFinishing = data?.map((v) => ({
    ...v,
    uraian: v.uraian_pekerjaan ?? v.nama_item,
  }));

  const machineProcess = data?.map((v) => ({
    ...v,
    kode_item_bahan: v.kode_item ?? v.kode_item_aset,
    nama_item: v.nama_item ?? v.nama_item_aset,
  }));

  const subconFinishing = data?.map((v) => ({
    ...v,
    uraian: v.uraian_pekerjaan,
  }));

  return {
    hardwood,
    plywood,
    factorySupply,
    bahanPenunjang,
    labourCost,
    labourCostFinishing,
    machineProcess,
    subconFinishing,
  };
};
