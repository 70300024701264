import { useContext } from 'react';
import { useFormik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownSeleksiVendorContext } from '../Context';

export const ModalFilter = ({
  show,
  setShow,
  filter,
  setFilter,
  withStatusApprovalFilter = false,
}) => {
  const { dropdown, loading } = useContext(DropdownSeleksiVendorContext);
  const defaultDropdown = { value: null, label: 'Semua' };
  const initialValues = {
    created_at_start: filter?.created_at_start,
    created_at_end: filter?.created_at_end,
    tgl_seleksi_vendor_start: filter?.tgl_seleksi_vendor_start,
    tgl_seleksi_vendor_end: filter?.tgl_seleksi_vendor_end,
    tgl_purchase_request_start: filter?.tgl_purchase_request_start,
    tgl_purchase_request_end: filter?.tgl_purchase_request_end,
    id_item_buaso: filter?.id_item_buaso,
    id_vendor: filter?.id_vendor,
    status_approval: filter?.status_approval,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    console.log();

    setFilter((prev) => ({ ...prev, ...values, active: false }));
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      created_at_start: undefined,
      created_at_end: undefined,
      tgl_seleksi_vendor_start: undefined,
      tgl_seleksi_vendor_end: undefined,
      tgl_purchase_request_start: undefined,
      tgl_purchase_request_end: undefined,
      id_item_buaso: undefined,
      id_vendor: undefined,
      status_approval: undefined,
    }));

    setShow(false);
  };

  const formik = useFormik({ initialValues, onSubmit });

  const { values, setFieldValue, handleSubmit } = formik;

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Input Data"
        placeholderText="Pilih Tgl. Input Data"
        startDate={
          values.created_at_start ? new Date(values.created_at_start) : ''
        }
        endDate={values.created_at_end ? new Date(values.created_at_end) : ''}
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('created_at_start', startDate);
          setFieldValue('created_at_end', endDate);
        }}
      />
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Seleksi Vendor"
        placeholderText="Pilih Tgl. Seleksi Vendor"
        startDate={
          values.tgl_seleksi_vendor_start
            ? new Date(values.tgl_seleksi_vendor_start)
            : ''
        }
        endDate={
          values.tgl_seleksi_vendor_end
            ? new Date(values.tgl_seleksi_vendor_end)
            : ''
        }
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_seleksi_vendor_start', startDate);
          setFieldValue('tgl_seleksi_vendor_end', endDate);
        }}
      />
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Purchase Request"
        placeholderText="Pilih Tgl. Purchase Request"
        startDate={
          values.tgl_purchase_request_start
            ? new Date(values.tgl_purchase_request_start)
            : ''
        }
        endDate={
          values.tgl_purchase_request_end
            ? new Date(values.tgl_purchase_request_end)
            : ''
        }
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_purchase_request_start', startDate);
          setFieldValue('tgl_purchase_request_end', endDate);
        }}
      />
      <SelectSearch
        label="Item Barang"
        loading={loading}
        placeholder="Pilih Item Barang"
        option={[defaultDropdown].concat(dropdown?.item)}
        defaultValue={
          values.id_item_buaso
            ? dropdown?.item?.find((v) => values.id_item_buaso === v.value)
            : defaultDropdown
        }
        onChange={({ value }) => setFieldValue('id_item_buaso', value)}
      />
      <SelectSearch
        loading={loading}
        label="Vendor"
        placeholder="Pilih Vendor"
        option={[defaultDropdown].concat(dropdown?.vendor)}
        defaultValue={
          values.id_vendor
            ? dropdown?.vendor?.find((v) => values.id_vendor === v.value)
            : defaultDropdown
        }
        onChange={({ value }) => setFieldValue('id_vendor', value)}
      />

      {withStatusApprovalFilter && (
        <SelectSearch
          label="Status Approval"
          placeholder="Pilih Status Approval"
          option={[defaultDropdown].concat(dropdown?.status_approval)}
          onChange={({ value }) => setFieldValue('status_approval', value)}
          defaultValue={
            values.status_approval
              ? dropdown?.status_approval?.find(
                  (s) => values.status_approval === s.value
                )
              : defaultDropdown
          }
        />
      )}
    </FilterModal>
  );
};
