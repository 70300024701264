import Services from '../../services';

class BayarDPVendor {
  // API SERVER
  get(params) {
    return Services.get('hrdu/approval_dp_vendor/list', { params });
  }

  getHistory(params) {
    return Services.get('hrdu/approval_dp_vendor/history/list', { params });
  }

  getSingle(params) {
    return Services.get('hrdu/approval_dp_vendor/detail', { params });
  }

  approve(data) {
    return Services.post('hrdu/approval_dp_vendor/approve', data);
  }
}

export default new BayarDPVendor();
