import Services from '../../services';

class PengakuanHutangApi {
  // API SERVER
  get(params) {
    return Services.get('hrdu/approval_faktur_ar/list', { params });
  }

  getHistory(params) {
    return Services.get('hrdu/approval_faktur_ar/history/list', { params });
  }

  getSingle(params) {
    return Services.get('hrdu/approval_faktur_ar/detail', { params });
  }

  approve(data) {
    return Services.post('hrdu/approval_faktur_ar/approve', data);
  }
}

export default new PengakuanHutangApi();
