import Services from 'services';

class ConvertBarangApi {
  get(params) {
    return Services.get('/hrdu/approval_convert_barang/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_convert_barang/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_convert_barang/detail', { params });
  }

  save(data) {
    return Services.post('/hrdu/approval_convert_barang/approve', data);
  }
}

export default new ConvertBarangApi();
