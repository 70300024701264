import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { RABApi } from 'api';
import { refetchInterval } from 'config/refetchInterval';

const HistoryApprovalRAB = () => {
  const history = useHistory();
  const location = useLocation();
  const [pagination, setPagination] = useState({
    q: '',
    page: '1',
    per_page: '10',
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };
  //handle pindah
  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  //handle banyak data
  const onDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const { data, isLoading } = useQuery(
    ['history', 'rab', 'list', pagination],
    () => RABApi.getHistory(pagination),
    {
      refetchInterval,
      onError: () =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        }),
    }
  );

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case 'APP':
        return 'APPROVED';
      case 'REJ':
        return 'REJECT';
      case 'REV':
        return 'REVISI';
      case 'VER':
        return 'VERIFIED';
      default:
        return 'PENDING';
    }
  };

  useEffect(() => {
    checkAlert();
  }, []);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Pembuat Pengajuan</Th>
            <Th>Jabatan Pembuat Pengajuan</Th>
            <ThFixed>Tgl. RAB</ThFixed>
            <ThFixed>No. RAB</ThFixed>
            <Th>Customer</Th>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Baseline</ThFixed>
            <Th style={{ width: '8.5rem' }}>Detail Pengajuan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.data.data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>
              <Td>
                {val.nama_karyawan_pengaju ? val.nama_karyawan_pengaju : '-'}
              </Td>
              <Td>
                {val.nama_jabatan_pengaju ? val.nama_jabatan_pengaju : '-'}
              </Td>
              <TdFixed>
                {val.tgl_rab ? DateConvert(new Date(val.tgl_rab)).custom : '-'}
              </TdFixed>
              <TdFixed>{val.no_rab ? val.no_rab : '-'}</TdFixed>
              <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
              <TdFixed>
                {val.status_approval
                  ? getStatusApprovalLabel(val.status_approval)
                  : 'PENDING'}
              </TdFixed>
              <TdFixed>{val.baseline ? val.baseline : '-'}</TdFixed>
              <Td>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    text="Lihat Detail"
                    className="col"
                    onClick={() =>
                      history.push(
                        '/human-resource/approval/rab/detail/' + val.id_rab,
                        {
                          no_rab: val.no_rab,
                          baseline: val.baseline,
                          tab: 'history',
                        }
                      )
                    }
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination?.page * pagination?.per_page - pagination?.per_page + 1
          }
          dataPage={
            data?.data?.data_count < pagination.per_page
              ? data?.data?.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={data?.data?.data_count}
          currentPage={pagination?.page}
          totalPage={data?.data?.total_Page}
          onPaginationChange={onPaginationChange}
          onDataLengthChange={onDataLengthChange}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data?.data?.data?.length > 0 ? (
        <PageContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default HistoryApprovalRAB;
