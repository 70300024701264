// fungsi hitung luas(m2)
export const kalkulasiLuas = ({ id_tipe_sisi, qty, t, w, l }) => {
  if (t && w && l) {
    const t_float = parseFloat(t);
    const w_float = parseFloat(w);
    const l_float = parseFloat(l);
    const qty_float = parseFloat(qty);
    switch (id_tipe_sisi) {
      case '2':
        // 4 Sisi
        return ((t_float + w_float) * 2 * qty_float * l_float) / 10000;
      case '3':
        // 3 Sisi Opsi 1
        return ((t_float + w_float + t_float) * qty_float * l_float) / 10000;
      case '4':
        // 3 Sisi Opsi 2
        return ((t_float + w_float + w_float) * qty_float * l_float) / 10000;
      case '5':
        // 2 Sisi Opsi 1
        return ((t_float + w_float) * qty_float * l_float) / 10000;
      case '6':
        // 2 Sisi Opsi 2
        return ((t_float + t_float) * qty_float * l_float) / 10000;
      case '7':
        // 2 Sisi Opsi 3
        return ((w_float + w_float) * qty_float * l_float) / 10000;
      case '8':
        // 1 Sisi Opsi 1
        return (t_float * qty_float * l_float) / 10000;
      case '9':
        // 1 Sisi Opsi 1
        return (w_float * qty_float * l_float) / 10000;
      default:
        return 0;
    }
  }
};
