import { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { IoOptionsOutline } from 'react-icons/io5';
import { Table, Th, Td, Input, DataStatus, ActionButton } from 'components';
import { RupiahConvert } from 'utilities';
import AnalisaBarangJadiRAB from '../AnalisaBarangJadiRAB';
import { ModalPrelim } from '../Section';
import { RABContext } from '../RABContext';

const TableRAB = ({
  profit,
  dataListRAB,
  setDataListRAB,
  getTotal,
  getAfterProfit,
  getGrandTotalPrelim,
  onInputRoundedChange,
  onProfitChange,
}) => {
  const { setIndexAnalisa, setDataPrelim, type: TYPE } = useContext(RABContext);
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const [modalPrelim, setModalPrelim] = useState({
    show: false,
  });

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);
    return convert;
  };

  return (
    <div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th rowSpan={2} width={30}>
              No.
            </Th>
            <Th rowSpan={2} width={120} noPadding>
              Kode Barang Jadi
            </Th>
            <Th rowSpan={2} width={250}>
              Item Barang Jadi
            </Th>
            <Th rowSpan={2}>Qty</Th>
            <Th rowSpan={2}>Satuan</Th>
            <Th rowSpan={2} width={125}>
              Unit Cost
            </Th>
            <Th noPadding width={125}>
              Profit (%)
            </Th>
            <Th rowSpan={2} width={125}>
              Rounded
            </Th>
            <Th rowSpan={2} width={125}>
              Sub Total
            </Th>
          </tr>
          <tr>
            <Th noPadding>
              <Input
                noMargin
                value={profit}
                onChange={(e) => {
                  const value = e.target.value;
                  const convert = decimalConvert(value);

                  onProfitChange(convert);
                }}
                readOnly={TYPE === 'DETAIL' ? true : false}
              />
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataListRAB && dataListRAB.length > 0 ? (
            dataListRAB.map((val, index) => (
              <tr>
                <Td textCenter>{index + 1}</Td>
                <Td>{val.kode_item}</Td>
                <Td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setProcessedData({
                        ...val,
                        index: index,
                      });
                      setIndexAnalisa(index);
                      setModalConfig({
                        show: true,
                        type: 'analisa',
                        title: (
                          <span className="text-primary">
                            Analisa Barang Jadi RAB
                          </span>
                        ),
                      });
                    }}
                  >
                    {val.nama_item}
                  </a>
                </Td>
                <Td textRight>{val.qty ? parseFloat(val.qty) : 0}</Td>
                <Td>{val.nama_satuan}</Td>
                <Td textRight>
                  {val.harga
                    ? RupiahConvert(String(parseInt(val.harga))).detail
                    : RupiahConvert(String(0)).detail}
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(String(parseInt(getAfterProfit(index))))
                      .detail
                  }
                </Td>
                <Td textRight>
                  <Input
                    noMargin
                    value={
                      val.rounded
                        ? RupiahConvert(String(parseInt(val.rounded))).detail
                        : RupiahConvert(String(0)).detail
                    }
                    onChange={(e) => {
                      const value = e.target.value ? e.target.value : 0;
                      const parseToNumber = RupiahConvert(
                        String(parseInt(value))
                      ).default;
                      const parseToRupiah = RupiahConvert(
                        String(parseInt(parseToNumber))
                      ).detail;
                      e.target.value = parseToRupiah;
                      return onInputRoundedChange({
                        index: index,
                        value: parseToNumber,
                      });
                    }}
                    readOnly={TYPE === 'DETAIL' ? true : false}
                  />
                </Td>
                <Td textRight>
                  {RupiahConvert(String(parseInt(getTotal(index)))).detail}
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
          <tr>
            <Td textCenter className="pb-2">
              {dataListRAB && dataListRAB.length > 0
                ? dataListRAB.length + 1
                : 1}
            </Td>
            <Td>-</Td>
            <Td>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setModalPrelim({ show: true });
                }}
              >
                <b>PRELIM</b>
              </a>
            </Td>
            <Td className="text-right">1</Td>
            <Td>-</Td>
            <Td textRight>
              {RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail}
            </Td>
            <Td textRight>
              {RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail}
            </Td>
            <Td textRight>
              {RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail}
            </Td>
            <Td textRight>
              {RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail}
            </Td>
          </tr>
        </tbody>
      </Table>

      {/* Modal Analisa Barang Jadi */}
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size="xl"
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AnalisaBarangJadiRAB
            processedData={processedData}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      </Modal>

      {/* Modal Prelim */}
      <ModalPrelim
        show={modalPrelim.show}
        onHide={() => setModalPrelim({ show: false })}
        onSubmit={(val) => {
          setDataPrelim(val);
          setModalPrelim({ show: false });
        }}
      />
    </div>
  );
};

export default TableRAB;
