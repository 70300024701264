/**
 Table Untuk Summary Biaya Bahan Baku dan Summary Biaya Lainnya

 @props data = Array
 @props type = baku | lainnya
 */

import React from 'react';
import { DataStatus, ReadButton } from 'components';
import { RupiahConvert } from 'utilities';

const TableSummaryBiaya = ({
  data = [],
  type = 'baku',
  setModalConfig,
  footer,
}) => {
  const fixed = { width: '30px' };
  return (
    <>
      <div className="font-weight-bold text-capitalize mb-1">
        Summary Biaya Bahan {type}
      </div>

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: '14px' }}
      >
        <thead className="text-center bg-light">
          <tr>
            <th style={fixed}>No.</th>
            <th className="align-middle">Nama Biaya</th>
            {type === 'baku' && (
              <th style={fixed} className="align-middle">
                Volume (m3)
              </th>
            )}
            <th style={{ width: '180px' }} className="align-middle">
              Total Price (Rp)
            </th>
            <th style={fixed} className="align-middle">
              Aksi
            </th>
          </tr>
        </thead>

        <tbody>
          {data && data.length > 0 ? (
            data.map((val, index) => (
              <tr>
                <td className="text-center">{index + 1}</td>
                <td>{val.nama}</td>
                {type === 'baku' && (
                  <td className="text-right">
                    {parseFloat(val.volume).toFixed(6)}
                  </td>
                )}
                <td className="text-right">
                  {RupiahConvert(String(Math.round(val.totalPrice))).detail}
                </td>
                <td className="text-center align-middle">
                  <ReadButton
                    onClick={() =>
                      setModalConfig({
                        show: true,
                        type: val.nama,
                        data: val.data ?? [],
                      })
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={type === 'baku' ? 5 : 4}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
        {footer}
      </table>
    </>
  );
};

export default TableSummaryBiaya;
