import Services from '../../services';

class ApprovalRAEApi {
  get(params) {
    return Services.get('/hrdu/approval_rae/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_rae/history/list', { params });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_rae/detail', { params });
  }

  getSingleAnalisa(params) {
    return Services.get('/hrdu/approval_rae/analisa_barang_jadi_single/', {
      params,
    });
  }

  getDropdownTipeFinishing() {
    return Services.get('/hrdu/approval_rae/d_finishing');
  }

  getRekapitulasi(params) {
    return Services.get('/hrdu/approval_rae/rekapitulasi', { params });
  }

  save(data) {
    return Services.post('/hrdu/approval_rae/approve', data);
  }

  saveLogProgess(data) {
    return Services.post('/hrdu/log_progress', data);
  }

  getExportRekapitulasi(data) {
    return Services.post('hrdu/approval_rae/export_rekapitulasi', data);
  }
}

export default new ApprovalRAEApi();
