import { createContext, useState } from 'react';
import { useQuery } from 'react-query';
import { ApprovalPurchaseOrderApi } from 'api';

export const DropdownPurchaseOrderContext = createContext();

export const DropdownPurchaseOrderContextProvider = ({ children }) => {
  const dropdownMenuKey = 'purchase_order';

  const dropdownApproval = [
    { value: 'PEN', label: 'PENDING' },
    { value: 'REV', label: 'REVISE' },
    { value: 'VER', label: 'VERIFIED' },
    { value: 'APP', label: 'APPROVED' },
    { value: 'REJ', label: 'REJECTED' },
  ];

  const [dropdownPembayaran, setDropdownPembayaran] = useState([
    { value: 'CASH', label: 'CASH' },
    {
      value: '7 HARI DARI TANDA TERIMA NOTA / INVOICE',
      label: '7 HARI DARI TANDA TERIMA NOTA / INVOICE',
    },
    {
      value: '14 HARI DARI TANDA TERIMA NOTA / INVOICE',
      label: '14 HARI DARI TANDA TERIMA NOTA / INVOICE',
    },
    {
      value: '30 HARI DARI TANDA TERIMA NOTA / INVOICE',
      label: '30 HARI DARI TANDA TERIMA NOTA / INVOICE',
    },
  ]);

  const dropdownJabatan = useQuery(
    ['dropdown', 'jabatan', dropdownMenuKey],
    () =>
      ApprovalPurchaseOrderApi.getDropdown({ tipe: 'jabatan' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_jabatan,
          label: k.nama_jabatan,
        }))
      )
  );

  const useDropdownKaryawan = (id_jabatan) =>
    useQuery(['dropdown', 'karyawan', dropdownMenuKey, id_jabatan], () =>
      ApprovalPurchaseOrderApi.getDropdown({
        tipe: 'karyawan',
        id_jabatan,
      }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_karyawan,
          label: k.nama_karyawan,
        }))
      )
    );

  const dropdownItem = useQuery(['dropdown', 'item', dropdownMenuKey], () =>
    ApprovalPurchaseOrderApi.getDropdown({ tipe: 'item' }).then((res) =>
      res.data.data.map((k) => ({
        value: k.id_item_buaso,
        label: k.nama_item,
      }))
    )
  );

  const dropdownVendor = useQuery(['dropdown', 'vendor', dropdownMenuKey], () =>
    ApprovalPurchaseOrderApi.getDropdown({ tipe: 'vendor' }).then((res) =>
      res.data.data.map((k) => ({ value: k.id_vendor, label: k.nama_vendor }))
    )
  );

  return (
    <DropdownPurchaseOrderContext.Provider
      value={{
        dropdownApproval,
        dropdownPembayaran,
        setDropdownPembayaran,
        dropdownJabatan,
        useDropdownKaryawan,
        dropdownItem,
        dropdownVendor,
      }}
    >
      {children}
    </DropdownPurchaseOrderContext.Provider>
  );
};

export const withDropdownPurchaseOrderContextProvider =
  (Component) => (props) => {
    return (
      <DropdownPurchaseOrderContextProvider>
        <Component {...props} />
      </DropdownPurchaseOrderContextProvider>
    );
  };
