import React, { useState, useEffect } from 'react';
import { RupiahConvert } from 'utilities';
import { Table, Th, Td } from 'components';

const TableListSummary = ({
  getGrandTotal,
  getGrandTotalPrice,
  dataAlatBantuOnSite,
  dataAnalisaBOK,
}) => {
  const [dataSummary, setDataSummary] = useState([]);
  const total = dataSummary.reduce(
    (total, { subtotal }) => total + parseInt(subtotal ?? 0),
    0
  );

  const dataListSummary = () => {
    const {
      bahanBaku,
      penunjangProduksi,
      finishing,
      alatBantuOnsite,
      labourCostOnsite,
      bok,
    } = getGrandTotalPrice();

    setDataSummary([
      { nama_biaya: 'Analisa Bahan Baku', subtotal: bahanBaku },
      { nama_biaya: 'Analisa Penunjang Produksi', subtotal: penunjangProduksi },
      { nama_biaya: 'Analisa Finishing', subtotal: finishing },
      { nama_biaya: 'Analisa Alat Bantu On-Site', subtotal: alatBantuOnsite },
      { nama_biaya: 'Analisa Labour Cost On-Site', subtotal: labourCostOnsite },
      { nama_biaya: 'Analisa Biaya Overhead Kantor', subtotal: bok },
    ]);
  };

  useEffect(() => {
    dataListSummary();
  }, [dataAlatBantuOnSite, dataAnalisaBOK, getGrandTotal]);

  return (
    <div className="table-responsive">
      <Table>
        <thead className="text-center bg-light">
          <tr>
            <Th>No.</Th>
            <Th>Nama Biaya</Th>
            <Th width={300}> Total Price (Rp)</Th>
          </tr>
        </thead>
        <tbody>
          {dataSummary?.map((val, index) => (
            <tr key={index}>
              <Td textCenter width={20}>
                {index + 1}
              </Td>
              <Td>{val.nama_biaya}</Td>
              <Td textRight>
                {RupiahConvert(String(parseInt(val.subtotal) ?? 0)).detail}
              </Td>
            </tr>
          ))}
          <tr className="bg-light">
            <Td colSpan={2} className="p-2" textRight>
              <b style={{ fontSize: 14 }}>Grand Total</b>
            </Td>
            <Td textRight className="p-2">
              <b style={{ fontSize: 14 }}>
                {RupiahConvert(String(parseInt(total) ?? 0)).detail}
              </b>
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableListSummary;
