import React, { useEffect } from 'react';
import { useSessionStorage } from 'hooks';
import { Card, Nav } from 'react-bootstrap';
import {
  ApprovalPermintaanProduksi,
  HistoryApprovalPermintaanProduksi,
} from './Tabs';
import { withDropdownPermintaanProduksiContextProvider } from './Context';

const MainApprovalPermintaanProduksi = ({ setNavbarTitle }) => {
  const [tabs, setTabs] = useSessionStorage('active', 'approval');

  const tabsConfig = [
    {
      tab: 'approval',
      label: 'Approval',
      component: () => <ApprovalPermintaanProduksi />,
    },
    {
      tab: 'history',
      label: 'History',
      component: () => <HistoryApprovalPermintaanProduksi />,
    },
  ];

  useEffect(() => setNavbarTitle('Approval Permintaan Produksi'), []);

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
          {tabsConfig.map((val, index) => (
            <TabsNav key={index} tab={val.tab} label={val.label} />
          ))}
        </Nav>
      </Card.Header>
      <Card.Body>
        {tabsConfig.map(
          ({ tab, component: Component }, index) =>
            tab === tabs && <Component key={index} />
        )}
      </Card.Body>
    </Card>
  );
};

export default withDropdownPermintaanProduksiContextProvider(
  MainApprovalPermintaanProduksi
);
