import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableLC } from './TableLC';

export const ContainerLCDetail = () => {
  const { dataLC } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableLC
        jobOrder
        readOnly
        showTotalPrice
        title="List Labour Cost Dari Job Order"
        data={dataLC.jobOrder}
      />
      <TableLC
        readOnly
        showTotalPrice
        title="List Permintaan Labour Cost"
        data={dataLC.permintaan}
      />
    </Row>
  );
};
