import { useEffect, useState } from 'react';
import { Card, Modal, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { ConvertBarangApi } from 'api';
import { Formik } from 'formik';
import { ApprovalStatus } from 'utilities';
import * as Yup from 'yup';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  DataStatus,
} from 'components';
import { InfoSection, FormBarangAwal, TableHasilConvert } from './Components';

const DetailApprovalConvertBarang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_convert_barang = state?.no_convert_barang;
  const tab = state?.tab ?? '';
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataConvertBarang, setDataConvertBarang] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ConvertBarangApi.getSingle({ no_convert_barang })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataConvertBarang(data.data.detail);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataConvertBarang.no_convert_barang,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataConvertBarang.detail.baseline,
    };

    ConvertBarangApi.save(finalValues)
      .then(() =>
        history.push('/human-resource/approval/convert-barang', {
          alert: {
            show: true,
            variant: 'primary',
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Approval Convert Barang');
    getInitialData();
    no_convert_barang && getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Row className="my-2 d-flex justify-content-start align-items-end">
        <Col>
          <b>Detail Data Convert Barang</b>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={isPageLoading} text="Memuat data..." />
      ) : (
        <>
          <Card>
            <Card.Body>
              <InfoSection data={dataConvertBarang} />
            </Card.Body>
          </Card>

          <Row className="mt-3">
            <Col md="4">
              <FormBarangAwal data={dataConvertBarang} />
            </Col>

            <Col>
              <TableHasilConvert data={dataConvertBarang} />
            </Col>
          </Row>
        </>
      )}

      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{
            catatan: '',
          }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={dataConvertBarang?.stakeholder ?? []}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Convert Barang"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalConvertBarang;
