import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ReactParser from 'html-react-parser';
import {
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  TextArea,
  Approval,
} from 'components';
import { RupiahConvert, DateConvert, ApprovalStatus } from 'utilities';
import { PenawaranApi } from 'api';

import AnalisaBarangJadiPenawaran from './AnalisaBarangJadiPenawaran';
import { TablePenawaran } from './TablePenawaran';

const DetailPenawaran = ({ setNavbarTitle }) => {
  const { id_penawaran } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { no_penawaran, baseline, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListPenawaran, setDataListPenawaran] = useState([]);
  const [dataListLC, setDataListLC] = useState([]);
  const [dataListMP, setDataListMP] = useState([]);
  const [dataPenawaran, setDataPenawaran] = useState({});
  const [dataApproval, setDataApproval] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [dataPrelim, setDataPrelim] = useState([]);
  const [totalPenawaran, setTotalPenawaran] = useState(0);
  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);
  const [processedData, setProcessedData] = useState({});
  const [modalAnalisaConfig, setModalAnalisaConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  // STATE TEXT EDITOR PEMBUKA
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  // STATE TEXT EDITOR PENUTUP
  const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
    EditorState.createEmpty();
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    //GET SINGLE DETAIL RAB
    Axios.all([PenawaranApi.getSingle({ id_penawaran: id_penawaran })])
      .then(
        Axios.spread((data) => {
          const dataAll = data?.data?.data ?? {};
          const dataInfo = dataAll.detail ?? {};

          const filterPrelim = dataInfo?.detail_prelim?.map((val, index) => ({
            id_kelompok: val.id_kelompok,
            id_item: val.id_item_prelim,
            kode_item: val.kode_item_prelim,
            nama_item: val.nama_item_prelim,
            qty_item: val.qty_analisa,
            id_unit: val.id_satuan_prelim,
            nama_unit: val.nama_satuan_prelim,
            harga: parseFloat(val.unit_price ?? 0),
            qty_durasi: parseFloat(val.qty_durasi ?? 0),
            konstanta: parseFloat(val.konstanta ?? 0),
            order: index + 1,
          }));

          setDataPenawaran(dataInfo);
          setDataListPenawaran(dataInfo.detail_barang_jadi ?? []);
          setDataPrelim(filterPrelim ?? []);
          setDataApproval(dataAll.approval);
          setApproveStatus(dataAll.behavior);

          // SET TEXT EDIOR
          setTextEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data?.data?.data?.teks_pembuka ?? '<p></p>')
              )
            )
          );
          setTextEditorStatePenutup(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data?.data?.data?.teks_penutup ?? '<p></p>')
              )
            )
          );
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. RAB"
                value={
                  dataPenawaran?.tgl_rab
                    ? DateConvert(new Date(dataPenawaran.tgl_rab)).detail
                    : '-'
                }
              />
              <InfoItem title="No. RAB" value={dataPenawaran?.no_rab ?? '-'} />
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  dataPenawaran?.tgl_penawaran
                    ? DateConvert(new Date(dataPenawaran.tgl_penawaran)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Penawaran"
                value={dataPenawaran?.no_penawaran ?? '-'}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={dataPenawaran?.nama_customer ?? '-'}
              />
              <InfoItem title="ATT" value={dataPenawaran?.att ?? '-'} />
              <InfoItem
                title="Tgl. Selesai"
                value={
                  dataPenawaran?.tgl_selesai
                    ? DateConvert(new Date(dataPenawaran.tgl_selesai)).detail
                    : '-'
                }
              />
              <InfoItem
                title="Proyek"
                value={
                  dataPenawaran?.nama_proyek ? dataPenawaran?.nama_proyek : '-'
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const GrandTotalSection = ({ totalPenawaran, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalPenawaran + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalPenawaran, totalLC, totalMP]);

    return (
      <div className="p-2 bg-light text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
          Grand Total : {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  useEffect(() => {
    setNavbarTitle('Penawaran');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Penawaran</b>
          <div className="d-flex flex-row justfiy-content-center align-items-center">
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && (
            <>
              <div className="pt-3">
                <b>Teks Pembuka</b>
              </div>
              <div className="p-2 border">
                {ReactParser(dataPenawaran.teks_pembuka ?? '<p></p>')}
              </div>
              <TablePenawaran
                type="DETAIL"
                ppn={parseFloat(dataPenawaran.ppn ?? 0)}
                diskon={parseFloat(dataPenawaran.diskon ?? 0).toPrecision()}
                setTotalPenawaran={setTotalPenawaran}
                totalPenawaran={totalPenawaran}
                dataBarangJadi={dataListPenawaran}
                dataPrelim={dataPrelim}
              />
              <GrandTotalSection
                totalPenawaran={totalPenawaran}
                totalLC={totalLC}
                totalMP={totalMP}
              />
              <div className="pt-3">
                <b>Teks Penutup</b>
              </div>
              <div className="p-2 border">
                {ReactParser(dataPenawaran.teks_penutup ?? '<p></p>')}
              </div>

              {!isPageLoading && (
                <Formik
                  initialValues={{ catatan: '' }}
                  validationSchema={Yup.object().shape({
                    catatan: Yup.string().required('Masukan Catatan'),
                  })}
                  onSubmit={(values) => {
                    const finalValues = {
                      ...values,
                      no_transaksi: dataPenawaran.no_penawaran,
                      status_approval: modalConfig.type,
                      tgl_approval: DateConvert(new Date()).default,
                      approval_baseline: dataPenawaran.baseline,
                    };

                    PenawaranApi.approve(finalValues)
                      .then((res) => {
                        history.push('/human-resource/approval/penawaran', {
                          alert: {
                            show: true,
                            variant: 'primary',
                            text: `${
                              modalConfig.type == 'APP'
                                ? 'Approve'
                                : modalConfig.type === 'VER'
                                ? 'Verify'
                                : modalConfig.type == 'REV'
                                ? 'Revise'
                                : 'Reject'
                            } data berhasil!`,
                          },
                        });
                        const logValues = {
                          no_transaksi: dataPenawaran.no_penawaran,
                          nama_transaksi: 'Peluang',
                          baseline: dataPenawaran.baseline,
                          status_approval: modalConfig.type,
                          no_transaksi_ref: dataPenawaran.no_rab,
                          baseline_ref: dataPenawaran.baseline,
                          keterangan_transaksi:
                            modalConfig.type === 'APP'
                              ? 'Approve Penawaran'
                              : modalConfig.type === 'VER'
                              ? 'Verifikasi Penawaran'
                              : 'Reject Penawaran',
                        };
                        PenawaranApi.saveLogProgress(logValues);
                      })
                      .catch((err) => {
                        setAlertConfig({
                          variant: 'danger',
                          text: `Ubah data gagal! (${err.response.data.message})`,
                        });
                      })
                      .finally(() => setModalConfig({ show: false }));
                  }}
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    validateForm,
                    dirty,
                    errors,
                    touched,
                  }) => (
                    <>
                      {!isPageLoading && (
                        <Approval
                          values={values}
                          handleChange={handleChange}
                          dirty={dirty}
                          validateForm={validateForm}
                          errors={errors}
                          touched={touched}
                          approveStatus={approveStatus}
                          data={dataApproval}
                          tab={tab}
                          setModalConfig={setModalConfig}
                          title="Penawaran"
                        />
                      )}

                      <ModalSection
                        values={values}
                        isSubmitting={isSubmitting}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                      />
                    </>
                  )}
                </Formik>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailPenawaran;
