import Services from 'services';

class PermintaanProduksiApi {
  get(params) {
    return Services.get('/hrdu/approval_permintaan_produksi/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_permintaan_produksi/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_permintaan_produksi/detail', {
      params,
    });
  }

  getDropdownJobOrder(params) {
    return Services.get('/hrdu/approval_permintaan_produksi/item_jo', {
      params,
    });
  }

  approve(data) {
    return Services.post('/hrdu/approval_permintaan_produksi/approve', data);
  }
}

export default new PermintaanProduksiApi();
