import { Table, Td, Th, ThFixed } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { calculatePageGrandTotal } from '../Utils';

export const TableListPurchaseOrder = ({ data }) => {
  const grandTotal = calculatePageGrandTotal(data?.detail?.po_detail ?? []);

  const diskon = Math.round(
    ((data?.detail?.diskon ?? 0 * 100) / 100) * grandTotal
  );

  const totalDikurangiDiskon = grandTotal - diskon;

  const ppn = Math.round(
    ((data?.detail?.ppn ?? 0 * 100) / 100) * totalDikurangiDiskon
  );

  const totalDitambahPpn = totalDikurangiDiskon + ppn;

  const dp = Math.round(
    ((data?.detail?.dp ?? 0 * 100) / 100) * totalDitambahPpn
  );

  const sisa = totalDitambahPpn - dp;

  return (
    <>
      <div className="mt-3" style={{ fontSize: '.9rem' }}>
        List Item Purchase Order
      </div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Seleksi Vendor</ThFixed>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th>Item Seleksi Vendor</Th>
            <Th width="100px">Qty</Th>
            <Th>Satuan</Th>
            <ThFixed>Harga Kesepakatan</ThFixed>
            <ThFixed>Total Harga</ThFixed>
          </tr>
        </thead>
        <tbody>
          {data?.detail?.po_detail?.map((val, index) => (
            <tr key={index}>
              <Td className="text-center">{index + 1}</Td>

              <Td className="text-nowrap">
                <div>
                  {val.tgl_seleksi_vendor
                    ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_seleksi_vendor ?? '-'}</div>
              </Td>

              <Td className="text-nowrap">
                <div>
                  {val.tgl_purchase_request
                    ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_purchase_request ?? '-'}</div>
              </Td>

              <Td>{val.nama_item}</Td>

              <Td>{parseFloat(val.qty_order)}</Td>

              <Td>{val.nama_satuan}</Td>

              <Td textRight>
                {
                  RupiahConvert(String(Math.round(val.harga_kesepakatan ?? 0)))
                    .detail
                }
              </Td>

              <Td textRight>
                {
                  RupiahConvert(
                    String(
                      Math.round(
                        parseFloat(val.harga_kesepakatan ?? 0) *
                          parseFloat(val.qty_order ?? 0)
                      )
                    )
                  ).detail
                }
              </Td>
            </tr>
          ))}
          <tr>
            <Td colSpan={7} textRight>
              Total
            </Td>

            <Td textRight>{RupiahConvert(String(grandTotal)).detail}</Td>
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              <div className="d-flex justify-content-end align-items-center">
                <span className="mb-2">
                  Diskon ({parseFloat(data?.detail?.diskon)}%)
                </span>
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(diskon)).detail}</Td>
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              Total Setelah Dikurangi Diskon
            </Td>
            <Td textRight>
              {RupiahConvert(String(totalDikurangiDiskon)).detail}
            </Td>
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              <div className="d-flex justify-content-end align-items-center">
                <span className="mb-2">
                  PPN ({parseFloat(data?.detail?.ppn)}%)
                </span>
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(ppn)).detail}</Td>
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              Total Setelah Ditambah PPN
            </Td>
            <Td textRight>{RupiahConvert(String(totalDitambahPpn)).detail}</Td>
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              <div className="d-flex justify-content-end align-items-center">
                <span className="mb-2">
                  DP ({parseFloat(data?.detail?.dp)}%)
                </span>
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(dp)).detail}</Td>
          </tr>

          <tr>
            <Td colSpan={7}>
              <div className="d-flex justify-content-end align-items-center">
                <span className="mb-2">Sisa</span>
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(sisa)).detail}</Td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
