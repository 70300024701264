import React, { useEffect, useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Modal } from 'react-bootstrap';
import FileSaver from 'file-saver';
import { ActionButton, DataStatus } from 'components';
import { ApprovalRAEApi } from 'api';
import {
  TableSummaryBiaya,
  TableListHardwood,
  TableListPlywood,
  TableList,
} from './Section';
import { generateListSummary, exportHelper } from './Utils';
import { RupiahConvert } from 'utilities';

const TabSummary = ({ idRAE: id_rae }) => {
  const [listSummary, setListSummary] = useState([]);
  const [fetchStatus, setFetchStatus] = useState({
    loading: true,
    success: false,
  });
  const [isExporting, setIsExporting] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'Machine Process',
    data: [],
  });

  const dismissModal = () =>
    setModalConfig({ show: false, type: '', data: [] });

  const TableFinishing = ({ data = [] }) => {
    const uniqueFinishing = Array.from(
      new Set(
        data.map(
          ({ nama_finishing_barang_jadi }) =>
            Boolean(nama_finishing_barang_jadi) && nama_finishing_barang_jadi
        )
      )
    );
    return (
      <div>
        {uniqueFinishing.length > 0 ? (
          uniqueFinishing.map((finishing) => {
            return (
              <div className="mt-3">
                <h5 className="font-weight-bold mb-2 ">{finishing}</h5>
                <TableList
                  title="Item Bahan Penunjang Finishing"
                  data={data?.filter(
                    (val) =>
                      val.nama_finishing_barang_jadi === finishing &&
                      val.ref === 'fn_bp'
                  )}
                />
                <TableList
                  title="Item Subkon Finishing"
                  data={data?.filter(
                    (val) =>
                      val.nama_finishing_barang_jadi === finishing &&
                      val.ref === 'fn_sc'
                  )}
                />
                <TableList
                  title="Item Labour Cost Finishing"
                  data={data?.filter(
                    (val) =>
                      val.nama_finishing_barang_jadi === finishing &&
                      val.ref === 'fn_lc'
                  )}
                />
              </div>
            );
          })
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </div>
    );
  };

  const TableLabourCostOnSite = ({ data }) => {
    return (
      <>
        <TableListHardwood isRef data={data?.allHardwood ?? []} />
        <TableList
          title="Item Labour Cost Onsite"
          data={data?.allLabourCostOnsite ?? []}
        />
      </>
    );
  };

  const renderModalBody = () => {
    if (modalConfig.type === 'Hardwood')
      return <TableListHardwood data={modalConfig.data ?? []} />;
    if (modalConfig.type === 'Plywood')
      return <TableListPlywood data={modalConfig.data ?? []} />;
    if (modalConfig.type === 'Factory Supply')
      return <TableList title="Factory Supply" data={modalConfig.data} />;
    if (modalConfig.type === 'Labour Cost')
      return <TableList title="Labour Cost" data={modalConfig.data} />;
    if (modalConfig.type === 'Machine Process')
      return <TableList title="Machine Process" data={modalConfig.data} />;
    if (modalConfig.type === 'Biaya Overhead')
      return <TableList title="Biaya Overhead" data={modalConfig.data} />;
    if (modalConfig.type === 'Finishing')
      return <TableFinishing title="Finishing" data={modalConfig.data} />;
    if (modalConfig.type === 'Labour Cost On-Site')
      return <TableLabourCostOnSite data={modalConfig.data} />;
    if (modalConfig.type === 'Alat Bantu On-Site')
      return (
        <TableList
          isAccumulate={false}
          title="Alat Bantu On-Site"
          data={modalConfig.data}
        />
      );
    if (modalConfig.type === 'Biaya Overhead Kantor')
      return (
        <TableList
          isAccumulate={false}
          title="Biaya Overhead Kantor"
          data={modalConfig.data}
        />
      );
  };

  const getInitialData = () => {
    ApprovalRAEApi.getRekapitulasi({ id_rae })
      .then((res) => {
        const data = res.data.data ?? {};
        const listSummary = generateListSummary(data);

        setListSummary(listSummary);
        setFetchStatus({ loading: false, success: true });
      })
      .catch(({ response }) => {
        window.alert(response?.data?.message ?? 'Data gagal dimuat!');
        setFetchStatus({ loading: false, success: false });
      });
  };

  useEffect(() => {
    getInitialData();

    return () => setListSummary([]);
  }, [id_rae]);

  if (fetchStatus.loading || !fetchStatus.success) {
    return (
      <>
        {fetchStatus.loading ? (
          <DataStatus loading={fetchStatus.loading} text="Memuat data . . ." />
        ) : (
          <DataStatus text="Data gagal dimuat" />
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end align-items-center">
        <ActionButton
          text="Export"
          variant="outline-success"
          className="mx-1 mb-1"
          loading={isExporting}
          onClick={() => {
            setIsExporting(true);
            const data = exportHelper(id_rae, 'excel', listSummary);

            ApprovalRAEApi.getExportRekapitulasi(data)
              .then((res) => {
                const filename = 'Rekapitulasi_RAE.xlsx';
                FileSaver.saveAs(res.data.data, filename);
              })
              .catch(() => window.alert('Gagal export data!'))
              .finally(() => setIsExporting(false));
          }}
        >
          <IoDocumentTextOutline className="mr-1 mb-1" size={18} />
        </ActionButton>
      </div>

      <TableSummaryBiaya
        type="baku"
        data={listSummary.filter((item) => item.type === 'baku')}
        setModalConfig={setModalConfig}
      />

      <TableSummaryBiaya
        type="lainnya"
        data={listSummary.filter((item) => item.type === 'lainnya')}
        setModalConfig={setModalConfig}
        footer={
          <tfoot>
            <tr className="bg-light h6">
              <td></td>
              <td className="text-right font-weight-bold">Grand Total</td>
              <td className="text-right font-weight-bold">
                {
                  RupiahConvert(
                    String(
                      Math.round(
                        listSummary?.reduce(
                          (acc, { totalPrice }) => acc + totalPrice,
                          0
                        )
                      )
                    )
                  ).detail
                }
              </td>
              <td />
            </tr>
          </tfoot>
        }
      />

      {/* Modal */}
      <Modal show={modalConfig.show} size="xl" onHide={() => dismissModal()}>
        <Modal.Header closeButton>
          <span className="font-weight-bold">{modalConfig.type}</span>
        </Modal.Header>

        <Modal.Body>{renderModalBody()}</Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center">
            <ActionButton
              className="bg-light text-dark border-0 text-sm"
              text="TUTUP"
              onClick={() => dismissModal()}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default TabSummary;
