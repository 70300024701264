import React, { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton,
  Approval,
} from 'components';
import { Form, InfoSection, TableBarangJadi, ModalPrelim } from './Section';
import { SalesOrderSPKApi } from 'api';
import { ApprovalStatus, DateConvert } from 'utilities';
import AnalisaBarangJadiSOSPK from './AnalisaBarangJadi';

const DetailApprovalSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id_sales_order_spk } = useParams();
  const { no_sales_order_spk, tab } = location.state || {};
  const [approveStatus, setApproveStatus] = useState('V');
  const [dataApproval, setDataApproval] = useState([]);
  const [dataSOSPK, setDataSOSPK] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [modalPrelim, setModalPrelim] = useState({ show: false });
  const [dataStakeHolder, setDataStakeHolder] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [modalAnalisaConfig, setModalAnalisaConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const StakeHolder = [];

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([SalesOrderSPKApi.getSingleSOSPK({ no_sales_order_spk })])
      .then(
        Axios.spread((resSingle) => {
          const behavior = String(
            resSingle?.data?.data?.behavior
          ).toUpperCase();
          const data = resSingle?.data?.data?.detail ?? {};
          const barangJadi = data?.detail?.map((item) =>
            Object({
              ...item,
              kode_item: item.kode_barang,
              nama_item: item.nama_barang,
              satuan: item.kode_satuan ?? item.nama_satuan,
              qty: parseFloat(item.qty_sales_order_spk),
              harga_satuan: item.harga_rate,
            })
          );

          const prelim = data?.prelim?.map((item, index) =>
            Object({
              ...item,
              id_item: item.id_item_prelim,
              kode_item: item.kode_item_prelim,
              nama_item: item.nama_item_prelim,
              qty_item: item.qty_analisa,
              id_unit: item.id_satuan_prelim,
              nama_unit: item.nama_satuan_prelim,
              harga: parseFloat(item.unit_price ?? 0),
              qty_durasi: parseFloat(item.qty_durasi ?? 0),
              konstanta: parseFloat(item.konstanta ?? 0),
              order: index + 1,
            })
          );

          setDataSOSPK(data);
          setDataBarangJadi(barangJadi);
          setDataPrelim(prelim);
          setApproveStatus(behavior ?? 'V');
          setDataApproval(resSingle?.data?.data?.approval ?? []);
          setDataStakeHolder(data.stakeholder);
        })
      )
      .catch((err) => {
        // setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const formInitialValues = {
    id_sales_order_spk: id_sales_order_spk,
    tgl_sales_order_spk: dataSOSPK?.tgl_sales_order_spk ?? '',
    no_sales_order_spk: dataSOSPK?.no_sales_order_spk ?? '',
    tgl_batas_waktu: dataSOSPK?.tgl_batas_waktu ?? '',
    id_sales: dataSOSPK?.id_sales ?? '',
    nama_sales: dataSOSPK?.nama_sales ?? '',
    diskon: parseFloat(dataSOSPK?.diskon ?? 0),
    ppn: parseFloat(dataSOSPK?.ppn ?? 0),
    uang_muka: parseFloat(dataSOSPK?.uang_muka ?? 0),
  };
  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required('Pilih tanggal Sales Order SPK'),
    no_sales_order_spk: Yup.string().required(
      'Pilih tanggal untuk menentukan nomor Sales Order SPK'
    ),
    tgl_batas_waktu: Yup.string().required('Pilih tanggal Batas Waktu'),
    id_sales: Yup.string().required('Pilih Sales'),
  });
  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      barang_jadi: dataBarangJadi,
    };

    console.log('finalValues', finalValues);

    SalesOrderSPKApi.save(finalValues)
      .then(() =>
        history.push('/transaksi/penawaran', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil ditambah!',
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          type: 'danger',
          text: 'Data gagal ditambah!',
        });
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    setNavbarTitle('Sales Order SPK');
    getInitialData();
  }, []);

  const ModalAnalisaSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiSOSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Sales Order SPK</b>
          <div className="d-flex align-items-center justify-content-top">
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataSOSPK} />
              <hr />
              <Form type="DETAIL" formik={formik} />
              <TableBarangJadi
                type="DETAIL"
                dataBarangJadi={dataBarangJadi}
                dataPrelim={dataPrelim}
                setDataBarangJadi={setDataBarangJadi}
                setModalPrelim={setModalPrelim}
                setProcessedData={setProcessedData}
                setModalConfig={setModalAnalisaConfig}
                diskon={formik.values.diskon}
                ppn={formik.values.ppn}
                uangMuka={formik.values.uang_muka}
                setFieldValue={formik.setFieldValue}
              />

              <ModalAnalisaSection
                processedData={processedData}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
                modalConfig={modalAnalisaConfig}
                setModalConfig={setModalAnalisaConfig}
              />

              {modalPrelim.show && (
                <ModalPrelim
                  show={modalPrelim.show}
                  onHide={() => setModalPrelim({ show: false })}
                  dataPrelim={dataPrelim}
                  onSubmit={(val) => {
                    setDataPrelim(val);
                    setModalPrelim({ show: false });
                  }}
                />
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {!isPageLoading && (
        <Formik
          initialValues={{ catatan: '' }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan Catatan'),
          })}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              no_transaksi: dataSOSPK.no_sales_order_spk,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: dataSOSPK.baseline,
            };

            SalesOrderSPKApi.approve(finalValues)
              .then((res) => {
                history.push('/human-resource/approval/sales-order-spk', {
                  alert: {
                    show: true,
                    variant: 'primary',
                    text: `${
                      modalConfig.type == 'APP'
                        ? 'Approve'
                        : modalConfig.type == 'REV'
                        ? 'Revise'
                        : modalConfig.type == 'VER'
                        ? 'Verify'
                        : 'Reject'
                    } data berhasil!`,
                  },
                });
              })
              .catch((err) => {
                setAlertConfig({
                  variant: 'danger',
                  text: `Ubah data gagal! (${err.response.data.message})`,
                });
              })
              .finally(() => setModalConfig({ show: false }));
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            validateForm,
            dirty,
            errors,
            touched,
          }) => (
            <>
              <Approval
                values={values}
                handleChange={handleChange}
                dirty={dirty}
                validateForm={validateForm}
                errors={errors}
                touched={touched}
                approveStatus={approveStatus}
                data={dataStakeHolder}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Sales Order SPK"
              />
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalSOSPK;
