import Services from 'services';

class AnalisaBarangJadi {
  get(params) {
    return Services.get('/hrdu/approval_analisa_barang_jadi/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_analisa_barang_jadi/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_analisa_barang_jadi/detail', {
      params,
    });
  }

  getAnalisaSingle(params) {
    return Services.get('hrdu/approval_rae/analisa_barang_jadi_single/', {
      params,
    });
  }

  getDropdownTipeFinishing(params) {
    return Services.get('hrdu/approval_rae/d_finishing', { params });
  }
  getDropdownTipeFinishing() {
    return Services.get('hrdu/approval_analisa_barang_jadi/d_finishing');
  }
  approve(data) {
    return Services.post('/hrdu/approval_analisa_barang_jadi/approve', data);
  }
}

export default new AnalisaBarangJadi();
