import React, { useState, useEffect, useContext, memo, useRef } from 'react';
import { Tabs, Tab, Row, Col, Card, Modal, Nav } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useFormik, Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Approval,
} from 'components';
import { InfoItemVertical } from 'components2';
import { DateConvert, ApprovalStatus } from 'utilities';
import { RAEApi, ApprovalRAEApi } from 'api';
import { RAEContextProvider, RAEContext } from '../RAEContext';
import { TableBarangJadi } from '../Section';
import {
  TabInfoPeluang,
  TabGambarPeluang,
  TabFilePeluang,
} from '../PeluangSection';
import TabSummary from './TabSummary/TabSummary';

const UbahRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();
  const no_rae = state?.no_rae;
  const tab = state?.tab ?? '';
  const baseline = state?.baseline;

  const ContentUbahRAE = memo(() => {
    const {
      dataAnalisaBarangJadi: contextAnalisa,
      setDataAnalisaBarangJadi: setAnalisaContext,
      dataPrelim,
      setDataPrelim,
      setType,
    } = useContext(RAEContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataRAE, setDataRAE] = useState({});
    const [dataSelectJenisBarangJadi, setDataSelectJenisBarangJadi] = useState(
      []
    );
    const [dataSelectAnalisaBarangJadi, setDataSelectAnalisaBarangJadi] =
      useState([]);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [dataLabourCost, setDataLabourCost] = useState([]);
    const [dataMachineProcess, setDataMachineProcess] = useState([]);
    const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([]);
    const [dataApproval, setDataApproval] = useState([]);
    const [approveStatus, setApproveStatus] = useState('v');
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [modalPeluangConfig, setModalPeluangConfig] = useState({
      show: false,
      nomor: '',
    });
    const [modalConfig, setModalConfig] = useState({
      show: false,
      type: '',
      title: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      Axios.all([RAEApi.getSingle({ no_rae: no_rae, baseline: baseline })])
        .then(
          Axios.spread((res) => {
            const rae = res.data.data.detail ? res.data.data.detail : {};
            const barangJadi = rae.detail ? rae.detail : [];
            const prelim = rae.prelim ? rae.prelim : [];

            const filterBarangJadi = barangJadi.map((val) => ({
              id_rae_detail: val.id_rae_detail,
              id_barang_jadi: val.id_barang_jadi,
              nama_item: val.nama_item,
              kode_item: val.kode_item,
              qty: val.qty_rae,
              satuan: val.nama_satuan,
              harga_satuan: val.harga_satuan_rae,
              has_edit: false,
            }));

            const filterPrelim = prelim.map((val, index) => ({
              id_kelompok: val.id_kelompok,
              id_item: val.id_rae_prelim,
              kode_item: val.kode_item_prelim,
              nama_item: val.nama_item_prelim,
              qty_item: val.qty_analisa,
              id_unit: val.id_satuan_prelim,
              nama_unit: val.nama_satuan_prelim,
              harga: parseFloat(val.unit_price ?? 0),
              qty_durasi: parseFloat(val.qty_durasi ?? 0),
              konstanta: parseFloat(val.konstanta ?? 0),
              satuan_durasi: val.nama_satuan_waktu,
              order: index + 1,
            }));

            const checkApp = res.data.data.approval ?? [];
            const checkStatus = res.data.data.behavior ?? 'v';

            setDataRAE(rae);
            setDataPrelim(filterPrelim);
            setDataBarangJadi(filterBarangJadi);
            setDataApproval(checkApp);
            setApproveStatus(checkStatus);
          })
        )
        .catch(() => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const mappingHardwood = (data) =>
      data.map((val) => ({
        id_barang_jadi: val.id_barang_jadi,
        urutan_item: val.urutan_item,
        id_deskripsi: val.id_deskripsi,
        is_header: val.is_header,
        qty_raw: val.qty_raw,
        t_raw: val.t_raw,
        w_raw: val.w_raw,
        l_raw: val.l_raw,
        qty_final: val.qty_final,
        t_final: val.t_final,
        w_final: val.w_final,
        l_final: val.l_final,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
        id_jenis_kayu: val.id_jenis_kayu,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
        id_part_kayu: val.id_part_kayu,
      }));

    const mappingPlywood = (data) =>
      data.map((val) => ({
        id_barang_jadi: val.id_barang_jadi,
        urutan_item: val.urutan_item,
        id_deskripsi: val.id_deskripsi,
        deskripsi: val.deskripsi,
        is_header: val.is_header,
        qty_final: val.qty_final,
        t_final: val.t_final,
        w_final: val.w_final,
        l_final: val.l_final,
        qty_raw: val.qty_raw,
        t_raw: val.t_raw,
        w_raw: val.w_raw,
        l_raw: val.l_raw,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
        id_item_buaso: val.id_item_buaso,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
      }));

    const mappingTP = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        });
      });

      return final;
    };

    const mappingPrelim = (data) =>
      data.map((val) => ({
        id_item_buaso: val.id_item,
        qty_analisa: val.qty_item,
        unit_price: val.harga,
        qty_durasi: val.qty_durasi,
        id_satuan_durasi: val.id_unit,
        konstanta: val.konstanta,
        satuan_durasi: val.satuan_durasi,
      }));

    const formInitialValues = {
      id_peluang: id,
      tgl_rae: '',
      no_rae: '',
    };

    const formValidationSchema = Yup.object().shape({
      tgl_rae: Yup.string().required('Pilih tanggal RAE'),
      no_rae: Yup.string().required('Pilih tanggal untuk menentukan nomor RAE'),
    });

    const formSubmitHandler = (values) => {
      const detailAnalisa = contextAnalisa.map((val) => ({
        index: val.index,
        analisa_hardwood: mappingHardwood(val.dataHardwood ?? []),
        analisa_plywood: mappingPlywood(val.dataPlywood ?? []),
        analisa_fin_tp_fs: mappingTP(val.dataTPFS ?? []),
        analisa_fin_tp_lc: mappingTP(val.dataTPLC ?? []),
        analisa_fin_tp_mp: mappingTP(val.dataTPMP ?? []),
        analisa_fin_tp_bop: mappingTP(val.dataTPBOP ?? []),
        analisa_fin_fn_bp: mappingFN(val.dataFNBP ?? []),
        analisa_fin_fn_sc: mappingFN(val.dataFNSC ?? []),
        analisa_fin_fn_lc: mappingFN(val.dataFNLC ?? []),
        analisa_alat_bantu_onsite: val.dataAlatBantuOnSite,
        analisa_biaya_overhead_kantor: val.dataAnalisaBOK,
        analisa_labour_cost_onsite: val.dataLabourCostOnSite,
      }));

      const barangJadi = dataBarangJadi.map((val, index) => ({
        id_barang_jadi: val.id_barang_jadi,
        qty_rae: val.qty,
        harga_satuan_rae: val.harga_satuan,
        analisa_barang_jadi:
          detailAnalisa.find((val) => val.index === index) ?? {},
      }));

      const finalValues = {
        id_rae: id,
        id_peluang: dataRAE.id_peluang,
        tgl_rae: values.tgl_rae,
        detail_barang_jadi: barangJadi,
        detail_prelim: mappingPrelim(dataPrelim),
      };

      RAEApi.save(finalValues)
        .then(() =>
          history.push('/transaksi/rae', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil diubah!',
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: 'danger',
            text: 'Data gagal ditambah!',
          });
        });
    };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const getNomorRAE = (date) => {
      RAEApi.getNomorRAE({ tanggal: date })
        .then(({ data }) => {
          formik.setFieldValue('tgl_rae', date);
          formik.setFieldValue('no_rae', data.data);
        })
        .catch(() => window.alert('Nomor RAE gagal di generate!'));
    };

    const grandTotal = () => {
      const totalBarangJadi = dataBarangJadi.reduce(
        (prevValue, currentValue) => {
          const checkQty = currentValue.qty ? parseFloat(currentValue.qty) : 0;
          const checkSatuan = currentValue.harga_satuan
            ? parseInt(currentValue.harga_satuan)
            : 0;
          const subTotal = parseInt(checkQty * checkSatuan);
          const total = parseInt(prevValue + subTotal);

          return total;
        },
        0
      );

      const totalPrelim = dataPrelim.reduce((prev, current) => {
        const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
        const newHarga = current.harga ? parseFloat(current.harga) : 0;
        const newQtyDurasi = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);

      const grandTotal = totalBarangJadi + totalPrelim;

      return {
        total: totalBarangJadi,
        prelim: totalPrelim,
        grandTotal: grandTotal,
      };
    };

    const ButtonSection = () => {
      return (
        <div className="d-flex justify-content-end my-4 mb-5">
          <ActionButton
            variant="success"
            text="Ubah Rencana Anggaran Estimasi"
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
          />
        </div>
      );
    };

    const ModalPeluang = () => {
      const [dataInfoPeluang, setDataInfoPeluang] = useState({});
      const [dataTablePeluang, setDataTablePeluang] = useState([]);
      const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
        loading: true,
        success: false,
      });
      const tabsRef = useRef();

      const [defaultTabs, setDefaultTabs] = useState('info');
      const [dataGambar, setDataGambar] = useState([]);
      const [dataFile, setDataFile] = useState([]);

      // Konfigurasi komponen tabs
      const tabsConfig = [
        {
          tab: 'info',
          label: 'Informasi Umum',
          component: ({ ...rest }) => <TabInfoPeluang {...rest} />,
        },
        {
          tab: 'gambar',
          label: 'Gambar',
          component: ({ ...rest }) => <TabGambarPeluang {...rest} />,
        },
        {
          tab: 'file',
          label: 'File',
          component: ({ ...rest }) => <TabFilePeluang {...rest} />,
        },
      ];

      // Menangani cek type dari file yang dikirim
      const checkTypeAndStoreFile = (data) => {
        let gambar = [];
        let file = [];

        data.map((val) => {
          const map = {
            link: val.path_gambar,
            id: val.id_peluang_gambar,
            nama: '',
            data: null,
          };

          const generalFileType = map.link.split('.').pop();
          const fileName = map.link.split('/').pop();

          // Check apakah data adalah gambar
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push({ ...map, nama: fileName });
          }

          return file.push({ ...map, nama: fileName });
        });

        setDataGambar(gambar);
        setDataFile(file);
      };

      // Menangani perubahan pada tabs
      const onTabsChangeHandler = (e, tabs) => {
        e.preventDefault();
        setDefaultTabs(tabs);
      };

      const getDataRAE = (noPeluang) => {
        setFetchPeluangStatus({
          loading: true,
          success: false,
        });

        RAEApi.getDetailPeluangRAE({ no_peluang: noPeluang })
          .then((res) => {
            const dataInfo = res.data.data ?? {};
            const dataTable = res.data.data.detail ?? {};

            setDataInfoPeluang(dataInfo);
            checkTypeAndStoreFile(dataInfo?.gambar ?? []);
            setDataTablePeluang(dataTable);
            setFetchPeluangStatus({
              loading: false,
              success: true,
            });
          })
          .catch(() => {
            setFetchPeluangStatus({
              loading: false,
              success: false,
            });
          });
      };

      useEffect(() => {
        if (modalPeluangConfig.nomor) {
          getDataRAE(modalPeluangConfig.nomor);
        } else {
          setFetchPeluangStatus({
            loading: false,
            success: false,
          });
        }

        // eslint-disable-next-line
      }, []);

      if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
        return (
          <Modal
            size="xl"
            show={modalPeluangConfig.show}
            onHide={() =>
              setModalPeluangConfig({
                ...modalPeluangConfig,
                show: false,
              })
            }
          >
            <Modal.Header closeButton>
              <b>Detail Peluang</b>
            </Modal.Header>
            <Modal.Body>
              {fetchPeluangStatus.loading ? (
                <DataStatus loading text="Memuat data . . ." />
              ) : (
                <DataStatus text="Data gagal dimuat" />
              )}
            </Modal.Body>
          </Modal>
        );
      }

      return (
        <Modal
          size="xl"
          show={modalPeluangConfig.show}
          onHide={() =>
            setModalPeluangConfig({
              ...modalPeluangConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <b>Detail Peluang</b>
          </Modal.Header>
          <Modal.Body>
            <Card.Header>
              {/* Tab Header */}
              <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
                {tabsConfig.map((val, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      ref={tabsRef}
                      href={`#${val.tab}`}
                      onClick={(e) => onTabsChangeHandler(e, val.tab)}
                    >
                      {val.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body>
              {tabsConfig.map(
                ({ tab, component: Component }, index) =>
                  tab === defaultTabs && (
                    <Component
                      key={index}
                      type="DETAIL"
                      dataInfoPeluang={dataInfoPeluang}
                      dataTablePeluang={dataTablePeluang}
                      dataGambar={dataGambar}
                      dataFile={dataFile}
                      setDataGambar={setDataGambar}
                      setDataFile={setDataFile}
                    />
                  )
              )}
            </Card.Body>
          </Modal.Body>
        </Modal>
      );
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value, link, onClick }) => (
        <tr onClick={onClick}>
          <td>{title}</td>
          <td className={`pl-4 pr-2`}>:</td>
          <td
            className={link ? 'text-primary' : ''}
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            {value}
          </td>
        </tr>
      );

      return (
        <Row>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. Peluang"
                  value={
                    dataRAE.tgl_peluang
                      ? DateConvert(new Date(dataRAE.tgl_peluang)).detail
                      : '-'
                  }
                />
                <InfoItem
                  link
                  title="No. Peluang"
                  value={dataRAE.no_peluang ? dataRAE.no_peluang : '-'}
                  onClick={() =>
                    setModalPeluangConfig({
                      show: true,
                      nomor: dataRAE.no_peluang,
                    })
                  }
                />
                <InfoItem
                  title="Tgl. RAE"
                  value={
                    dataRAE.tgl_rae
                      ? DateConvert(new Date(dataRAE.tgl_rae)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="No. RAE"
                  value={dataRAE.no_rae ? dataRAE.no_rae : '-'}
                />
                <InfoItem
                  title="Schedule Kebutuhan Proyek (Total Hari)"
                  value={
                    dataRAE.schedule_kebutuhan_proyek
                      ? dataRAE.schedule_kebutuhan_proyek
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Customer"
                  value={dataRAE.nama_customer ? dataRAE.nama_customer : '-'}
                />
                <InfoItem title="ATT" value={dataRAE.att ? dataRAE.att : '-'} />
                <InfoItem
                  title="Target Penawaran"
                  value={
                    dataRAE.tgl_selesai
                      ? DateConvert(new Date(dataRAE.tgl_selesai)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="Peringkat Peluang"
                  value={
                    dataRAE.nama_peringkat_peluang
                      ? dataRAE.nama_peringkat_peluang
                      : '-'
                  }
                />
                <InfoItem
                  title="Realisasi Penawaran"
                  value={
                    dataRAE.realisasi_penawaran
                      ? DateConvert(new Date(dataRAE.realisasi_penawaran))
                          .detail
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
        </Row>
      );
    };

    const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
      const ModalContent = () => (
        <>
          <Modal.Body className="text-center">
            <p>
              <h6>
                <b>{modalConfig.title} dengan catatan :</b>
              </h6>
              <span>{values.catatan}</span>
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              className="px-4"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: false,
                })
              }
            />
            <ActionButton
              text={ApprovalStatus(modalConfig.type, approveStatus).text}
              variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
              className="m-1 px-3 text-white"
              onClick={handleSubmit}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </>
      );

      return (
        <Modal
          show={modalConfig.show}
          onHide={() =>
            setModalConfig({
              ...modalConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton className="py-2 d-flex align-items-center">
            <Modal.Title>
              <small>{modalConfig.title}</small>
            </Modal.Title>
          </Modal.Header>
          <ModalContent />
        </Modal>
      );
    };

    useEffect(() => {
      setNavbarTitle('Rencana Anggaran Estimasi (RAE)');
      getInitialData();
      setDataLabourCost(dataRAE.detail_upah ? dataRAE.detail_upah : []);
      setDataMachineProcess(
        dataRAE.detail_alat_mesin ? dataRAE.detail_alat_mesin : []
      );
      setType('DETAIL');

      return () => {
        setIsPageLoading(false);
      };
    }, []);

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Detail Data RAE</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : isFetchingFailed ? (
              <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
            ) : (
              <>
                <InfoSection />
                <hr />
                <Card className="border">
                  <Tab.Container defaultActiveKey="barangjadi">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="barangjadi">Barang Jadi</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="rekapitulasi">
                            Rekapitulasi
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>

                    <Card.Body>
                      <Tab.Content>
                        <Tab.Pane eventKey="barangjadi">
                          <TableBarangJadi
                            dataBarangJadi={dataBarangJadi}
                            dataSelectJenisBarangJadi={
                              dataSelectJenisBarangJadi
                            }
                            dataSelectAnalisaBarangJadi={
                              dataSelectAnalisaBarangJadi
                            }
                            setDataBarangJadi={setDataBarangJadi}
                            setDataSelectJenisBarangJadi={
                              setDataSelectJenisBarangJadi
                            }
                            setDataSelectAnalisaBarangJadi={
                              setDataSelectAnalisaBarangJadi
                            }
                            grandTotal={grandTotal}
                          />
                        </Tab.Pane>

                        <Tab.Pane eventKey="rekapitulasi">
                          <TabSummary idRAE={dataRAE.id_rae} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Card.Body>
                  </Tab.Container>
                </Card>
                <hr />

                <InfoItemVertical
                  label="Catatan"
                  text={
                    <pre
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 13,
                        lineHeight: 1.7,
                      }}
                    >
                      {dataRAE.catatan ?? '-'}
                    </pre>
                  }
                />
              </>
            )}
          </Card.Body>
          {modalPeluangConfig.show && <ModalPeluang />}
        </Card>
        {!isFetchingFailed && !isPageLoading && (
          <Formik
            initialValues={{ catatan: '' }}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required('Masukan Catatan'),
            })}
            onSubmit={(values) => {
              const finalValues = {
                ...values,
                no_transaksi: dataRAE.no_rae,
                status_approval: modalConfig.type,
                tgl_approval: DateConvert(new Date()).default,
                approval_baseline: dataRAE.baseline,
              };

              ApprovalRAEApi.save(finalValues)
                .then((res) => {
                  history.push('/human-resource/approval/rae', {
                    alert: {
                      show: true,
                      variant: 'primary',
                      text: `${
                        modalConfig.type === 'APP'
                          ? 'Approve'
                          : modalConfig.type === 'REV'
                          ? 'Revise'
                          : modalConfig.type === 'VER'
                          ? 'Verify'
                          : 'Reject'
                      } data berhasil!`,
                    },
                  });

                  const logValues = {
                    no_transaksi: dataRAE.no_rae,
                    nama_transaksi: 'Peluang',
                    baseline: dataRAE.baseline,
                    status_approval: modalConfig.type,
                    no_transaksi_ref: dataRAE.no_peluang,
                    baseline_ref: dataRAE.baseline,
                    keterangan_transaksi:
                      modalConfig.type === 'APP'
                        ? 'Approve Rencana Anggaran Estimasi'
                        : modalConfig.type === 'VER'
                        ? 'Verifikasi Rencana Anggaran Estimasi'
                        : 'Reject Rencana Anggaran Estimasi',
                  };
                  ApprovalRAEApi.saveLogProgess(logValues);
                })
                .catch((err) => {
                  setAlertConfig({
                    show: true,
                    variant: 'danger',
                    text: `Simpan approval gagal! (${
                      err?.response?.data?.message ?? ''
                    })`,
                  });
                })
                .finally(() => setModalConfig({ show: false }));
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              errors,
              touched,
              validateForm,
              dirty,
            }) => (
              <>
                <Approval
                  values={values}
                  handleChange={handleChange}
                  dirty={dirty}
                  validateForm={validateForm}
                  errors={errors}
                  touched={touched}
                  approveStatus={approveStatus}
                  data={dataApproval}
                  tab={tab}
                  setModalConfig={setModalConfig}
                  title="RAE"
                />
                <ModalSection
                  values={values}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </Formik>
        )}
      </>
    );
  });

  return (
    <RAEContextProvider>
      <ContentUbahRAE />
    </RAEContextProvider>
  );
};

export default UbahRAE;
