import React, { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Approval,
} from 'components';
import { ApprovalStatus } from 'utilities';
import { ApprovalPurchaseOrderApi } from 'api';
import {
  InfoSection,
  InformasiVendorSection,
  TableListPurchaseOrder,
} from './Components';

const DetailApprovalPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_po = state?.no_po;
  const tab = state?.tab ?? '';
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPO, setDataPO] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalPurchaseOrderApi.getSingle({ no_purchase_order: state.no_po })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataPO(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoPurchaseOrder = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataPO.detail.no_transaksi,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataPO.detail.baseline,
    };

    ApprovalPurchaseOrderApi.save(finalValues)
      .then(() =>
        history.push('/human-resource/approval/purchase-order', {
          alert: {
            show: true,
            variant: 'primary',
            text: `${
              modalConfig.type === 'APP'
                ? 'Approve'
                : modalConfig.type === 'VER'
                ? 'Verify'
                : modalConfig.type === 'REV'
                ? 'Revise'
                : 'Reject'
            } data berhasil!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Purchase Order');
    no_po ? getInitialData() : whenNoPurchaseOrder();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Purchase Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection data={dataPO} />
              <InformasiVendorSection data={dataPO} />
              <TableListPurchaseOrder data={dataPO} />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: '' }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan Catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            dirty,
          }) => (
            <>
              {!isPageLoading && (
                <Approval
                  values={values}
                  handleChange={handleChange}
                  dirty={dirty}
                  validateForm={validateForm}
                  errors={errors}
                  touched={touched}
                  approveStatus={approveStatus}
                  data={dataPO?.approval ?? []}
                  tab={tab}
                  setModalConfig={setModalConfig}
                  title="Purchase Order"
                />
              )}

              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalPurchaseOrder;
