import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Col, Row } from 'react-bootstrap';
import { debounce } from 'lodash';
import { IoEyeOutline } from 'react-icons/io5';
import { PermintaanProduksiApi } from 'api';
import {
  ActionButton,
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { refetchInterval } from 'config/refetchInterval';
import { useSessionStorage } from 'hooks';
import { DateConvert, TableNumber } from 'utilities';
import {
  ItemPermintaanCollapse,
  ModalFilterPermintaanProduksi,
} from '../Components';

export const ApprovalPermintaanProduksi = () => {
  const history = useHistory();
  const location = useLocation();
  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(
    `${location.pathname}_pagination`,
    {
      q: '',
      page: '1',
      per_page: '10',
      data_count: '0',

      active: false,
      tgl_permintaan_produksi_start: undefined,
      tgl_permintaan_produksi_end: undefined,
      tgl_job_order_start: undefined,
      tgl_job_order_end: undefined,
      id_barang_jadi: undefined,
      status_approval: undefined,
    }
  );
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const { data, isLoading } = useQuery(
    ['approval', 'PermintaanProduksi', 'list', pagination],
    () => PermintaanProduksiApi.get(pagination),
    {
      refetchInterval,
      onError: () =>
        setAlert({
          variant: 'danger',
          text: 'Data gagal dimuat!',
        }),
    }
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const dismissAlert = () => {
    setAlert({ variant: 'primary', text: '' });

    const state = { ...location.state };
    delete state.alert;

    history.replace({ ...state });
  };

  useEffect(() => {
    if (location?.state?.alert) {
      setAlert({
        text: location.state.alert.text,
        variant: location.state.alert.variant,
      });
    }
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-1">
            <Col className="d-flex flex-row justify-content-start align-items-center">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <Alert
        show={!!alert.text}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={dismissAlert}
      />

      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : !data?.data?.data?.data?.length ? (
        <PageContent
          data={data?.data ?? []}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterPermintaanProduksi
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

const PageContent = ({ data, pagination, setPagination, history }) => {
  return (
    <>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Informasi Pembuat Pengajuan</Th>
            <Th>Informasi Permintaan Produksi</Th>
            <Th>Informasi Job Order</Th>
            <Th>Item Barang Jadi</Th>
            <Th>Item Permintaan Produksi</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data?.data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed textCenter>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>
              <Td>
                <div>{val?.nama_karyawan_pengaju ?? '-'}</div>
                <div>{val?.nama_jabatan_pengaju ?? '-'}</div>
              </Td>
              <Td>
                <div>
                  {val?.tgl_permintaan_produksi
                    ? DateConvert(new Date(val.tgl_permintaan_produksi))
                        .defaultDMY
                    : '-'}
                </div>
                <div>{val?.no_permintaan_produksi ?? '-'}</div>
              </Td>
              <Td>
                <div>
                  {val?.tgl_job_order
                    ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                    : '-'}
                </div>
                <div>{val?.no_job_order ?? '-'}</div>
              </Td>
              <Td>{val.nama_barang_jadi ?? '-'}</Td>
              <Td>
                <ItemPermintaanCollapse data={val.detail ?? []} />
              </Td>
              <Td>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/human-resource/approval/permintaan-produksi/detail/${val.id_permintaan_produksi}`,
                        { no_transaksi: val.no_transaksi }
                      )
                    }
                  >
                    <IoEyeOutline />
                  </ActionButton>
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>

      <Pagination
        dataLength={pagination.per_page}
        dataNumber={
          pagination?.page * pagination?.per_page - pagination?.per_page + 1
        }
        dataPage={
          data?.data_count < pagination?.per_page
            ? data?.data_count
            : pagination.page * pagination.per_page
        }
        dataCount={data?.data_count}
        currentPage={pagination?.page}
        totalPage={data?.total_page}
        onPaginationChange={({ selected }) =>
          setPagination((prev) => ({ ...prev, page: selected + 1 }))
        }
        onDataLengthChange={(e) =>
          setPagination((prev) => ({
            ...prev,
            page: 1,
            per_page: e.target.value,
          }))
        }
      />
    </>
  );
};
