import {
  calcHW,
  calcPW,
  calculateSummaryLainnya,
  calcUnitPriceAlatBantuOnsite,
  calcUnitPriceBOK,
} from './helpers';

export const generateListSummary = (data = []) => {
  //  1. calculate hardwood
  const allHardwood = data
    .map((item) =>
      item['analisa_hardwood'].map((hw) => ({
        ...hw,
        qty_raw: parseFloat(item.qty_rae) * parseFloat(hw.qty_raw),
        qty_final: parseFloat(item.qty_rae) * parseFloat(hw.qty_final),
        qty_rae: item.qty_rae ?? 1,
      }))
    )
    .flat();
  const sumHW = calcHW(allHardwood);

  //  2. calculate plywood
  const allPlywood = data
    .map((item) =>
      item['analisa_plywood'].map((pw) => ({
        ...pw,
        qty_raw: parseFloat(item.qty_rae) * parseFloat(pw.qty_raw),
        qty_final: parseFloat(item.qty_rae) * parseFloat(pw.qty_final),
        qty_rae: item.qty_rae ?? 1,
      }))
    )
    .flat();
  const sumPW = calcPW(allPlywood);

  // 3. calculate factory supply
  const allFS = data
    .map((item) =>
      item['analisa_fin_tp_fs'].map((fs) => ({
        ...fs,
        qty: parseFloat(fs.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();
  const sumFS = calculateSummaryLainnya(allFS);

  // 4. calculate labout cost
  const allLC = data
    .map((item) =>
      item['analisa_fin_tp_lc'].map((lc) => ({
        ...lc,
        qty: parseFloat(lc.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();
  const sumLC = calculateSummaryLainnya(allLC);

  // 5. calculate machine process
  const allMP = data
    .map((item) =>
      item['analisa_fin_tp_mp'].map((mp) => ({
        ...mp,
        qty: parseFloat(mp.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();
  const sumMP = calculateSummaryLainnya(allMP);

  // 6. calculate biaya overhead
  const allBOP = data
    .map((item) =>
      item['analisa_fin_tp_bop'].map((bo) => ({
        ...bo,
        qty: parseFloat(bo.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();
  const sumBOP = calculateSummaryLainnya(allBOP);

  // 7. calculate finishing
  // bahan penunjang finishing
  const allFNBP = data
    .map((item) =>
      item['analisa_fin_fn_bp'].map((fnbp) => ({
        ...fnbp,
        qty: parseFloat(fnbp.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();

  // subkon finishing
  const allFNSC = data
    .map((item) =>
      item['analisa_fin_fn_sc'].map((fnsc) => ({
        ...fnsc,
        qty: parseFloat(fnsc.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();

  // labour cost finishing
  const allFNLC = data
    .map((item) =>
      item['analisa_fin_fn_lc'].map((fnlc) => ({
        ...fnlc,
        qty: parseFloat(fnlc.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();
  const allFinishing = [allFNBP, allFNSC, allFNLC].flat();
  const sumFinishing = calculateSummaryLainnya(allFinishing);

  // 8. calculate alat bantu onsite
  const AlatBantuOnSite = data.reduce(
    (result, item) => {
      const firstAlatBantu = item['analisa_alat_bantu_onsite'][0];

      const itemAlatBantu = {
        ...firstAlatBantu,
        qty: parseFloat(firstAlatBantu.qty) * parseFloat(item.qty_rae),
      };
      const itemUnitPrice = calcUnitPriceAlatBantuOnsite(
        item['analisa_hardwood'],
        item['analisa_plywood']
      );

      const caclItemSubtotal = Math.round(
        parseFloat(itemAlatBantu['qty']) *
          parseFloat(itemAlatBantu['konstanta'] ?? 0) *
          parseInt(itemUnitPrice ?? 0)
      );

      return {
        sum: result.sum + caclItemSubtotal,
        data: [
          ...result.data,
          {
            ...itemAlatBantu,
            unit_price: itemUnitPrice,
            total_price: caclItemSubtotal,
          },
        ],
      };
    },
    { sum: 0, data: [] }
  );

  // 9. calculate labour cost onsite
  const allLabourCostOnsite = data
    .map((item) =>
      item['analisa_labour_cost_onsite'].map((lcos) => ({
        ...lcos,
        qty: parseFloat(lcos.qty) * parseFloat(item.qty_rae),
      }))
    )
    .flat();

  const sumLabourCostOnSite = allLabourCostOnsite.reduce((result, i) => {
    return (result +=
      parseFloat(i.qty ?? 0) *
      parseFloat(i.unit_price ?? 0) *
      parseFloat(i.konstanta ?? 0));
  }, 0);

  // 10. calculate biaya overhead kantor
  const BiayaOverheadKantor = data.reduce(
    (result, item) => {
      const firstItemBOK = item['analisa_biaya_overhead_kantor'][0] ?? {};
      const itemBOK = {
        ...firstItemBOK,
        qty: Math.round(
          parseFloat(firstItemBOK.qty) * parseFloat(item.qty_rae)
        ),
      };
      const itemUnitPrice = calcUnitPriceBOK({
        hardwood: item['analisa_hardwood'],
        plywood: item['analisa_plywood'],
        fs: item['analisa_fin_tp_fs'],
        lc: item['analisa_fin_tp_lc'],
        mp: item['analisa_fin_tp_mp'],
        bop: item['analisa_fin_tp_bop'],
        fnbp: item['analisa_fin_fn_bp'],
        fnsc: item['analisa_fin_fn_sc'],
        fnlc: item['analisa_fin_fn_lc'],
        alat_bantu_onsite: item['analisa_alat_bantu_onsite'],
        labour_cost_on_site: item['analisa_labour_cost_onsite'],
      });
      const caclItemSubtotal =
        parseFloat(itemBOK['qty'] ?? 0) *
        parseFloat(itemBOK['konstanta'] ?? 0) *
        parseFloat(itemUnitPrice ?? 0);

      return {
        sum: result.sum + caclItemSubtotal,
        data: [
          ...result.data,
          {
            ...itemBOK,
            unit_price: itemUnitPrice,
            total_price: caclItemSubtotal,
          },
        ],
      };
    },
    { sum: 0, data: [] }
  );

  return [
    {
      nama: 'Hardwood',
      type: 'baku',
      data: allHardwood,
      totalPrice: sumHW.totalPrice,
      volume: sumHW.volume,
    },
    {
      nama: 'Plywood',
      type: 'baku',
      data: allPlywood,
      totalPrice: sumPW.totalPrice,
      volume: sumPW.volume,
    },
    { nama: 'Factory Supply', type: 'lainnya', totalPrice: sumFS, data: allFS },
    { nama: 'Labour Cost', type: 'lainnya', totalPrice: sumLC, data: allLC },
    {
      nama: 'Machine Process',
      type: 'lainnya',
      totalPrice: sumMP,
      data: allMP,
    },
    {
      nama: 'Biaya Overhead',
      type: 'lainnya',
      totalPrice: sumBOP,
      data: allBOP,
    },
    {
      nama: 'Finishing',
      type: 'lainnya',
      totalPrice: sumFinishing,
      data: allFinishing,
    },
    {
      nama: 'Alat Bantu On-Site',
      type: 'lainnya',
      totalPrice: AlatBantuOnSite.sum,
      data: AlatBantuOnSite.data ?? [],
    },
    {
      nama: 'Labour Cost On-Site',
      type: 'lainnya',
      totalPrice: sumLabourCostOnSite,
      data: { allLabourCostOnsite, allHardwood },
    },
    {
      nama: 'Biaya Overhead Kantor',
      type: 'lainnya',
      totalPrice: BiayaOverheadKantor.sum,
      data: BiayaOverheadKantor.data ?? [],
    },
  ];
};
