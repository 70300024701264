import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
  Approval,
} from '../../../components';
import { ApprovalStatus, DateConvert, RupiahConvert } from '../../../utilities';
import { ApprovalDeliveryOrderApi } from '../../../api';

const DetailApprovalDeliveryOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const idDO = state?.id_delivery_order;
  const tab = state?.tab ?? '';
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataDeliveryOrder, setDataDeliveryOrder] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalDeliveryOrderApi.getSingle({ id_delivery_order: idDO })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataDeliveryOrder(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoDO = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataDeliveryOrder.detail.no_delivery_order,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataDeliveryOrder.detail.baseline,
    };

    ApprovalDeliveryOrderApi.save(finalValues)
      .then(() =>
        history.push('/human-resource/approval/delivery-order', {
          alert: {
            show: true,
            variant: 'primary',
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Detail Delivery Order');
    getInitialData();
    idDO ? getInitialData() : whenNoDO();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <table style={{ fontSize: '14px' }}>
        <tbody>
          <InfoItem
            title="Tgl. Sales Order"
            value={
              dataDeliveryOrder?.detail?.tgl_sales_order
                ? DateConvert(
                    new Date(dataDeliveryOrder?.detail?.tgl_sales_order)
                  ).detail
                : '-'
            }
          />
          <InfoItem
            title="No. Sales Order"
            value={
              dataDeliveryOrder?.detail?.no_sales_order
                ? dataDeliveryOrder?.detail?.no_sales_order
                : '-'
            }
          />
          <InfoItem
            title="Customer"
            value={
              dataDeliveryOrder?.detail?.nama_customer
                ? dataDeliveryOrder?.detail?.nama_customer
                : '-'
            }
          />
          <InfoItem
            title="Sales"
            value={
              dataDeliveryOrder?.detail?.nama_sales
                ? dataDeliveryOrder?.detail?.nama_sales
                : '-'
            }
          />
          <InfoItem
            title="Tgl. Delivery Order"
            value={
              dataDeliveryOrder?.detail?.tgl_delivery_order
                ? DateConvert(
                    new Date(dataDeliveryOrder?.detail?.tgl_delivery_order)
                  ).detail
                : '-'
            }
          />
          <InfoItem
            title="No. Delivery Order"
            value={
              dataDeliveryOrder?.detail?.no_delivery_order
                ? dataDeliveryOrder?.detail?.no_delivery_order
                : '-'
            }
          />
          <InfoItem
            title="Gudang"
            value={
              dataDeliveryOrder?.detail?.nama_gudang
                ? dataDeliveryOrder?.detail?.nama_gudang
                : '-'
            }
          />
          <InfoItem
            title="Proyek"
            value={
              dataDeliveryOrder?.detail?.nama_proyek
                ? dataDeliveryOrder?.detail?.nama_proyek
                : '-'
            }
          />
          <InfoItem
            title="Catatan"
            value={
              dataDeliveryOrder?.detail?.catatan_delivery_order
                ? dataDeliveryOrder?.detail?.catatan_delivery_order
                : '-'
            }
          />
        </tbody>
      </table>
    );
  };

  const TableDOSection = () => {
    const dataTableDO = dataDeliveryOrder?.detail?.detail
      ? dataDeliveryOrder.detail.detail
      : [];

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          fontSize: '14px',
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={props.className}
        style={{
          ...props.style,
          fontSize: '14px',
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th className="text-center" style={{ width: 30 }}>
                No
              </Th>
              <Th className="text-center" style={{ width: 100 }}>
                Kode Barang
              </Th>
              <Th className="text-center">Nama Barang</Th>
              <Th className="text-center">Gudang</Th>
              <Th className="text-center">Qty SO</Th>
              <Th className="text-center">Satuan</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableDO.length > 0 ? (
              dataTableDO.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="px-1">{val.kode_barang}</Td>
                  <Td className="px-1">{val.nama_barang}</Td>
                  <Td className="px-1">{val.nama_gudang}</Td>
                  <Td className="px-1">{val.qty}</Td>
                  <Td className="px-1">{val.nama_satuan}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Delivery Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableDOSection />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{
            catatan: '',
          }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={
                  dataDeliveryOrder?.approval ? dataDeliveryOrder.approval : []
                }
                tab={tab}
                setModalConfig={setModalConfig}
                title="Delivery Order"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalDeliveryOrder;
