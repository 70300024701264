import React, { useState, useEffect } from 'react';
import { RupiahConvert } from 'utilities';

const TableListSummary = ({ getGrandTotal, analisaVariable }) => {
  const [dataSummary, setDataSummary] = useState([]);
  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const total = dataSummary.reduce(
    (total, { subtotal }) => total + parseInt(subtotal ?? 0),
    0
  );

  const dataListSummary = () => {
    const data = getGrandTotal();
    // const valueBOK = analisaVariable.find(item => item.nama_variabel === "BOK Produksi")?.nilai ?? 7;

    // const totalBOK = data.reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0) * (valueBOK / 100);

    const totalBahanBaku = data
      .filter((item) => item.kode === 'hw' || item.kode === 'pw')
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalPenunjangProduksi = data
      .filter(
        (item) =>
          item.kode === 'fs' ||
          item.kode === 'lc' ||
          item.kode === 'mp' ||
          item.kode === 'bop'
      )
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalFinishing = data
      .filter((item) => item.kode === 'fin')
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    setDataSummary([
      { nama_biaya: 'Analisa Bahan Baku', subtotal: totalBahanBaku },
      {
        nama_biaya: 'Analisa Penunjang Produksi',
        subtotal: totalPenunjangProduksi,
      },
      { nama_biaya: 'Analisa Finishing', subtotal: totalFinishing },
      // { nama_biaya: 'Analisa BOK Produksi', subtotal: totalBOK }
    ]);
  };

  useEffect(() => {
    dataListSummary();
    return () => {};
  }, [analisaVariable, getGrandTotal]);

  return (
    <div className="table-responsive">
      <table className="table table-bordered bg-white table-sm">
        <thead className="text-center">
          <tr>
            <th className="align-middle p-2" style={tableStyling}>
              No.
            </th>
            <th className="align-middle p-2" style={tableStyling}>
              Nama Biaya
            </th>
            <th className="align-middle p-2" style={tableStyling}>
              Total Price (Rp)
            </th>
          </tr>
        </thead>
        <tbody>
          {dataSummary?.map((val, index) => (
            <tr key={index}>
              <td style={tableStyling} className="text-center" width={20}>
                {index + 1}
              </td>
              <td style={tableStyling}>{val.nama_biaya}</td>
              <td style={tableStyling} className="text-right" width={200}>
                {RupiahConvert(String(parseInt(val.subtotal) ?? 0)).detail}
              </td>
            </tr>
          ))}

          <tr className="bg-light">
            <td
              className="text-right font-weight-bold"
              style={tableStyling}
              colSpan={2}
            >
              Grand Total
            </td>
            <td
              className="text-right font-weight-bold"
              style={tableStyling}
              width={200}
            >
              {RupiahConvert(String(parseInt(total) ?? 0)).detail}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableListSummary;
