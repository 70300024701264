import Services from '../../services';

class PemeliharaanAsetApi {
  // API SERVER
  get(params) {
    return Services.get('/hrdu/approval_pemeliharaan_aset/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_pemeliharaan_aset/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_pemeliharaan_aset/detail', { params });
  }

  approve(data) {
    return Services.post('/hrdu/approval_pemeliharaan_aset/approve', data);
  }
}

export default new PemeliharaanAsetApi();
