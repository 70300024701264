import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import {
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  Approval,
} from 'components';
import { InfoItemHorizontal } from 'components2';
import { DateConvert, ApprovalStatus } from 'utilities';
import { PenerimaanBarangApi } from 'api';
import { tglInputFormat } from './Utils';

const DetailPenerimaanBarang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { no_penerimaan_barang = '', tab = '' } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPenerimaanBarang, setDataPenerimaanBarang] = useState({});
  const [dataApproval, setDataApproval] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [showImage, setShowImage] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL Penerimaan Barang
    Axios.all([
      PenerimaanBarangApi.getSingle({
        no_penerimaan_barang: no_penerimaan_barang,
      }),
    ])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : 'V';
          setDataPenerimaanBarang(detail);
          setDataApproval(approval ?? []);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).detail;
    }
    return '-';
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value, style }) => (
      <tr style={{ ...style }}>
        <td>{title}</td>
        <td className="pl-4 pr-2">{!title === '' ? ':' : ''}</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <>
        <Row>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItemHorizontal
                  className="m-1"
                  label="Tgl. Purchase Order"
                  text={
                    dataPenerimaanBarang?.tgl_purchase_order
                      ? getConvertedDate(
                          dataPenerimaanBarang?.tgl_purchase_order
                        )
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="Vendor"
                  text={dataPenerimaanBarang?.nama_vendor ?? '-'}
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="Qty. Purchase Order"
                  text={dataPenerimaanBarang.qty_order ?? '0'}
                />
              </tbody>
            </table>
          </Col>

          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItemHorizontal
                  className="m-1"
                  label="No. Purchase Order"
                  text={dataPenerimaanBarang?.no_purchase_order ?? '-'}
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="Item"
                  text={dataPenerimaanBarang.nama_item}
                />
              </tbody>
            </table>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col>
            <table>
              <tbody>
                <InfoItemHorizontal
                  className="mx-1"
                  label="Tgl. Input Data"
                  text={tglInputFormat({
                    created_at:
                      dataPenerimaanBarang.created_at_penerimaan_barang,
                    separator: ' - ',
                  })}
                />
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <Col>
            <table>
              <tbody>
                <InfoItemHorizontal
                  className="m-1"
                  label="Tgl. Penerimaan Barang"
                  text={
                    dataPenerimaanBarang?.tgl_penerimaan_barang
                      ? getConvertedDate(
                          dataPenerimaanBarang?.tgl_penerimaan_barang
                        )
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="No. Surat Jalan"
                  text={dataPenerimaanBarang?.no_surat_jalan ?? '-'}
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="Gudang Penerimaaan"
                  text={dataPenerimaanBarang?.nama_gudang ?? '-'}
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="Qty. Penerimaan Dalam Satuan Beli"
                  text={`${dataPenerimaanBarang?.qty_beli ?? '0'} ${
                    dataPenerimaanBarang?.nama_satuan_beli ?? ''
                  }`}
                />
              </tbody>
            </table>
          </Col>

          <Col>
            <table>
              <tbody>
                <InfoItemHorizontal
                  className="m-1"
                  label="No. Penerimaan Barang"
                  text={dataPenerimaanBarang?.no_penerimaan_barang ?? '-'}
                />
                <InfoItemHorizontal
                  className="m-1"
                  label="Petugas Penerimaan"
                  text={dataPenerimaanBarang?.nama_karyawan ?? '-'}
                />
                <InfoItem
                  className="m-1"
                  label="Qty. Penerimaan Dalam Satuan Pakai"
                  text={`${dataPenerimaanBarang?.qty_pakai ?? '0'} ${
                    dataPenerimaanBarang?.nama_satuan_pakai ?? ''
                  }`}
                />
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle('Detail Data Penerimaan Barang');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Penerimaan Barang</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
              {dataPenerimaanBarang?.foto_surat_jalan && (
                <>
                  <img
                    src={dataPenerimaanBarang.foto_surat_jalan}
                    width={300}
                    className="m-3"
                    alt="foto penerimaan barang"
                    onClick={() => setShowImage(true)}
                    style={{ cursor: 'zoom-in' }}
                  />
                  {showImage && (
                    <ImageViewer
                      disableScroll
                      closeOnClickOutside
                      src={[dataPenerimaanBarang.foto_surat_jalan]}
                      currentIndex={0}
                      backgroundStyle={{ zIndex: '10' }}
                      onClose={() => setShowImage(false)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: '' }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required('Masukan Catatan'),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataPenerimaanBarang.no_penerimaan_barang,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: 0,
          };

          PenerimaanBarangApi.approve(finalValues)
            .then((res) => {
              history.push('/human-resource/approval/penerimaan-barang', {
                alert: {
                  show: true,
                  variant: 'primary',
                  text: `${
                    modalConfig.type == 'APP'
                      ? 'Approve'
                      : modalConfig.type == 'REV'
                      ? 'Revise'
                      : modalConfig.type == 'VER'
                      ? 'Verify'
                      : 'Reject'
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                variant: 'danger',
                text: `Ubah data gagal! (${err.response.data.message})`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            {!isPageLoading && (
              <Approval
                values={values}
                handleChange={handleChange}
                dirty={dirty}
                validateForm={validateForm}
                errors={errors}
                touched={touched}
                approveStatus={approveStatus}
                data={dataApproval}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Penerimaan Barang"
              />
            )}
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailPenerimaanBarang;
