export const STATUS_APPROVAL = {
  PEN: 'PENDING',
  VER: 'VERIFIED',
  APP: 'APPROVED',
  REV: 'REVISE',
  REJ: 'REJECT',
};

export const FILE_TYPE = {
  images: ['image/jpeg', 'image/png'],
};
