import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, ActionButton, Approval } from 'components';
import { ApprovalStatus } from 'utilities';
import { Api } from './apis';
import { useQuery } from 'react-query';
import { RencanaProyekDetail } from './baseView/RencanaProyekDetail';
import { baseData } from './baseView/functions';

const DetailApprovalRencanaProyek = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const tab = state?.tab ?? '';
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getData = useQuery(
    ['rencana-proyek', 'single', id],
    () => Api.getSingle({ id_rencana_proyek: id }),
    {
      onSuccess: ({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataSeleksiVendor({
          ...data.data.detail,
          new_approval: data.approval,
        });
        setApproveStatus(behavior.toUpperCase());
      },
      onError: () => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      },
    }
  );

  useEffect(() => {
    setNavbarTitle(baseData.title);
  }, []);

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      <RencanaProyekDetail getData={getData} />

      {!getData.isError && !getData.isLoading && (
        <Fragment>
          <Formik
            initialValues={{ catatan: '' }}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required('Masukan Catatan'),
            })}
            onSubmit={(values) => {
              const today = new Date().toISOString().slice(0, 10);

              const finalValues = {
                no_transaksi: dataSeleksiVendor.no_rencana_proyek,
                status_approval: modalConfig.type,
                catatan: values.catatan,
                tgl_approval: today,
                approval_baseline: dataSeleksiVendor.baseline,
              };

              Api.approve(finalValues)
                .then(() =>
                  history.push(baseData.baseLink, {
                    alert: {
                      show: true,
                      variant: 'primary',
                      text: 'Approval berhasil disimpan!',
                    },
                  })
                )
                .catch(() =>
                  setAlertConfig({
                    show: true,
                    variant: 'danger',
                    text: 'Approval gagal disimpan!',
                  })
                )
                .finally(() => {
                  setModalConfig({
                    ...modalConfig,
                    show: false,
                  });
                  getData.refetch();
                });
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              validateForm,
              errors,
              touched,
              dirty,
            }) => (
              <>
                <Approval
                  values={values}
                  handleChange={handleChange}
                  dirty={dirty}
                  validateForm={validateForm}
                  errors={errors}
                  touched={touched}
                  approveStatus={approveStatus}
                  data={
                    dataSeleksiVendor?.new_approval
                      ? dataSeleksiVendor.new_approval
                      : []
                  }
                  tab={tab}
                  setModalConfig={setModalConfig}
                  title="Rencana Proyek"
                />

                <ModalSection
                  values={values}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </Formik>
        </Fragment>
      )}
    </>
  );
};

export default DetailApprovalRencanaProyek;
