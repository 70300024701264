import Services from '../../services';

class SPKApi {
  get(params) {
    return Services.get('/hrdu/approval_spk/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_spk/history/list', { params });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_spk/detail', { params });
  }
  getSingleAnalisa(params) {
    return Services.get('/hrdu/approval_spk/analisa_barang_jadi_single', {
      params,
    });
  }

  save(data) {
    return Services.post('/hrdu/approval_spk/approve', data);
  }
  saveLogProgress(data) {
    return Services.post('/hrdu/log_progress/', data);
  }

  // Analisa Barang Jadi
  getAnalisaSingle(params) {
    return Services.get('hrdu/approval_rab/analisa_barang_jadi_single/', {
      params,
    });
  }

  getDropdownTipeFinishing(params) {
    return Services.get('hrdu/approval_rab/d_finishing', { params });
  }

  // Prelim
  getKelompokPrelim(params) {
    return Services.get('/hrdu/approval_rab/list_kelompok_prelim', { params });
  }

  getItemPrelim(params) {
    return Services.get('/hrdu/approval_rab/dropdown_item_prelim', { params });
  }
}

export default new SPKApi();
