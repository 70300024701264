import React, { useEffect } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useSessionStorage } from 'hooks';
import { ApprovalPurchaseOrder, HistoryApprovalPurchaseOrder } from './Tabs';
import { withDropdownPurchaseOrderContextProvider } from './Contexts';

const MainApprovalPurchaseOrder = withDropdownPurchaseOrderContextProvider(
  ({ setNavbarTitle }) => {
    useEffect(() => {
      setNavbarTitle('Approval Purchase Order');
      return () => {};
    }, []);

    const TableSection = () => {
      const [tabs, setTabs] = useSessionStorage('active', 'approval'); //State untuk menampung tabs yang aktif

      // Konfigurasi komponen tabs
      const tabsConfig = [
        {
          tab: 'approval',
          label: 'Approval',
          component: () => <ApprovalPurchaseOrder />,
        },
        {
          tab: 'history',
          label: 'History',
          component: () => <HistoryApprovalPurchaseOrder />,
        },
      ];

      // Menangani perubahan pada tabs
      const onTabsChangeHandler = (e, newPage) => {
        e.preventDefault();
        setTabs(newPage);
      };

      const TabsNav = ({ tab, label }) => (
        <Nav.Item>
          <Nav.Link
            href={`#${tab}`}
            onClick={(e) => onTabsChangeHandler(e, tab)}
          >
            {label}
          </Nav.Link>
        </Nav.Item>
      );

      return (
        <Card>
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>
      );
    };

    return <TableSection />;
  }
);

export default withDropdownPurchaseOrderContextProvider(
  MainApprovalPurchaseOrder
);
