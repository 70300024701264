// React
import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// Component
import { Row, Col, Card, Modal } from 'react-bootstrap';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  TextArea,
  Approval,
} from '../../../components';
import { ApprovalStatus, DateConvert } from '../../../utilities';

// API
import { Formik } from 'formik';
import { PemeliharaanAsetApi } from '../../../api';

// Form
import * as Yup from 'yup';

const DetailPemeliharaanAset = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const tab = state?.tab ?? '';
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataMutasiGudang, setDataMutasiGudang] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PemeliharaanAsetApi.getSingle({ id_pemeliharaan_aset: id })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataMutasiGudang(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoMutasiGudang = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataMutasiGudang.detail.no_transaksi,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataMutasiGudang.detail.baseline,
    };

    PemeliharaanAsetApi.approve(finalValues)
      .then(() =>
        history.push('/human-resource/approval/pemeliharaan-aset', {
          alert: {
            show: true,
            variant: 'primary',
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Approval Pemeliharaan Aset');
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop = false }) => (
      <tr>
        <td
          className="align-top"
          style={isPaddingTop ? { paddingTop: '10px' } : {}}
        >
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    );

    return (
      <>
        <Row>
          <Col md>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. Pemeliharaan Aset"
                  value={
                    dataMutasiGudang?.detail?.tgl_pemeliharaan_aset
                      ? DateConvert(
                          new Date(
                            dataMutasiGudang?.detail?.tgl_pemeliharaan_aset
                          )
                        ).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="No. Pemeliharaan Aset"
                  value={dataMutasiGudang?.detail?.no_pemeliharaan_aset ?? '-'}
                />
                <InfoItem
                  title="Item Aset"
                  value={
                    dataMutasiGudang?.detail?.nama_item_aset
                      ? `${
                          dataMutasiGudang?.detail?.kode_item_aset
                            ? `${dataMutasiGudang?.detail?.kode_item_aset} - `
                            : ''
                        }${dataMutasiGudang?.detail?.nama_item_aset}`
                      : '-'
                  }
                />
                <InfoItem
                  title="Grup Aset"
                  value={dataMutasiGudang?.detail?.nama_grup_aset ?? '-'}
                />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Kategori Aset"
                  value={dataMutasiGudang?.detail?.nama_kategori_aset ?? '-'}
                />
                <InfoItem
                  title="Jenis Aset"
                  value={dataMutasiGudang?.detail?.nama_jenis_aset ?? '-'}
                />
                <InfoItem
                  title="Penanggung Jawab"
                  value={dataMutasiGudang?.detail?.nama_karyawan ?? '-'}
                />
                <InfoItem
                  title="Unit Organisasi Pengguna"
                  value={dataMutasiGudang?.detail?.nama_unit_organisasi ?? '-'}
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />

        <TextArea
          label="Catatan Pemeliharaan"
          value={dataMutasiGudang?.detail?.catatan_pemeliharaan_aset ?? '-'}
          readOnly
          rows="3"
        />
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Pemeliharaan Aset</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <InfoSection />
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: '' }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={
                  dataMutasiGudang?.approval ? dataMutasiGudang.approval : []
                }
                tab={tab}
                setModalConfig={setModalConfig}
                title="Pemeliharaan Aset"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailPemeliharaanAset;
