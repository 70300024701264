import React, { useEffect, useState } from 'react';
import { Alert, SelectSearch, Input } from 'components';
import { RupiahConvert } from 'utilities';

const TableBarangJadi = ({
  dataPrelim,
  dataBarangJadi,
  setDataBarangJadi,
  diskon,
  ppn,
  uangMuka,
  setFieldValue,
  type = 'CREATE',
  setModalPrelim,
  setProcessedData,
  setModalConfig,
}) => {
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);
    return convert;
  };

  // const calculateItemProfit = (harga_satuan) => {
  //   const harga = parseInt(harga_satuan ?? 0)
  //   return harga + ((harga * 10) / 100)
  // }

  const getGrandTotalPrelim = dataPrelim?.reduce((prev, current) => {
    const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
    const newHarga = current.harga ? parseFloat(current.harga) : 0;
    const newQtyDurasi = current.qty_durasi
      ? parseFloat(current.qty_durasi)
      : 0;
    const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
    const subTotal = newQtyItem * newHarga;
    const total = subTotal * newKonts;

    return prev + total;
  }, 0);

  const grandTotal = dataBarangJadi.reduce((acc, { harga_satuan, qty }) => {
    // const subtotal = calculateItemProfit(harga_satuan) * parseFloat(qty ?? 0)
    const subtotal = harga_satuan * parseFloat(qty ?? 0);
    return parseInt(acc + subtotal);
  }, 0);

  const calculateDiskon = () => {
    const checkDiskon = diskon ? parseFloat(diskon) : 0;
    const totalDiskon =
      (checkDiskon * (grandTotal + getGrandTotalPrelim)) / 100;
    const totalSetelahDiskon = grandTotal + getGrandTotalPrelim - totalDiskon;

    return {
      totalDiskon: parseInt(totalDiskon),
      totalSetelahDiskon: parseInt(totalSetelahDiskon),
    };
  };

  const calculatePPN = () => {
    const checkPPN = ppn ? parseFloat(ppn) : 0;
    const totalSetelahDiskon = calculateDiskon().totalSetelahDiskon;
    const totalPPN = (checkPPN * totalSetelahDiskon) / 100;
    const totalSetelahPPN = totalSetelahDiskon + totalPPN;

    return {
      totalPPN: parseInt(totalPPN),
      totalSetelahPPN: parseInt(totalSetelahPPN),
    };
  };

  const calculateDP = () => {
    const checkDP = uangMuka ? parseFloat(uangMuka) : 0;
    const totalSetelahPPN = calculatePPN().totalSetelahPPN;
    const totalDP = (checkDP * totalSetelahPPN) / 100;
    const totalSetelahDP = totalSetelahPPN - totalDP;

    return {
      totalDP: parseInt(totalDP),
      totalSetelahDP: parseInt(totalSetelahDP),
    };
  };

  return (
    <>
      <div className="py-2 p-1">
        <b>List Barang Jadi</b>
      </div>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: '14px' }}
      >
        <thead className="text-center bg-light">
          <tr>
            <th className="align-middle" style={{ width: 30 }}>
              No.
            </th>
            <th className="align-middle" style={{ width: 100 }}>
              Kode Barang Jadi
            </th>
            <th className="align-middle" style={{ width: 250 }}>
              Nama Barang Jadi
            </th>
            <th className="align-middle" style={{ width: 280 }}>
              Gudang
            </th>
            <th className="align-middle">Qty.</th>
            <th className="align-middle">Satuan</th>
            <th className="align-middle">Harga Satuan</th>
            <th className="align-middle">Sub Total</th>
          </tr>
        </thead>
        <tbody>
          {dataBarangJadi?.map((val, index) => {
            const checkQty = val.qty ? parseFloat(val.qty) : 0;
            const harga = val.harga_satuan ? parseInt(val.harga_satuan) : 0;
            // const harga_profit = calculateItemProfit(harga)
            const subTotal = parseInt(checkQty * harga);
            const mapGudang = val?.gudang?.map((item) =>
              Object({
                ...item,
                value: item.id_gudang,
                label: `${item.nama_gudang} (${item.jumlah})`,
              })
            );

            return (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{val.kode_item}</td>
                <td>
                  {/* <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setProcessedData({
                        ...val,
                        index: index,
                        id_barang_jadi: val.id_item,
                      });
                      setModalConfig({
                        show: true,
                        type: "analisa",
                        title: <span className="text-primary">Analisa Barang Jadi SPK</span>,
                      });
                    }}
                  >
                    {val.nama_item}
                  </a> */}
                  {val.nama_item}
                </td>
                <td>
                  {type === 'DETAIL' ? (
                    val.nama_gudang
                  ) : (
                    <SelectSearch
                      wrapperStyle={{ width: 280 }}
                      placeholder="Pilih Gudang"
                      defaultValue={
                        val.id_gudang
                          ? mapGudang.find(
                              (item) => item.value === val.id_gudang
                            )
                          : ''
                      }
                      option={mapGudang}
                      onChange={(e) => {
                        const newData = dataBarangJadi?.map((item, idx) =>
                          index === idx ? { ...item, id_gudang: e.value } : item
                        );
                        setDataBarangJadi(newData);
                      }}
                    />
                  )}
                </td>
                <td className="text-right">{val.qty}</td>
                <td>{val.satuan}</td>
                <td className="text-right">
                  {
                    RupiahConvert(parseInt(val.harga_satuan ?? 0).toString())
                      .detail
                  }
                </td>
                <td className="text-right">
                  {RupiahConvert(subTotal?.toString()).detail}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="text-center pb-2">
              {dataBarangJadi?.length > 0 ? dataBarangJadi.length + 1 : 1}
            </td>
            <td className="px-1">-</td>
            <td className="px-1">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setModalPrelim({ show: true });
                }}
              >
                PRELIM
              </a>
            </td>
            <td className="px-1">-</td>
            <td className="px-1 text-right">-</td>
            <td className="px-1 ">-</td>
            <td className="px-1 text-right">
              {RupiahConvert(parseFloat(getGrandTotalPrelim).toString()).detail}
            </td>
            <td className="px-1 text-right">
              {RupiahConvert(parseFloat(getGrandTotalPrelim).toString()).detail}
            </td>
          </tr>
        </tbody>
        <tfoot>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(grandTotal + getGrandTotalPrelim)).detail}
              </b>
            </td>
          </tr>

          {/* DISKON */}
          <tr>
            <td colSpan={7} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>Diskon (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  <Input
                    size="sm"
                    noMargin
                    readOnly={type === 'DETAIL'}
                    placeholder="Diskon %"
                    className="text-right"
                    value={diskon}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue('diskon', convert);
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(calculateDiskon().totalDiskon)).detail}
              </b>
            </td>
          </tr>

          {/* TOTAL SETELAH DISKON */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total Setelah Dikurangi Diskon</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalSetelahDiskon))
                    .detail
                }
              </b>
            </td>
          </tr>

          {/* PPN */}
          <tr>
            <td colSpan={7} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>PPN (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  <Input
                    size="sm"
                    noMargin
                    readOnly={type === 'DETAIL'}
                    placeholder="PPN %"
                    className="text-right"
                    value={ppn}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue('ppn', convert);
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(calculatePPN().totalPPN)).detail}</b>
            </td>
          </tr>

          {/* TOTAL SETELAH PPN */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total Setelah Ditambah PPN</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(calculatePPN().totalSetelahPPN)).detail}
              </b>
            </td>
          </tr>

          {/* PPN */}
          <tr>
            <td colSpan={7} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>Uang Muka (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  <Input
                    size="sm"
                    noMargin
                    readOnly={type === 'DETAIL'}
                    placeholder="PPN %"
                    className="text-right"
                    value={uangMuka}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue('uang_muka', convert);
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(calculateDP().totalDP)).detail}</b>
            </td>
          </tr>

          {/* TOTAL SETELAH PPN */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Sisa</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(calculateDP().totalSetelahDP)).detail}
              </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default TableBarangJadi;
