import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Approval,
} from 'components';
import { ApprovalStatus, DateConvert, RupiahConvert } from 'utilities';
import { SPKApi } from 'api';
import AnalisaBarangJadiSPK from './AnalisaBarangJadiSPK';
import { ModalPrelim } from './Content';

const DetailApprovalSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const noSPK = state?.no_spk;
  const tab = state?.tab ?? '';
  const baseline = state?.baseline ?? '';
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSPK, setDataSPK] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [modalAnalisaConfig, setModalAnalisaConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [modalPrelim, setModalPrelim] = useState({ show: false });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [approveStatus, setApproveStatus] = useState('V');

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([SPKApi.getSingle({ no_spk: noSPK, baseline })])
      .then(
        Axios.spread((res) => {
          const data = res.data.data;
          const dataPrelim = data?.detail?.detail_prelim ?? [];

          const filterPrelim = dataPrelim.map((val, index) => ({
            id_kelompok: val.id_kelompok,
            id_item: val.id_item_prelim,
            kode_item: val.kode_item_prelim,
            nama_item: val.nama_item_prelim,
            qty_item: val.qty_analisa,
            id_unit: val.id_satuan_prelim,
            nama_unit: val.nama_satuan_prelim,
            harga: parseFloat(val.unit_price ?? 0),
            qty_durasi: parseFloat(val.qty_durasi ?? 0),
            konstanta: parseFloat(val.konstanta ?? 0),
            order: index + 1,
          }));

          setDataSPK(data);
          setApproveStatus(data.behavior);
          setDataPrelim(filterPrelim);
        })
      )
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoSPK = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  console.log('test', modalConfig.type);
  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataSPK.detail.no_spk,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataSPK.detail.baseline,
    };

    SPKApi.save(finalValues)
      .then((res) => {
        history.push('/human-resource/approval/surat-perjanjian-kerja', {
          alert: {
            show: true,
            variant: 'primary',
            text: `${
              modalConfig.type == 'APP'
                ? 'Approve'
                : modalConfig.type == 'REV'
                ? 'Revise'
                : modalConfig.type == 'VER'
                ? 'Verify'
                : 'Reject'
            } data berhasil!`,
          },
        });
        const logValues = {
          no_transaksi: dataSPK.detail.no_spk,
          nama_transaksi: 'Peluang',
          baseline: dataSPK.detail.baseline,
          status_approval: modalConfig.type,
          no_transaksi_ref: dataSPK.detail.no_peluang,
          baseline_ref: dataSPK.detail.baseline,
          keterangan_transaksi:
            modalConfig.type === 'APP'
              ? 'Approve Surat Perjanjian Kerja'
              : modalConfig.type === 'VER'
              ? 'Verifikasi Surat Perjanjian Kerja'
              : 'Reject Surat Perjanjian Kerja',
        };
        SPKApi.saveLogProgress(logValues);
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Surat Perjanjian Kerja');
    getInitialData();
    noSPK ? getInitialData() : whenNoSPK();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {props.children}
    </td>
  );

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <table style={{ fontSize: '14px' }}>
        <tbody>
          <InfoItem
            title="Tgl. Penawaran"
            value={
              dataSPK?.detail?.tgl_penawaran
                ? DateConvert(new Date(dataSPK?.detail?.tgl_penawaran)).detail
                : '-'
            }
          />
          <InfoItem
            title="No. Penawaran"
            value={
              dataSPK?.detail?.no_penawaran
                ? dataSPK?.detail?.no_penawaran
                : '-'
            }
          />
          <InfoItem
            title="Customer"
            value={
              dataSPK?.detail?.nama_customer
                ? dataSPK?.detail?.nama_customer
                : '-'
            }
          />
          <InfoItem
            title="ATT"
            value={dataSPK?.detail?.att ? dataSPK?.detail?.att : '-'}
          />
          <InfoItem
            title="Tgl. Selesai"
            value={
              dataSPK?.detail?.tgl_selesai
                ? DateConvert(new Date(dataSPK?.detail?.tgl_selesai)).detail
                : '-'
            }
          />
          <InfoItem
            title="Tgl. SPK"
            value={
              dataSPK?.detail?.tgl_spk
                ? DateConvert(new Date(dataSPK?.detail?.tgl_spk)).detail
                : '-'
            }
          />
          <InfoItem
            title="No. SPK"
            value={dataSPK?.detail?.no_spk ? dataSPK?.detail?.no_spk : '-'}
          />
          <InfoItem
            title="Tgl. Selesai SPK"
            value={
              dataSPK?.detail?.tgl_selesai_spk
                ? DateConvert(new Date(dataSPK?.detail?.tgl_selesai_spk)).detail
                : '-'
            }
          />
          <InfoItem
            title="Proyek"
            value={
              dataSPK?.detail?.nama_proyek ? dataSPK?.detail?.nama_proyek : '-'
            }
          />
        </tbody>
      </table>
    );
  };

  const TableSPKSection = () => {
    const dataPPN = dataSPK?.detail?.ppn ? dataSPK.detail.ppn : 0;
    const dataDiskon = dataSPK?.detail?.diskon ? dataSPK?.detail?.diskon : 0;
    const dataDP = dataSPK?.detail?.uang_muka ? dataSPK?.detail?.uang_muka : 0;
    const dataTableSPK = dataSPK?.detail.detail_barang_jadi
      ? dataSPK.detail.detail_barang_jadi
      : [];

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;
      const total = parseInt(dataQty) * parseInt(dataHarga);

      return RupiahConvert(total.toString()).detail;
    };

    const getGrandTotalPrelim = dataPrelim.reduce((prev, current) => {
      const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
      const newHarga = current.harga ? parseFloat(current.harga) : 0;
      const newQtyDurasi = current.qty_durasi
        ? parseFloat(current.qty_durasi)
        : 0;
      const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
      const subTotal = newQtyItem * newHarga;
      // const total = subTotal * newQtyDurasi * newKonts;
      const total = subTotal;
      return prev + total;
    }, 0);

    const getTotal = () => {
      const subTotal = dataTableSPK.reduce((prev, current) => {
        const qty = current.qty_rab ? current.qty_rab : 0;
        const price = current.rounded ? current.rounded : 0;
        const total = parseInt(price) * parseFloat(qty);

        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal + getGrandTotalPrelim;
    };

    const getJumlahDiskon = () => {
      const total = getTotal();
      const diskon = dataDiskon;
      const totalDiskon = (total / 100) * diskon;

      return totalDiskon;
    };

    const getGrandTotalAfterDiskon = () => {
      const total = getTotal();
      const diskon = dataDiskon ?? 0;
      const countDiskon = (diskon * total) / 100;
      const afterDiskon = parseInt(total) - parseInt(countDiskon);

      return afterDiskon;
    };

    const getJumlahPPN = () => {
      const total = getGrandTotalAfterDiskon();
      const ppn = dataPPN;
      const totalPPN = (total / 100) * ppn;

      return totalPPN;
    };

    const getTotalAfterPPN = () => {
      const total = getGrandTotalAfterDiskon();
      const ppn = dataPPN;
      const totalPPN = (total / 100) * ppn;
      const totalAfterPPN = parseInt(total) + parseFloat(totalPPN);

      return totalAfterPPN;
    };

    const getJumlahDP = () => {
      const total = getTotalAfterPPN();
      const dp = dataDP;
      const totalDP = (total / 100) * dp;

      return totalDP;
    };

    const getTotalAfterDP = () => {
      const total = getTotalAfterPPN();
      const dp = dataDP;
      const totalDP = (total / 100) * dp;
      const totalAfterDP = parseInt(total) - parseFloat(totalDP);

      return totalAfterDP;
    };

    return (
      <>
        <div className="p-1 py-2">
          <b>List Item Barang Jadi SPK</b>
        </div>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th rowSpan={2} className="text-center p-0" style={{ width: 30 }}>
                No
              </Th>
              <Th
                rowSpan={2}
                className="text-center p-0"
                style={{ width: 100 }}
              >
                Kode Item Barang Jadi
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Nama Item Barang Jadi
              </Th>
              <Th colSpan={2} className="text-center p-0">
                Volume
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Uraian
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Harga Rate
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Sub Total Rate
              </Th>
            </tr>
            <tr>
              <Th className="text-center p-0">Qty</Th>
              <Th className="text-center p-0">Unit</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableSPK &&
              dataTableSPK.length > 0 &&
              dataTableSPK.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="px-1">{val.kode_item}</Td>
                  <Td className="px-1">
                    {
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setProcessedData({
                            ...val,
                            index: index,
                          });
                          setModalAnalisaConfig({
                            show: true,
                            type: 'analisa',
                            title: (
                              <span className="text-primary">
                                Analisa Barang Jadi SPK
                              </span>
                            ),
                          });
                        }}
                      >
                        {val.nama_item}
                      </a>
                    }
                  </Td>
                  <Td className="px-1 text-right">{val.qty_rab}</Td>
                  <Td className="px-1">{val.nama_satuan}</Td>
                  <Td className="px-1">{val.uraian}</Td>
                  <Td className="px-1 text-right">
                    {val.rounded
                      ? RupiahConvert(parseInt(val.rounded).toString()).detail
                      : ''}
                  </Td>
                  <Td className="px-1 text-right">
                    {getSubTotal(val.qty_rab, val.rounded)}
                  </Td>
                </tr>
              ))}
            <tr>
              <Td className="text-center pb-2">
                {dataTableSPK && dataTableSPK.length > 0
                  ? dataTableSPK.length + 1
                  : 1}
              </Td>
              <Td className="px-1">-</Td>
              <Td className="px-1">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalPrelim({ show: true });
                  }}
                >
                  PRELIM
                </a>
              </Td>
              <Td className="px-1 text-right">1</Td>
              <Td className="px-1">-</Td>
              <Td className="px-1">-</Td>
              <Td className="px-1 text-right">
                {RupiahConvert(parseInt(getGrandTotalPrelim).toString()).detail}
              </Td>
              <Td className="px-1 text-right">
                {RupiahConvert(parseInt(getGrandTotalPrelim).toString()).detail}
              </Td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <Th colSpan={7} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotal().toString()).detail}
              </Th>
            </tr>
            <tr>
              <Th colSpan={7} className="text-right">
                {`Diskon (${parseFloat(dataDiskon).toPrecision()} %)`}
              </Th>
              <Th className="text-right">
                {RupiahConvert(parseInt(getJumlahDiskon()).toString()).detail}
              </Th>
            </tr>
            <tr>
              <Th colSpan={7} className="bg-light text-right">
                Total Setelah Diskon
              </Th>
              <Th className="bg-light text-right">
                {
                  RupiahConvert(parseInt(getGrandTotalAfterDiskon()).toString())
                    .detail
                }
              </Th>
            </tr>
            <tr>
              <Th colSpan={7} className="text-right">
                {`PPN (${parseFloat(dataPPN).toPrecision()} %)`}
              </Th>
              <Th className="text-right">
                {RupiahConvert(parseInt(getJumlahPPN()).toString()).detail}
              </Th>
            </tr>
            <tr>
              <Th colSpan={7} className="bg-light text-right">
                Total Setelah PPN
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(parseInt(getTotalAfterPPN()).toString()).detail}
              </Th>
            </tr>
            <tr>
              <Th colSpan={7} className="text-right">
                {`Uang Muka (${parseFloat(dataDP).toPrecision()} %)`}
              </Th>
              <Th className="text-right">
                {RupiahConvert(parseInt(getJumlahDP()).toString()).detail}
              </Th>
            </tr>
            <tr>
              <Th colSpan={7} className="bg-light text-right">
                Sisa
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(parseInt(getTotalAfterDP()).toString()).detail}
              </Th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const ModalAnalisaSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Surat Perjanjian Kerja</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSPKSection />
              {/* <TableLabourCostSection />
              <TableMachineProcess /> */}
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{
            catatan: '',
          }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={dataSPK?.approval ? dataSPK.approval : []}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Surat Perjanjian Kerja"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}

      {/* Modal Prelim */}
      <ModalPrelim
        show={modalPrelim.show}
        onHide={() => setModalPrelim({ show: false })}
        dataPrelim={dataPrelim}
        onSubmit={(val) => {
          setDataPrelim(val);
          setModalPrelim({ show: false });
        }}
      />

      <ModalAnalisaSection
        processedData={processedData}
        dataBarangJadi={dataSPK}
        setDataBarangJadi={setDataSPK}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalAnalisaConfig}
        setModalConfig={setModalAnalisaConfig}
      />
    </>
  );
};

export default DetailApprovalSPK;
