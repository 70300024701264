import { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InfoItemVertical } from 'components2';
import { dateConvert, rupiahConvert } from 'utilities2';

export const DetailPengajuanPettyCashContent = () => {
  const { values } = useFormikContext();

  return (
    <Fragment>
      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Pengajuan Petty Cash"
            text={dateConvert().getSlashDMY(
              new Date(values?.tgl_pengajuan_petty_cash)
            )}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="No. Pengajuan Petty Cash"
            text={values?.no_pengajuan_petty_cash}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoItemVertical
            label="Nominal Pengajuan Petty Cash"
            text={rupiahConvert().getWithComa(
              String(values?.nominal_pengajuan)
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoItemVertical label="Proyek" text={values?.nama_proyek} />
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoItemVertical label="Divisi" text={values?.kode_modul} />
        </Col>
      </Row>
      <Row>
        <Col>
          <InfoItemVertical label="Keperluan" text={values?.keperluan} />
        </Col>
      </Row>
    </Fragment>
  );
};
