import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ApprovalSeleksiVendorApi } from 'api';
import { useSessionStorage } from 'hooks';

export const DropdownSeleksiVendorContext = createContext();

export const DropdownSeleksiVendorProvider = ({ children }) => {
  const FETCH_INTERVAL_MINUTES = 2 * 60 * 1000; // 2 MINUTES
  const [triggerFetch, setTriggerFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastFetch, setLastFetch] = useSessionStorage(
    'last_fetch_dropdown_seleksi_vendor',
    null
  );
  const [dropdown, setDropdown] = useSessionStorage('dropdown_seleksi_vendor', {
    vendor: [],
    item: [],
    status_approval: [
      { value: 'PEN', label: 'PENDING' },
      { value: 'VER', label: 'VERIFIED' },
      { value: 'APP', label: 'APPROVED' },
      { value: 'REV', label: 'REVISE' },
      { value: 'REJ', label: 'REJECT' },
    ],
  });

  const isRequireToRefetch = lastFetch
    ? Boolean(
        new Date()?.getTime() - new Date(lastFetch).getTime() >
          FETCH_INTERVAL_MINUTES
      )
    : true;

  const getDropdown = () => {
    setLoading(true);

    axios
      .all([
        ApprovalSeleksiVendorApi.dropdown({ tipe: 'item' }),
        ApprovalSeleksiVendorApi.dropdown({ tipe: 'vendor' }),
      ])

      .then(
        axios.spread((resItem, resVendor) => {
          const vendor =
            resVendor.data.data.map((v) => ({
              ...v,
              value: v.id_vendor,
              label: v.nama_vendor,
            })) ?? [];

          const item =
            resItem.data.data.map((v) => ({
              value: v.id_item_buaso,
              label: `${v.kode_item} - ${v.nama_item}`,
            })) ?? [];

          const dataDropdown = {
            ...dropdown,
            vendor,
            item,
          };

          setDropdown(dataDropdown);
          setLastFetch(new Date());
        })
      )
      .catch(() => {
        alert('Gagal memuat dropdown');
        setDropdown({
          item: [],
          vendor: [],
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (triggerFetch && isRequireToRefetch) {
      getDropdown();
    }

    return () => {
      setDropdown({
        item: [],
        vendor: [],
      });

      setLoading(false);
    };
  }, [triggerFetch]);

  return (
    <DropdownSeleksiVendorContext.Provider
      value={{
        dropdown,
        setDropdown,
        loading,
        setLoading,
        setTriggerFetch,
      }}
    >
      {children}
    </DropdownSeleksiVendorContext.Provider>
  );
};

export const withDropdownSeleksiVendorProvider = (Component) => (props) => {
  return (
    <DropdownSeleksiVendorProvider>
      <Component {...props} />
    </DropdownSeleksiVendorProvider>
  );
};
