import NumberFormat from 'react-number-format';
import { Tr, Td } from 'components';

export const RowItemBO = ({ index, data = {} }) => {
  const toFloatFixed = (value) => (
    <NumberFormat
      value={parseFloat(value)}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
    />
  );

  return (
    <Tr>
      <Td textCenter>{index + 1} </Td>
      <Td className="text-nowrap"> {data.kode_item} </Td>
      <Td> {data.nama_item} </Td>
      <Td textRight> {toFloatFixed(data.qty)} </Td>
      <Td className="text-nowrap"> {data.nama_satuan} </Td>
      <Td textRight>
        <NumberFormat
          value={parseFloat(data.unit_price)}
          displayType="text"
          prefix="Rp"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </Td>
      <Td textRight> {toFloatFixed(data.konstanta)} </Td>
      <Td textRight>
        <NumberFormat
          value={parseFloat(calcSubtotalBO(data))}
          displayType="text"
          prefix="Rp"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </Td>
    </Tr>
  );
};

export const calcSubtotalBO = (data) => {
  return Math.round(
    parseFloat(data.qty).toFixed(6) *
      parseFloat(data.unit_price) *
      parseFloat(data.konstanta).toFixed(6)
  );
};
