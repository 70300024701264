import { useState, useEffect } from 'react';

const getStoredValue = (key, initialValue) => {
  const storedValue = sessionStorage.getItem(key);

  if (!storedValue) return initialValue;

  return JSON.parse(storedValue);
};

const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(getStoredValue(key, initialValue));

  useEffect(
    () => sessionStorage.setItem(key, JSON.stringify(value)),
    [key, value]
  );

  return [value, setValue];
};

export default useSessionStorage;
