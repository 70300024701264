import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InfoItemVertical } from 'components2';
import { dateConvert } from 'utilities2';

export const PenerimaanPettyCashConfirmationContent = () => {
  const { values } = useFormikContext();

  return (
    <Fragment>
      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Pengajuan Petty Cash"
            text={dateConvert().getSlashDMY(
              new Date(values?.tgl_pengajuan_petty_cash)
            )}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="No. Pengajuan Petty Cash"
            text={values?.no_pengajuan_petty_cash}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Mutasi Petty Cash"
            text={dateConvert().getSlashDMY(
              new Date(values?.tgl_mutasi_petty_cash)
            )}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="No. Bukti Mutasi"
            text={values?.no_bukti_mutasi}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoItemVertical label="Proyek" text={values?.nama_proyek} />
        </Col>
        <Col>
          <InfoItemVertical label="Divisi" text={values?.kode_modul} />
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Penerimaan Petty Cash"
            text={dateConvert().getSlashDMY(
              new Date(values?.tgl_penerimaan_petty_cash)
            )}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="No. Penerimaan Petty Cash"
            text={values?.no_penerimaan_petty_cash}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoItemVertical label="Keterangan" text={values?.keterangan} />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-column mb-2">
            <small style={{ fontSize: 12, display: 'block' }}>
              Bukti Penerimaan Petty Cash
            </small>

            <img
              alt=""
              className="mt-2 "
              style={{ minWidth: 150, maxWidth: 200, height: 'auto' }}
              src={values.bukti.url}
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
