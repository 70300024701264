/**
 * Reusable Table Component for :
 *
 * Factory Supply
 * Labour Cost
 * Machine Process
 * Biaya Overhead
 * etc.
 */
import { useEffect, useState } from 'react';
import { DataStatus } from 'components';
import { RupiahConvert } from 'utilities';
import { accumulateOthers } from '../Utils';

const TableList = ({ isAccumulate = true, title = '', data = [] }) => {
  let grandTotal = 0;
  const [dataAkumulasi, setDataAkumulasi] = useState([]);
  const fixed = { width: '30px' };

  useEffect(() => {
    const accumulate = isAccumulate ? accumulateOthers(data) ?? [] : data;
    setDataAkumulasi(accumulate);

    return () => {
      setDataAkumulasi([]);
    };
  }, [data]);

  return (
    <>
      <div className="font-weight-bold text-capitalize mb-1">List {title}</div>

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: '14px' }}
      >
        <thead className="text-center bg-light">
          <tr>
            <th className="align-middle px-0" style={fixed}>
              No.
            </th>
            <th className="align-middle px-0">Kode Item</th>
            <th className="align-middle px-0">Item {title}</th>
            <th className="align-middle px-0">Qty.</th>
            <th className="align-middle px-0">Unit</th>
            <th className="align-middle px-0">Unit Price</th>
            <th className="align-middle px-0">Konst.</th>
            <th className="align-middle px-0">Total Price (Rp)</th>
          </tr>
        </thead>
        <tbody>
          {dataAkumulasi && dataAkumulasi.length > 0 ? (
            dataAkumulasi.map((val, index) => {
              grandTotal += Math.round(val.total_price ?? 0);
              return (
                <tr>
                  <td className="text-center"> {index + 1} </td>
                  <td> {val.kode_item} </td>
                  <td> {val.nama_item} </td>
                  <td className="text-right"> {parseFloat(val.qty)} </td>
                  <td> {val.nama_satuan} </td>
                  <td className="text-right">
                    {' '}
                    {
                      RupiahConvert(String(parseInt(val.unit_price))).detail
                    }{' '}
                  </td>
                  <td className="text-right"> {parseFloat(val.konstanta)} </td>
                  <td className="text-right">
                    {' '}
                    {
                      RupiahConvert(String(parseInt(val.total_price))).detail
                    }{' '}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}

          {/* Grand Total */}
          {dataAkumulasi?.length > 0 && (
            <tr>
              <td colSpan={7} className="font-weight-bold text-right">
                Grand Total
              </td>
              <td className="font-weight-bold text-right">
                {RupiahConvert(String(parseInt(grandTotal))).detail}
              </td>
            </tr>
          )}
          <tr></tr>
        </tbody>
      </table>
    </>
  );
};

export default TableList;
